<div class="e11-flex e11-border-b e11-border-skin-app-borders">
  <div class="e11-grow e11-w-2/3">
    <div class="e11-flex e11-items-center e11-text-sm">
      <div class="e11-w-1/2 e11-py-4">
        <p class="e11-skeleton-loader e11-mb-0"></p>
      </div>
      <div class="e11-grow e11-items-center e11-py-4 e11-justify-end">
        <div class="e11-flex e11-items-center e11-grow e11-justify-end">
          <p class="e11-skeleton-loader e11-mb-0"></p>
        </div>
      </div>
    </div>
  </div>
</div>

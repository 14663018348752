import { Component, EventEmitter, Input, Output } from '@angular/core'
import { NgClass, NgForOf } from '@angular/common'

@Component({
  selector: 'star-rating-form',
  standalone: true,
  imports: [NgForOf, NgClass],
  template: `
    <div class="e11-w-fit">
      <div class="e11-cursor-pointer e11-text-skin-light">
        <span
          *ngFor="let i of [1, 2, 3, 4, 5]"
          class="material-icons-outlined md-18 hover:e11-text-skin-complimentary"
          (mouseover)="starActive(i)"
          (mouseout)="starActive(0)"
          (click)="rate(i)"
          [ngClass]="{ 'e11-text-skin-complimentary': currentRating >= i || selectedRating >= i }"
        >
          star
        </span>
      </div>
    </div>
  `,
  styles: [],
})
export class StarRatingFormComponent {
  @Input() currentRating: number = 0
  @Input() readOnly: boolean = false

  @Output() ratingChanged = new EventEmitter<number>()

  selectedRating = 0

  starActive(value: number): void {
    if (this.readOnly) {
      return
    }
    this.selectedRating = value
  }

  rate(value: number) {
    if (this.readOnly) {
      return
    }

    this.currentRating = value
    this.ratingChanged.emit(value)
  }
}

import { Duration } from '@engineering11/date-time'
import { IFileReceipt } from '@engineering11/files-web'
import { ISelectOption } from '@engineering11/ui-lib/e11-select'

/**
 * UI Specific models for coordinating between form input and the backend
 * TODO: determine how generic forms can be
 */

export interface IBaseAutomationFormData {
  enabled: boolean
}

export type IDelayFormData = {
  delayDuration: number
  /** Dropdown */
  delayUnits: IDelayUnit
}

export type IMarkdownReceiptFile = {
  id: string
  receiptFile: IFileReceipt
}

export interface ISendMessageFormData extends IBaseAutomationFormData, IDelayFormData {
  senderId: string
  message: string
  attachments?: IFileReceipt[]
}

export interface IBuildSelectInterviewFormArgs {
  authorSelectOptions: ISelectOption[]
  inputSelectOptions: ISelectOption[]
  searchDefaultValue?: string
  authorDefaultValue?: string
  responseTypeDefaultValue?: string
}

export type IDelayUnit = keyof Duration
export const DelayUnitToName: Record<Extract<IDelayUnit, 'minutes' | 'hours' | 'days'>, string> = {
  days: 'Days',
  hours: 'Hours',
  minutes: 'Minutes',
}

export const delayUnitSelectOptions: ISelectOption<IDelayUnit>[] = Object.entries(DelayUnitToName).map(([k, v]) => ({
  name: v,
  value: k as IDelayUnit,
}))

export function delayToDuration(data: IDelayFormData): Duration {
  const duration: Duration = {}
  duration[data.delayUnits] = data.delayDuration
  return duration
}

export function durationToDelay(duration?: Duration): IDelayFormData | undefined {
  if (!duration) return undefined
  const keyValue = Object.entries(duration).find(([k, v]) => v)
  if (!keyValue) return undefined
  return { delayUnits: keyValue[0] as keyof Duration, delayDuration: keyValue[1] }
}

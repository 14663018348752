/**
 * https://engineering11.atlassian.net/browse/CNCT-4941
 */

import { IAutomatedQuestionMessageFormData } from '@employer/app/modules/customer/views/jobs-post-automation-company-settings/auto-question-message.model'
import { IInterviewQuestion } from '@employer/app/modules/interview-question-library/interview-question.model'
import { APPLICATION_STATE, MessagingSystemUserIds } from 'shared-lib'
import { IBaseAutomationFormData, ISendMessageFormData } from '../job-automation.model'

export type AutomatedQuestionSwimlanes = Extract<
  APPLICATION_STATE,
  APPLICATION_STATE.APPLIED | APPLICATION_STATE.SCREENED | APPLICATION_STATE.INTERVIEWING | APPLICATION_STATE.FINALIST
>

export interface IAutoQuestionAutomationFormData {
  enabled: boolean
  defaultMessages?: IAutomatedQuestionMessageFormData
  autoQuestions: IAutoQuestionFormData[]
}

export interface IAutoQuestionFormData extends IBaseAutomationFormData {
  id: string
  /** Whether this question automation is enabled */
  enabled: boolean
  /** What to name the interview session */
  name: string
  /**
   * What swimlane does the candidate need to enter to trigger these questions
   */
  swimlane: AutomatedQuestionSwimlanes

  /** What questions to send the candidate */
  questions: IInterviewQuestion[]

  initialMessage: ISendMessageFormData
  followUp: ISendMessageFormData
  warning: ISendMessageFormData
  /** user id of the creator of this automation */
  ownerId: string
}

export interface IAutoQuestionFormComponent {
  /** An input to set the form data */
  formData: IAutoQuestionFormData | undefined

  /**
   * Validate the form data and return the data if it is valid
   * @returns The form data if it is valid, otherwise undefined
   */
  validateAndReturnData(): Partial<IAutoQuestionFormData> | undefined
}

export type IAutoQuestionMessageFormData = Pick<IAutoQuestionFormData, 'initialMessage' | 'followUp' | 'warning'>
export const autoQuestionAutomationDefaults: IAutoQuestionAutomationFormData = {
  enabled: false,
  autoQuestions: [],
}

export const autoQuestionInitialMessageDefaults: ISendMessageFormData = {
  enabled: false,
  message:
    'We wanted to let you know that some interview questions have been sent to you. You should see a notification about this and see it highlighted on your application. Please take some time to review these questions and submit your responses when you are ready.',
  senderId: MessagingSystemUserIds.hiring_team,
  delayDuration: 2,
  delayUnits: 'minutes',
}

export const autoQuestionFollowUpDefaults: ISendMessageFormData = {
  enabled: false,
  message:
    'This is a reminder that some interview questions were recently sent to you. You should have seen a notification about this and should see it highlighted on your application. Please take some time to review these questions and submit your responses when you are ready.',
  senderId: MessagingSystemUserIds.hiring_team,
  delayDuration: 2,
  delayUnits: 'days',
}

export const autoQuestionWarningDefaults: ISendMessageFormData = {
  enabled: false,
  message:
    'We wanted to send a final reminder that some interview questions were recently sent to you. Please take some time to review these questions and submit your responses when you are ready. If you do not respond and submit your answers, you will be at risk of being marked as an unresponsive applicant for this job.',
  senderId: MessagingSystemUserIds.hiring_team,
  delayDuration: 9,
  delayUnits: 'days',
}

export interface IAutoQuestionFormDefaults extends Omit<IAutoQuestionFormData, 'id' | 'name' | 'swimlane'> {}

export const autoQuestionFormDefaults: IAutoQuestionFormDefaults = {
  enabled: false,
  questions: [],
  initialMessage: autoQuestionInitialMessageDefaults,
  followUp: autoQuestionFollowUpDefaults,
  warning: autoQuestionWarningDefaults,
  ownerId: '',
}

export enum FormStep {
  Trigger = 'Trigger',
  Question = 'Question',
  Messages = 'Messages',
}

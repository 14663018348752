import { Component, Input } from '@angular/core'
import { AutomationHelperIconComponent } from './automation-helper-icon.component'
import { NgClass, NgIf } from '@angular/common'

export interface IAutomationHelperGroupItem {
  icon: string
  title: string
  description?: string
  color: 'primary-accent' | 'secondary' | 'success'
}

@Component({
  selector: 'automation-helper-group',
  standalone: true,
  imports: [AutomationHelperIconComponent, NgIf, NgClass],
  template: `
    <div
      class="e11-grid e11-grid-cols-{{ groupItems.length }} e11-gap-8 e11-justify-center e11-mb-4 e11-relative e11-m-auto"
      [ngClass]="{
        'e11-max-w-2xl': groupItems.length === 2,
        'e11-max-w-6xl': groupItems.length === 3,
      }"
    >
      @for (item of groupItems; track item.title; let count = $count; let idx = $index; let isLast = $last) {
      <div class="e11-col-span-full lg:e11-col-span-1 e11-flex e11-flex-col e11-items-center">
        <div class="e11-flex e11-flex-col e11-items-center">
          <div class="e11-mb-4 e11-flex e11-justify-center e11-items-center">
            <automation-helper-icon
              [showLeftIndicator]="idx === 2 || idx === 1"
              [showRightIndicator]="idx === 0 || idx === 1"
              [icon]="item.icon"
              [color]="item.color"
            ></automation-helper-icon>
            <div
              class="e11-hidden xl:e11-block e11-absolute e11-top-[60px]"
              [ngClass]="{
                'e11-left-[288px]': idx === 0 && groupItems.length === 2,
                'e11-left-80': idx === 0 && groupItems.length === 3,
                'e11-right-80': idx === 1
              }"
              *ngIf="!isLast"
            >
              <span class="material-icons e11-text-skin-primary-accent/30 e11-text-6xl">arrow_right_alt</span>
            </div>
          </div>
        </div>
        <div class="xl:e11-w-[240px]">
          <p
            class="e11-font-primary-demibold e11-text-center"
            [ngClass]="{
              'e11-mb-4': item.description,
              'e11-mb-0': !item.description
            }"
          >
            {{ item.title }}
          </p>
          <p *ngIf="item.description">{{ item.description }}</p>
        </div>
      </div>
      }
    </div>
  `,
})
export class AutomationHelperGroupComponent {
  @Input() groupItems: IAutomationHelperGroupItem[] = []
}

import { Injectable } from '@angular/core'
import { JobPostApplicationRepository } from '@employer/app/repositories/job-post-application.repository'
import { IRequestedUpdate } from 'shared-lib'
import { arrayRemove, arrayUnion } from '@engineering11/web-api-firebase'
import { IFileReceipt } from '@engineering11/files-web'

@Injectable({ providedIn: 'root' })
export class JobPostApplicationService {
  constructor(public repository: JobPostApplicationRepository) {}

  addRequestedUpdate(jobPostId: string, applicationId: string, requestedUpdate: IRequestedUpdate[]) {
    return this.repository.update({ id: applicationId, jobPostId, requestedUpdated: arrayUnion(...requestedUpdate) })
  }

  addConversationId(jobPostId: string, applicationId: string, conversationId: string) {
    return this.repository.update({ id: applicationId, jobPostId, conversationId })
  }

  addApplicationFiles(jobPostId: string, applicationId: string, applicationFiles: IFileReceipt[]) {
    return this.repository.update({ id: applicationId, jobPostId, applicationFiles })
  }

  removeApplicationFiles(jobPostId: string, applicationId: string, applicationFiles: IFileReceipt[]) {
    return this.repository.update({ id: applicationId, jobPostId, applicationFiles: arrayRemove(...applicationFiles) })
  }
}

import { Pipe, PipeTransform } from '@angular/core'
import { QuestionType } from 'shared-lib'

@Pipe({
  name: 'questionInputType',
})
export class QuestionInputTypePipe implements PipeTransform {
  transform(inputType: QuestionType): string {
    switch (inputType) {
      case QuestionType.EssayOrVideo:
        return 'Text or Video'
      case QuestionType.Video:
        return 'Video'
      default:
        return 'Text'
    }
  }
}

<div *ngIf="mode">
  <e11-panel>
    <div class="row">
      <div class="col-xs-12 text-center">
        <h1 *ngIf="mode == 'verifyEmail'" class="">
          {{ 'Your email has been verified. Please return to your previous tab to continue registration' | translate }}
        </h1>
        <h1 *ngIf="mode == 'resetPassword'" class="">{{ 'All set, your password has been changed.' | translate }}</h1>
        <i class="fas fa-6x fa-check-circle text-success"></i>
      </div>
    </div>
  </e11-panel>
</div>

import { Injectable } from '@angular/core'
import { IEmployerUser } from '@cnect/user-shared'
import { IJobPostUserConfig } from '@employer/app/models/job-post-user-config.model'
import { BusinessUserManagementRestService } from '@engineering11/user-web'
import { isNotNil } from '@engineering11/utility'
import { Store } from '@ngrx/store'
import { Observable, filter, firstValueFrom } from 'rxjs'
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators'
import { getCurrentUserEmployer } from 'shared-lib'
import { JobPostUserConfigRepository } from '../repositories/job-post-user-config.repository'

@Injectable({ providedIn: 'root' })
export class EmployerUserService {
  user$ = this.store.pipe(getCurrentUserEmployer, filter(isNotNil))

  constructor(
    private store: Store,
    private userService: BusinessUserManagementRestService,
    private jobPostUserConfigRepository: JobPostUserConfigRepository
  ) {}

  getAllActiveByCustomer(customerKey: string): Observable<IEmployerUser[]> {
    return this.userService.getAllActiveByCustomer(customerKey)
  }

  getJobPostConfigForCustomer(customerKey: string): Observable<IJobPostUserConfig[]> {
    return this.jobPostUserConfigRepository.getAllForCustomer(customerKey)
  }

  getJobPostConfigForCurrentCustomerUser() {
    return this.user$.pipe(
      map(user => user.id),
      distinctUntilChanged(),
      switchMap(userId => this.jobPostUserConfigRepository.getForCustomerUser(userId))
    )
  }

  async updateJobPostConfig(config: Omit<IJobPostUserConfig, 'id' | 'customerKey'>) {
    const { id, customerKey } = await firstValueFrom(this.user$)
    return this.jobPostUserConfigRepository.update({ id, customerKey, ...config })
  }
}

import { Component, Input } from '@angular/core'
import { NgClass, NgForOf } from '@angular/common'

@Component({
  selector: 'ui-candidate-stars',
  standalone: true,
  template: `
    <span class="e11-flex e11-cursor-pointer">
      <span
        *ngFor="let star of fullStars(starRating || 0)"
        class="material-icons-outlined e11-text-skin-complimentary"
        [ngClass]="{ 'md-18': size === 'md', 'md-14': size === 'sm' }"
      >
        star
      </span>
      <span
        *ngFor="let star of emptyStars(starRating || 0)"
        class="material-icons-outlined e11-text-skin-light"
        [ngClass]="{ 'md-18': size === 'md', 'md-14': size === 'sm' }"
      >
        star
      </span>
    </span>
  `,
  imports: [NgForOf, NgClass],
})
export class UiCandidateStarsComponent {
  @Input() starRating = 0
  @Input() size: 'sm' | 'md' = 'md'

  constructor() {}

  fullStars(n: number) {
    return Array(n)
  }

  emptyStars(fullStars: number) {
    const STAR_TOTAL = 5
    return Array(STAR_TOTAL - fullStars)
  }
}

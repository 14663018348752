import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { IEmployerUser } from '@cnect/user-shared'
import { CustomerService } from '@engineering11/customer-web'
import { Optional, ReplaceValues } from '@engineering11/types'
import { E11ConfirmationComponent } from '@engineering11/ui-lib/e11-confirmation'
import { Validation } from '@engineering11/ui-lib/e11-input-errors'
import { INotificationMessage } from '@engineering11/ui-lib/e11-notifications'
import { isNotNil } from '@engineering11/utility'
import { NotificationService } from '@engineering11/web-ui-helpers'
import { Store } from '@ngrx/store'
import { Subject, filter } from 'rxjs'
import { FormCustomValidationService, QuestionType, getCurrentUserEmployer } from 'shared-lib'
import { IInterviewQuestion } from '../../interview-question.model'

type EditableFields = Omit<IInterviewQuestion, 'customerKey' | 'id' | 'date' | 'authorId' | 'authorName'>
export type InterviewQuestionForm = ReplaceValues<EditableFields, FormControl<any>>

@Component({
  selector: 'ui-interview-question-form',
  template: `<ng-container *ngrxLet="user$ as user">
    <form class="" [formGroup]="questionForm" (ngSubmit)="onSubmitFormQuestion(user)" novalidate>
      <div class="e11-mb-6 e11-border-b e11-border-skin-app-borders">
        <div class="e11-flex e11-justify-between">
          <div>
            <h3 class="e11-mb-4">{{ 'Your Question' | translate }}</h3>
          </div>
          <div class="e11-mb-2 e11-justify-end">
            <e11-button
              [buttonGroup]="true"
              [color]="'neutral'"
              (click)="cancelForm()"
              [ghost]="true"
              value="{{ 'Cancel' | translate }}"
            ></e11-button>
            <e11-button
              *ngIf="selectedQuestion"
              [buttonGroup]="true"
              [color]="'secondary'"
              (click)="deleteQuestion()"
              value="{{ 'Delete' | translate }}"
            ></e11-button>
            <e11-button [type]="'submit'" [color]="'primary-accent'" value="{{ 'Save' | translate }}"></e11-button>
          </div>
        </div>
        <!-- Temporary flexed question and radios for MVP w/o tags + categories -->
        <div class="e11-flex e11-justify-between e11-mb-4">
          <div class="e11-grow e11-mr-4">
            <e11-textarea
              name="question"
              [required]="true"
              tabindex="0"
              label="{{ 'Question' | translate }}"
              placeholder="{{ 'Your Question Here' | translate }}"
              [parentForm]="questionForm"
              formControlName="question"
            >
              <div class="e11-w-full e11-flex e11-items-center">
                <div class="e11-grow">
                  <e11-input-errors
                    *ngIf="questionFormSubmitted"
                    [parentForm]="questionForm"
                    [formControl]="f.question"
                    label="{{ 'Question' | translate }}"
                  ></e11-input-errors>
                </div>
                <div class="e11-flex e11-justify-end">
                  <span class="e11-text-xs">{{ f.question.value?.length }} / {{ maxQuestionLength }}</span>
                </div>
              </div>
            </e11-textarea>
          </div>
          <div class="e11-items-center e11-flex">
            <div class="e11-pb-4">
              <p class="e11-font-primary-demibold e11-text-sm e11-mb-2">{{ 'Response Type' | translate }}</p>
              <e11-radio-input
                label="{{ 'Text or Video' | translate }}"
                [radioValue]="QuestionType.EssayOrVideo"
                groupName="inputType"
                [value]="f.inputType.value === QuestionType.EssayOrVideo"
                [displayInline]="true"
                (valueChange)="f.inputType.setValue(QuestionType.EssayOrVideo)"
              >
              </e11-radio-input>
              <e11-radio-input
                label="{{ 'Text' | translate }}"
                [radioValue]="QuestionType.Essay"
                groupName="inputType"
                [value]="f.inputType.value === QuestionType.Essay"
                [displayInline]="true"
                (valueChange)="f.inputType.setValue(QuestionType.Essay)"
              >
              </e11-radio-input>
              <e11-radio-input
                label="{{ 'Video' | translate }}"
                [radioValue]="QuestionType.Video"
                groupName="inputType"
                [value]="f.inputType.value === QuestionType.Video"
                [displayInline]="true"
                (valueChange)="f.inputType.setValue(QuestionType.Video)"
              >
              </e11-radio-input>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ng-container>`,
})
export class InterviewQuestionFormComponent implements OnInit {
  user$ = this.store.pipe(getCurrentUserEmployer, filter(isNotNil))
  loading: boolean = true
  maxQuestionLength = 500
  destroy$: Subject<boolean> = new Subject<boolean>()

  radioState: QuestionType = QuestionType.EssayOrVideo
  questionFormSubmitted: boolean = false
  questionFormVisable: boolean = false

  QuestionType = QuestionType

  @ViewChild('confirmation') confirmation!: E11ConfirmationComponent

  @Input() selectedQuestion?: IInterviewQuestion

  @Output() closeForm = new EventEmitter()
  @Output() questionDeleted = new EventEmitter()
  @Output() questionSubmitted = new EventEmitter()

  questionForm = new FormGroup<InterviewQuestionForm>({
    question: new FormControl('', [Validators.required, this.customValidator.whitespaceValidator(), Validators.maxLength(this.maxQuestionLength)]),
    inputType: new FormControl(QuestionType.EssayOrVideo),
    tags: new FormControl([] as string[]),
    categories: new FormControl([] as string[]),
  })

  constructor(
    private store: Store,
    private customerService: CustomerService,
    private router: Router,
    private customValidator: FormCustomValidationService,
    private notificationsService: NotificationService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.selectedQuestion = changes.selectedQuestion.currentValue
    if (!changes.selectedQuestion.firstChange) {
      this.changePatch(changes.selectedQuestion.currentValue)
    }
  }

  get f() {
    return this.questionForm.controls
  }

  notify() {
    this.notificationsService.popNotification(saveSuccessNotification)
  }

  deleteQuestion() {
    this.questionDeleted.emit(this.selectedQuestion)
  }

  changePatch(change: IInterviewQuestion) {
    if (!change) {
      this.resetPatch()
    } else {
      this.questionForm.patchValue({
        question: change.question,
        categories: change.categories,
        tags: change.tags,
        inputType: change.inputType,
      })
    }
  }

  resetPatch() {
    this.questionForm.patchValue({
      question: '',
      categories: [],
      tags: [],
      inputType: QuestionType.EssayOrVideo,
    })
  }

  cancelForm() {
    this.closeForm.emit()
    this.questionForm.reset()
    this.resetPatch()
  }

  makeQuestion(user: IEmployerUser) {
    let newQuestion: Optional<IInterviewQuestion, 'id'> = {
      question: this.f.question.value!,
      categories: this.f.categories?.value ?? [],
      tags: this.f.tags?.value ?? [],
      inputType: this.f.inputType.value,
      authorId: user.id!,
      authorName: user.displayName!,
      date: new Date(),
      customerKey: user.customerKey,
    }

    return newQuestion
  }

  onSubmitFormQuestion(user: IEmployerUser) {
    this.questionFormSubmitted = true
    if (this.questionForm.valid) {
      this.notify()
      this.questionFormSubmitted = false
      this.questionSubmitted.emit(this.makeQuestion(user))
      this.cancelForm()
    } else {
      this.questionForm.updateValueAndValidity()
      Validation.validateAll(this.questionForm)
    }
  }
}

const saveSuccessNotification: INotificationMessage = {
  title: 'Success',
  message: 'Your question was saved successfully!',
  type: 'success',
  autoClose: true,
}

import { Injectable } from '@angular/core'
import { Optional } from '@engineering11/types'
import { FirestoreQueryBuilder, OrderByDirection, SubcollectionRepository } from '@engineering11/web-api-firebase'
import { COLLECTIONS } from 'shared-lib'
import { IJobApplicationReview } from '../models/job-application-review.model'

function jobApplicationReviewPath(jobPostId: string, jobApplicationId: string, id = ''): string {
  return `${COLLECTIONS.JOB_POST}${jobPostId}${COLLECTIONS.JOB_POST_APPLICATION}${jobApplicationId}${COLLECTIONS.JOB_APPLICATION_REVIEW}${id}`
}

@Injectable({ providedIn: 'root' })
export class JobApplicationReviewRepository extends SubcollectionRepository<IJobApplicationReview, typeof jobApplicationReviewPath> {
  protected buildPath = jobApplicationReviewPath

  getAllValueChangesOrderedByDate(jobPostId: string, jobApplicationId: string, direction: OrderByDirection = OrderByDirection.Asc) {
    const dateKey: keyof IJobApplicationReview = 'createdDate'
    const queryBuilder = new FirestoreQueryBuilder()
    const sortByDate = queryBuilder.orderBy(dateKey, direction).build()
    return super.queryValueChanges(sortByDate, jobPostId, jobApplicationId)
  }

  add(model: Optional<IJobApplicationReview, 'id'>) {
    return super.add(model, model.jobPostId, model.jobApplicationId)
  }
  set(model: IJobApplicationReview): Promise<any> {
    return super.set(model, model.jobPostId, model.jobApplicationId, model.id)
  }
}

import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { INotificationHandler, INotificationView, UserAppNotificationService } from '@engineering11/notifications-web'
import { VirtualDialogueSubmittedNotificationForEmployer } from '../../../../../shared-lib/src/lib/model/app-notification.model'
import { CandidateDetailTabs, EmployerNavigationService } from '../navigation.service'

@Injectable({ providedIn: 'root' })
export class VirtualDialogueSubmittedEmployerHandler
  implements INotificationHandler<VirtualDialogueSubmittedNotificationForEmployer, INotificationView>
{
  notificationType = 'employer_interview_response_received'
  constructor(
    private router: Router,
    private userAppNotificationService: UserAppNotificationService,
    private navigationService: EmployerNavigationService
  ) {}

  onClick(notification: VirtualDialogueSubmittedNotificationForEmployer) {
    this.markAsRead(notification)
    return this.navigationService.toCandidateDetailPage(notification.jobPostId, notification.candidateApplicationId, CandidateDetailTabs.Dialogues)
  }

  private markAsRead(notification: VirtualDialogueSubmittedNotificationForEmployer) {
    if (notification.viewed) {
      return
    }
    this.userAppNotificationService.markNotificationAsRead(notification.id)
  }
}

import { ErrorHandlingConfig } from 'shared-lib'

export const ERROR_HANDLING: ErrorHandlingConfig = {
  USER_NAME_MIN_LENGTH: 8,
  USER_NAME_MAX_LENGTH: 50,

  PASSWORD_MIN_LENGTH: 8,
  PASSWORD_MAX_LENGTH: 24,

  EMAIL_MIN_LENGTH: 7,
  EMAIL_MAX_LENGTH: 100,

  FIRST_NAME_MIN_LENGTH: 1,
  FIRST_NAME_MAX_LENGTH: 100,

  LAST_NAME_MIN_LENGTH: 1,
  LAST_NAME_MAX_LENGTH: 100,

  COMPANY_NAME_MIN_LENGTH: 5,
  COMPANY_NAME_MAX_LENGTH: 100,

  PHONE_MIN_LENGTH: 14, // Takes into consideration auto formatting (US only currently)
  PHONE_MAX_LENGTH: 18,

  ADDRESS_MIN_LENGTH: 4,
  ADDRESS_MAX_LENGTH: 100,

  STATE_REQUIRED_LENGTH: 2,
  ZIP_MIN_LENGTH: 5,
  ZIP_MAX_LENGTH: 10,
  COUNTRY_REQUIRED_LENGTH: 2,
}

export const EMPLOYER_DEPARTMENT_NAME_MAX_LENGTH = 100
export const EMPLOYER_HERO_TITLE_MAX_LENGTH = 30
export const EMPLOYER_HERO_DESCRIPTION_MAX_LENGTH = 500

export const MAX_COMPANY_VIDEOS = 1 // seconds
export const MAX_DURATION_COMPANY_VIDEO = 120 // seconds
export const MAX_DURATION_JOB_POST_VIDEO = 120 // seconds

export const MIN_HEADER_IMAGE_WIDTH = 1621 // pixels
export const MIN_HEADER_IMAGE_HEIGHT = 1080 // pixels

export const MAX_FEEDBACK_TEXT_LENGTH = 600

import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { EmailActionsGuard } from '@employer/app/guards/email-actions.guard'
import { redirectLoggedInToHomeGuard } from '@engineering11/auth-web'
// This 'DenyGuard' is used to block users from gettign back to the 'auth' module post login
// All 'user' management should be done in a 'users' module. 'auth' is not reusable for user management
import { FileNotFoundComponent } from '../_404/404.component'
import { AuthenticationComponent } from './authentication.component'
import { ActionSuccessComponent } from './views/action-success/action-success.component'
import { AuthenticationCodeWallComponent } from './views/code-wall/code-wall.component'
import { EmailActionsComponent } from './views/email-actions/email-actions.component'
import { AuthenticationLoginComponent } from './views/login/login.component'
import { AuthenticationRecoverComponent } from './views/recover/recover.component'
import { NavItemNames } from '@employer/app/services/navigation.service'

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/auth/login',
  },
  {
    path: 'auth',
    component: AuthenticationComponent,
    children: [
      {
        path: 'login',
        component: AuthenticationLoginComponent,
        ...redirectLoggedInToHomeGuard(),
      },
      {
        path: 'login/:deepLink',
        component: AuthenticationLoginComponent,
        ...redirectLoggedInToHomeGuard(),
      },
      {
        path: 'recover',
        component: AuthenticationRecoverComponent,
        ...redirectLoggedInToHomeGuard(),
      },

      {
        path: 'actions',
        component: EmailActionsComponent,
        canActivate: [EmailActionsGuard],
      },
      {
        path: 'action-success/:mode',
        component: ActionSuccessComponent,
      },
      {
        path: '**',
        component: FileNotFoundComponent,
        data: { title: NavItemNames.FileNotFound },
      },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthenticationRoutingModule {}

import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { INotificationHandler, INotificationView, UserAppNotificationService } from '@engineering11/notifications-web'
import { IApplicationCommentCreatedNotification } from 'projects/shared-lib/src/lib/model/app-notification.model'
import { CandidateDetailTabs, EmployerNavigationService } from '../navigation.service'

@Injectable({ providedIn: 'root' })
export class ApplicationCommentNotificationHandler implements INotificationHandler<IApplicationCommentCreatedNotification, INotificationView> {
  notificationType = 'employer_new_comments_available'
  constructor(
    private router: Router,
    private userAppNotificationService: UserAppNotificationService,
    private navigationService: EmployerNavigationService
  ) {}

  onClick(notification: IApplicationCommentCreatedNotification) {
    this.markAsRead(notification)
    return this.navigationService.toCandidateDetailPage(notification.jobPostId, notification.candidateApplicationId, CandidateDetailTabs.Notes)
  }

  private markAsRead(notification: IApplicationCommentCreatedNotification) {
    if (notification.viewed) {
      return
    }
    this.userAppNotificationService.markNotificationAsRead(notification.id)
  }
}

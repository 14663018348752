import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { AuthConfigProvider } from '@employer/app/config/auth.config'
import { RegistrationConfigProvider } from '@employer/app/config/registration.config'
import { BetaService } from '@employer/app/services/beta/beta.service'
import { IBetaService } from '@employer/app/services/beta/beta.service.interface'
import { AuthModule } from '@engineering11/auth-web'
import { RegistrationModule } from '@engineering11/registration-web'
import { UserModule } from '@engineering11/user-web'
import { RecaptchaModule } from 'ng-recaptcha'
import { UiComponentLibraryModule } from '../_component-library/component-library.module'
import { SharedModule } from '../_shared/shared.module'
import { AuthenticationComponent } from './authentication.component'
import { AuthenticationRoutingModule } from './authentication.routing'
import { ActionSuccessComponent } from './views/action-success/action-success.component'
import { AuthenticationCodeWallComponent } from './views/code-wall/code-wall.component'
import { EmailActionsComponent } from './views/email-actions/email-actions.component'
import { AuthenticationLoginComponent } from './views/login/login.component'
import { AuthenticationRecoverComponent } from './views/recover/recover.component'
import { UserConfigProvider } from '@employer/app/config/user-config'

@NgModule({
  declarations: [
    AuthenticationComponent,

    AuthenticationLoginComponent,
    AuthenticationRecoverComponent,
    AuthenticationCodeWallComponent,
    EmailActionsComponent,
    ActionSuccessComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    AuthModule.forRoot({ configProvider: AuthConfigProvider }),
    RegistrationModule.forRoot({ configProvider: RegistrationConfigProvider }),
    UserModule.forRoot({ configProvider: UserConfigProvider }),
    AuthenticationRoutingModule,
    UiComponentLibraryModule,
  ],
  exports: [],
  providers: [{ provide: IBetaService, useClass: BetaService }],
})
export class AuthenticationModule {}

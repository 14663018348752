import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { IJobApplicationToken } from './job-post-application-share.model'
import { JobPostApplicationShareStore } from './job-post-application-share.store'
import { LetDirective } from '@ngrx/component'
import { DatePipe, NgClass, NgIf } from '@angular/common'
import { E11TooltipModule } from '@engineering11/ui-lib/e11-tooltip'
import { TranslateModule } from '@ngx-translate/core'
import { E11IconLoaderComponent } from '@engineering11/ui-lib/e11-icon'

@Component({
  selector: 'candidate-share-card',
  standalone: true,
  imports: [LetDirective, NgIf, E11TooltipModule, NgClass, TranslateModule, DatePipe, E11IconLoaderComponent],
  template: `
    <ng-container *ngrxLet="candidateSharesTokensLoaded$ as tokensLoaded">
      <div
        class="e11-border e11-border-skin-grey/50 hover:e11-border-skin-primary-accent hover:e11-shadow e11-rounded-lg e11-flex-col e11-justify-start e11-items-center e11-p-4"
      >
        <div class="e11-flex e11-justify-between e11-items-center">
          <p *ngIf="tokensLoaded" class="e11-font-bold e11-truncate e11-mb-0">{{ share.shareName }}</p>
          <p *ngIf="!tokensLoaded" class="e11-skeleton-empty e11-mb-1"></p>
          <div *ngIf="tokensLoaded">
            <e11-tooltip [color]="'dark'" tooltipText="{{ 'Copy a link to this shared application.' }}">
              <span
                (click)="copyShareClicked.emit(share)"
                class="material-icons-outlined e11-text-skin-primary-accent hover:e11-text-skin-primary hover:e11-cursor-pointer"
                >copy</span
              >
            </e11-tooltip>
          </div>
        </div>

        <div class="e11-flex e11-items-center e11-mb-1">
          <div class="e11-flex e11-items-center e11-text-skin-secondary" *ngIf="share.maskPersonalDetails && tokensLoaded">
            <e11-tooltip
              [color]="'dark'"
              [tooltipText]="'The candidates personal contact information is hidden from view in this shared application.' | translate"
            >
              <span class="material-icons-outlined md-18 e11-mr-2">disabled_visible</span>
            </e11-tooltip>
          </div>
          <p *ngIf="tokensLoaded" class="e11-truncate e11-mb-0" [ngClass]="{ 'e11-text-skin-secondary': share.maskPersonalDetails }">
            {{ share.candidateFirstName }} {{ share.candidateLastName }}
          </p>
          <p *ngIf="!tokensLoaded" class="!e11-w-24 e11-skeleton-empty e11-mb-1"></p>
        </div>

        <div *ngIf="tokensLoaded" class="e11-flex">
          <!-- Left -->
          <div class="e11-grow">
            <div class="e11-flex e11-items-center e11-mb-1">
              <p class="e11-inline-flex e11-text-xs e11-mr-1 e11-mb-0 ">
                {{ 'Date:' | translate }}
              </p>
              <span class="e11-text-xs">{{ share.__createdAt | date }}</span>
            </div>
            <div *ngIf="tokensLoaded" class="e11-flex e11-items-center ">
              <p class="e11-inline-block e11-text-xs e11-mr-1 e11-mb-0 ">
                {{ 'Expir. Date:' | translate }}
              </p>
              <span class="e11-text-xs e11-mb-0 ">{{ share.expiredDate | date }}</span>
            </div>
          </div>
          <!-- Right -->
          <div>
            <div *ngIf="tokensLoaded" class="e11-flex e11-justify-end e11-items-center">
              <div class="e11-flex">
                <e11-tooltip [color]="'dark'" tooltipText="{{ 'Delete this shared application.' }}">
                  <span
                    *ngIf="!deletingTokenIds.includes(share.id)"
                    (click)="deleteShareClicked.emit(share)"
                    class="material-icons-outlined e11-text-skin-secondary hover:e11-text-skin-danger hover:e11-cursor-pointer"
                    >delete</span
                  >
                </e11-tooltip>
                <e11-icon-loader *ngIf="deletingTokenIds.includes(share.id)" classOverrides="e11-text-skin-secondary"></e11-icon-loader>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!tokensLoaded">
          <p class="!e11-w-24 e11-mb-1 e11-skeleton-empty"></p>
          <p class="!e11-w-28 e11-mb-1 e11-skeleton-empty"></p>
        </div>
      </div>
    </ng-container>
  `,
})
export class CandidateShareCardComponent implements OnInit {
  @Input() share!: IJobApplicationToken
  @Input() showCandidateName: boolean = true
  @Input() deletingTokenIds: string[] = []

  @Output() copyShareClicked: EventEmitter<IJobApplicationToken> = new EventEmitter<IJobApplicationToken>()
  @Output() deleteShareClicked: EventEmitter<IJobApplicationToken> = new EventEmitter<IJobApplicationToken>()

  candidateSharesTokensLoaded$ = this.jobPostApplicationShareStore.tokensLoaded$

  constructor(private jobPostApplicationShareStore: JobPostApplicationShareStore) {}

  ngOnInit() {}
}

import { NgForOf } from '@angular/common'
import { Component, TemplateRef, ViewChild } from '@angular/core'
import {
  AutomationHelperGroupComponent,
  IAutomationHelperGroupItem,
} from '@employer/app/components/jobs-automation/partials/automation-helper-group.component'
import { E11CardComponent } from '@engineering11/ui-lib/e11-card'
import { E11ModalGlobalService } from '@engineering11/ui-lib/e11-modal'
import { TranslateModule } from '@ngx-translate/core'

@Component({
  selector: 'job-automation-requested-update-helper',
  standalone: true,
  imports: [TranslateModule, E11CardComponent, NgForOf, AutomationHelperGroupComponent],
  template: `
    <span (click)="openModal()" class="e11-text-skin-primary-accent e11-cursor-pointer hover:e11-text-skin-primary">{{ 'How this works' }}</span>
    <ng-template #content>
      <div class="e11-text-skin-primary-accent e11-flex e11-gap-2 e11-items-center e11-mb-4">
        <span class="material-icons md-24">auto_mode</span>
        <h3>{{ 'Automatically review applications and request missing information' | translate }}</h3>
      </div>
      <p>
        {{
          'Automate the laborious task of reviewing applications and asking for important pieces of information that were not included when the application was submitted. Choose the specific sections you want more information on and let this feature handle the rest.'
            | translate
        }}
      </p>

      <div class="e11-mb-4">
        <div>
          <h3 class="e11-mb-8">{{ 'What you do:' | translate }}</h3>
          <automation-helper-group [groupItems]="needToKnow" />
        </div>
        <hr class="e11-my-8" />
        <div>
          <h3 class="e11-mb-8">{{ 'What happens?' | translate }}</h3>
          <automation-helper-group [groupItems]="whatHappens" />
        </div>
      </div>
    </ng-template>
  `,
  styles: ``,
})
export class AutomationRequestedUpdateHelperComponent {
  @ViewChild('content') content!: TemplateRef<any>
  needToKnow: IAutomationHelperGroupItem[] = [
    {
      icon: 'checklist',
      title: 'Select Important Sections',
      color: 'primary-accent',
    },
    {
      icon: 'settings_suggest',
      title: 'Choose What Happens Next',
      color: 'secondary',
    },
  ]

  whatHappens: IAutomationHelperGroupItem[] = [
    {
      icon: 'insert_drive_file',
      title: 'Application Processed',
      description: 'The application is reviewed to see if all required information was supplied.',
      color: 'primary-accent',
    },
    {
      icon: 'chat',
      title: 'Applicant Contacted',
      description: 'The candidate is contacted using the message configuration requesting that they update their application. ',
      color: 'secondary',
    },
    {
      icon: 'add_task',
      title: 'Information Updated',
      description: 'The application is updated and set in the status or swimlane configured in this feature.',
      color: 'primary-accent',
    },
  ]

  constructor(private modalService: E11ModalGlobalService) {}

  openModal() {
    this.modalService.openModal({
      body: this.content,
      title: 'Automation Configuration - Requested Updates',
      width: 'xl',
    })
  }
}

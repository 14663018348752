import { ViewportScroller } from '@angular/common'
import { Component, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { ICustomDeactivationReason } from '@engineering11/registration-web'

@Component({
  templateUrl: './customer-home.component.html',
  styleUrls: ['./customer-home.component.scss'],
})
export class CustomerHomeComponent {
  customDeactivationReasons: ICustomDeactivationReason[] = [
    { reasonCode: '1', reasonText: 'Subscription costs' },
    { reasonCode: '2', reasonText: 'Difficulty using the site' },
    { reasonCode: '3', reasonText: 'No longer need to hire' },
    { reasonCode: '4', reasonText: 'Other' },
  ]

  constructor(private router: Router, private viewportScroller: ViewportScroller) {}

  accountDeactivated(event: any) {
    this.router.navigate(['/auth/deactivated'])
  }
}

import { DataType, TableOptions } from '@engineering11/ui-lib/e11-datatable'

export const autoQuestionsTableOptions: TableOptions = {
  keyField: 'name',
  selectable: false,
  enableDynamicFields: false,
  enableFilter: false,
  enableSorting: false,
  paginationSettings: {
    enabled: false,
    serverSide: false,
    pageLinks: 5,
    rowCount: 10,
    rowsPerPageOptions: [25, 50, 100],
  },
  columns: [
    {
      key: 'name',
      title: 'Name',
      type: DataType.TextField,
      visible: true,
      optional: false,
    },
    {
      key: 'swimlane',
      title: 'Trigger',
      type: DataType.TemplateField,
      visible: true,
      optional: false,
    },
    {
      key: 'questions',
      title: 'Questions',
      type: DataType.TemplateField,
      visible: true,
      optional: false,
      enableSort: false,
    },
    {
      key: 'messaging',
      title: 'Messaging',
      type: DataType.TemplateField,
      visible: true,
      optional: false,
      enableSort: false,
    },
    {
      key: 'action',
      title: '',
      type: DataType.TemplateField,
      visible: true,
      optional: false,
      enableSort: false,
    },
  ],
}

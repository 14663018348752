import { Injectable } from '@angular/core'
import { Optional } from '@engineering11/types'
import { compareDesc, sortWith, valueOf } from '@engineering11/utility'
import { Timestamp } from '@engineering11/web-api-firebase'
import { Observable, from } from 'rxjs'
import { map } from 'rxjs/operators'
import { AddInterviewQuestionRequest, IInterviewQuestion } from '../modules/interview-question-library/interview-question.model'
import { InterviewQuestionRepository } from '../repositories/interview-question.repository'

@Injectable({ providedIn: 'root' })
export class InterviewQuestionService {
  constructor(private repository: InterviewQuestionRepository) {}

  getAll(customerKey: string): Observable<Timestamp<IInterviewQuestion>[]> {
    const descByDate = compareDesc<Timestamp<IInterviewQuestion>>(valueOf('__updatedAt')) // Recent activity first
    return this.repository.getAllForCustomer(customerKey).pipe(map(questions => sortWith(questions, [descByDate])))
  }
  addQuestion(question: AddInterviewQuestionRequest) {
    return from(this.repository.add(question))
  }

  updateQuestion(question: IInterviewQuestion) {
    return from(this.repository.update(question))
  }

  deleteQuestion(questionId: string) {
    return from(this.repository.delete(questionId))
  }
}

import { IMultiSelectOption } from '@engineering11/ui-lib/e11-multi-select'
import { APPLICATION_STATE, MessagingSystemUserIds, RequestedUpdateFields, requestedUpdateFieldToLabel } from 'shared-lib'
import { IBaseAutomationFormData, ISendMessageFormData } from './job-automation.model'

export interface IRequestUpdatesFormData extends IBaseAutomationFormData {
  fields: RequestedUpdateFields[]
  newSwimlane: APPLICATION_STATE
}

export const requestedUpdateDefaults: IRequestUpdatesFormData = {
  fields: ['firstImpression'],
  enabled: false,
  newSwimlane: APPLICATION_STATE.SCREENED,
}

export const requestedUpdateInitialMessageDefaults: ISendMessageFormData = {
  enabled: false,
  message:
    'After reviewing your application, we would like to ask for you to provide a few additional pieces of information. You should see a request come through asking for some updates to your application to provide this information. Please supply the requested items and then resubmit your application to us.',
  senderId: MessagingSystemUserIds.hiring_team,
  delayDuration: 30,
  delayUnits: 'minutes',
}

export const requestedUpdateFollowUpDefaults: ISendMessageFormData = {
  enabled: false,
  message:
    'We wanted to send you a reminder that after reviewing your application, there were some additional pieces of information requested from you. You should have received a request asking for some updates to your application. Please take a minute to review this request, provide the additional information, and resubmit your application to us.',
  senderId: MessagingSystemUserIds.hiring_team,
  delayDuration: 2,
  delayUnits: 'days',
}

export const requestedUpdateWarningDefaults: ISendMessageFormData = {
  enabled: false,
  message:
    'We wanted to send you a final reminder that there were some additional pieces of information we requested from you regarding your application. Please take a minute to review our request to provide this information, and resubmit your updated application to us. If you do not respond to this request, you will be at risk of being marked as an unresponsive applicant for this job.',
  senderId: MessagingSystemUserIds.hiring_team,
  delayDuration: 9,
  delayUnits: 'days',
}

export const requestedFieldOptions: IMultiSelectOption[] = Object.entries(requestedUpdateFieldToLabel).map(([k, v]) => ({ label: v, value: k }))

export interface IRequestMoreFormData {
  requestedUpdate: IRequestUpdatesFormData
  initialMessage: ISendMessageFormData
  followUp: ISendMessageFormData
  warning: ISendMessageFormData
}

export const requestMoreFormDefaults: IRequestMoreFormData = {
  requestedUpdate: requestedUpdateDefaults,
  initialMessage: requestedUpdateInitialMessageDefaults,
  followUp: requestedUpdateFollowUpDefaults,
  warning: requestedUpdateWarningDefaults,
}

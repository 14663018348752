import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { IInterviewQuestion } from '@employer/app/modules/interview-question-library/interview-question.model'
import { InterviewQuestionStore } from '@employer/app/store/interview-question.store'
import { E11InputModule } from '@engineering11/ui-lib/e11-input'
import { E11InputCheckboxModule } from '@engineering11/ui-lib/e11-input-checkbox'
import { E11SelectModule, ISelectOption } from '@engineering11/ui-lib/e11-select'
import { isNotNil } from '@engineering11/utility'
import { Store } from '@ngrx/store'
import { TranslateModule } from '@ngx-translate/core'
import { Observable, filter, firstValueFrom, map } from 'rxjs'
import { getCurrentUserEmployer, IQuestion, QuestionType } from 'shared-lib'
import { LetDirective } from '@ngrx/component'
import { WebUtilityModule } from '@engineering11/web-utilities'
import {
  E11DynamicFormModule,
  e11FieldInputSelect,
  e11FieldInputText,
  e11FormFor,
  E11FormForType,
  e11FormLayoutInlineGrid,
  e11LayoutGroup,
} from '@engineering11/ui-lib/e11-form-builder'
import { IBuildSelectInterviewFormArgs } from '@employer/app/components/jobs-automation/job-automation.model'
import { ISearchInterviewFormData } from '@employer/app/components/jobs-automation/automation-auto-question/partials/automated-question-interview-form/automated-question-interview.model'

@Component({
  selector: 'search-job-interview',
  standalone: true,
  imports: [
    CommonModule,
    E11InputModule,
    E11SelectModule,
    E11InputCheckboxModule,
    E11DynamicFormModule,
    TranslateModule,
    ReactiveFormsModule,
    LetDirective,
    WebUtilityModule,
  ],
  template: `
    <ng-container>
      <div class="e11-pb-2 e11-w-full">
        <e11-dynamic-form-builder
          #searchInterviewForm
          [fieldGroups]="searchFormFieldGroup"
          [hideSubmitMenu]="true"
          (valueChanges)="handleValueChange($event)"
        >
        </e11-dynamic-form-builder>
      </div>
      <div class="e11-mb-4">
        <div class="e11-flex">
          <p class="e11-mr-2 e11-font-primary-demibold">{{ 'Questions' | translate }}</p>
          <p>{{ '(' + (questions$ | async)?.length + ' results)' | translate }}</p>
        </div>
        <div class="e11-border-t e11-border-b e11-p-2 e11-border-skin-app-borders e11-max-h-[350px] e11-overflow-y-scroll">
          <ng-container *ngrxLet="listLoaded$; let listLoaded">
            <div *ngIf="!listLoaded">
              <div class="e11-flex" *ngFor="let i of 12 | range">
                <e11-input-checkbox [disabled]="true"></e11-input-checkbox>
                <p class="e11-skeleton-loader"></p>
              </div>
            </div>
            <div *ngIf="listLoaded && (questions$ | async)?.length === 0" class="e11-text-center e11-items-center">
              <p class="e11-mb-0 e11-py-4">{{ 'No questions meet these search criteria!' | translate }}</p>
            </div>
            <div class="e11-flex e11-mb-4" *ngFor="let question of questions$ | async">
              <div class="e11-py-1">
                <e11-input-checkbox
                  #checkboxes
                  [color]="'primary-accent'"
                  [ring]="true"
                  [checked]="selectedQuestionIds.includes(question.id)"
                  [loading]="loadingQuestionIds.includes(question.id)"
                  (change)="onQuestionSelect.emit(question)"
                ></e11-input-checkbox>
              </div>
              <div class="e11-items-center">
                <p class="e11-mb-1 e11-line-clamp-5">{{ question.question | translate }}</p>
                <div class="e11-flex e11-text-sm e11-text-skin-light">
                  <ng-container *ngIf="question.inputType === QuestionType.EssayOrVideo; else singleIcon">
                    <span class="material-icons-outlined md-18 e11-mb-1 e11-mr-0.5"> article </span>
                    <span class="material-icons-outlined md-18 e11-mb-1 e11-mr-1"> videocam </span>
                  </ng-container>
                  <ng-template #singleIcon>
                    <span class="material-icons-outlined md-18 e11-mb-1 e11-mr-1">
                      {{ question.inputType === QuestionType.Video ? 'videocam' : 'article' }}
                    </span>
                  </ng-template>
                  <div class="sm:e11-flex">
                    <p class="e11-mr-1 e11-truncate e11-mb-0">{{ question.authorName + ' - ' }}</p>
                    <p class="e11-mb-0">{{ question.date | date : 'MMM dd yyyy ' }}</p>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  `,
})
export class SearchJobInterviewComponent implements OnInit {
  @Input() set selectedQuestions(questions: IInterviewQuestion[] | IQuestion[]) {
    this.selectedQuestionIds = questions.map(q => ('libraryId' in q && q.libraryId ? q.libraryId : q.id))
  }
  @Input() set loadingQuestions(questions: IInterviewQuestion[] | string[]) {
    this.loadingQuestionIds = questions.map(q => (typeof q === 'string' ? q : q.id))
  }
  @Output() onQuestionSelect = new EventEmitter<IInterviewQuestion>()

  QuestionType = QuestionType

  inputSelectOptions = [
    { name: 'All', value: '' },
    { name: 'Text', value: QuestionType.Essay },
    { name: 'Video', value: QuestionType.Video },
  ]

  selectedQuestionIds: string[] = []
  loadingQuestionIds: string[] = []

  authorSelectOptions: ISelectOption[] = []

  searchFormFieldGroup: E11FormForType<ISearchInterviewFormData> = this.buildForm({
    authorSelectOptions: this.authorSelectOptions,
    inputSelectOptions: this.inputSelectOptions,
  })

  searchFormData: ISearchInterviewFormData = {
    search: '',
    author: 'all',
    responseType: 'all',
  }

  user$ = this.store.pipe(getCurrentUserEmployer, filter(isNotNil))

  questions$ = this.interviewQuestionStore.filteredQuestions$
  listLoaded$ = this.interviewQuestionStore.listLoaded$

  authorOptions$: Observable<ISelectOption[]> = this.interviewQuestionStore.authors$.pipe(
    map(authors => Array.from(authors).map(a => ({ name: a, value: a })))
  )

  constructor(private store: Store, private interviewQuestionStore: InterviewQuestionStore) {}

  async ngOnInit() {
    const user = await firstValueFrom(this.user$)
    this.authorOptions$.subscribe(options => {
      this.authorSelectOptions = [{ name: 'All', value: '' }].concat(options)
      this.searchFormFieldGroup = this.buildForm({ authorSelectOptions: this.authorSelectOptions, inputSelectOptions: this.inputSelectOptions })
    })
    this.interviewQuestionStore.onGetAll(user.customerKey)
  }

  async handleValueChange(value: ISearchInterviewFormData) {
    this.interviewQuestionStore.updateSearchFilter(value.search)
    this.interviewQuestionStore.updateAuthorFilter(value.author)
    this.interviewQuestionStore.updateInputFilter(value.responseType)
  }

  buildForm({ authorSelectOptions, inputSelectOptions }: IBuildSelectInterviewFormArgs): E11FormForType<ISearchInterviewFormData> {
    return e11FormFor<ISearchInterviewFormData>()([
      e11LayoutGroup(
        [
          e11FormLayoutInlineGrid(
            [
              e11FieldInputText('search', '', undefined, {
                extraInputOption: { isRounded: true, trailingIcon: 'search', placeholder: 'Search' },
                hideInputError: true,
              }),
            ],
            {
              fieldsClass: 'e11-w-full md:e11-w-auto e11-mt-4',
            }
          ),
          e11LayoutGroup(
            [
              e11FormLayoutInlineGrid(
                [
                  e11FieldInputSelect('author', 'Author', authorSelectOptions, '', {
                    extraInputOption: {
                      size: 'sm',
                      value: { name: 'All', value: '' },
                    },
                    hideInputError: true,
                  }),
                  e11FieldInputSelect('responseType', 'Response Type', inputSelectOptions, '', {
                    extraInputOption: {
                      size: 'sm',
                      value: { name: 'All', value: '' },
                    },
                    hideInputError: true,
                  }),
                ],
                {
                  fieldsClass: 'e11-w-54',
                }
              ),
            ],
            {
              containerClass: 'e11-w-auto e11-flex',
            }
          ),
        ],
        {
          containerClass: 'e11-w-full',
          layoutContainerClass: 'e11-w-full e11-flex e11-flex-col md:e11-flex-row e11-gap-4 md:e11-gap-0 e11-justify-between e11-mb-4',
        }
      ),
    ])
  }
}

import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { E11DynamicFormBuilderComponent, e11FieldInputCheckBox, e11FormFor, e11FormLayoutInlineGrid } from '@engineering11/ui-lib/e11-form-builder'
import { ICheckboxOptions } from '@engineering11/ui-lib/e11-input-checkbox'
import { arrayToObject, isDeepEqual } from '@engineering11/utility'
import { APPLICATION_STATE, SwimlaneWatchConfig, applicationStateMappings } from 'shared-lib'

type SwimlaneNamesFormType = SwimlaneWatchConfig

const stateToLabel: Record<APPLICATION_STATE, string> = {
  [APPLICATION_STATE.APPLIED]: 'Applicant has applied for the position. No screening or communications have taken place.',
  [APPLICATION_STATE.SCREENED]: 'Applicant has been screened or has met the requirements to be considered for the position.',
  [APPLICATION_STATE.INTERVIEWING]: 'Applicant is actively being interviewed.',
  [APPLICATION_STATE.FINALIST]: 'Applicant is a finalist for the position.',
  [APPLICATION_STATE.OFFER_SENT]: 'An offer has been made and sent to the applicant.',
  [APPLICATION_STATE.HIRED]: 'An offer has been accepted by the applicant.',
  [APPLICATION_STATE.WITHDRAWN]: 'Applicant has withdrawn from consideration.',
  [APPLICATION_STATE.NOT_INTERESTED]: 'Applicant has been rejected by the Hiring Team for this position.',
  [APPLICATION_STATE.UNRESPONSIVE]: 'Applicant is unresponsive.',
}
const stateToSelectOption = (state: APPLICATION_STATE): ICheckboxOptions => ({
  name: applicationStateMappings.get(state)!,
  value: state,
  checkboxProps: {
    labelClassOverrides: 'e11-font-bold',
    description: stateToLabel[state],
    descriptionClassOverrides: 'e11-ml-6',
  },
})

const ORDERED_STATES = [
  APPLICATION_STATE.APPLIED,
  APPLICATION_STATE.SCREENED,
  APPLICATION_STATE.INTERVIEWING,
  APPLICATION_STATE.FINALIST,
  APPLICATION_STATE.OFFER_SENT,
  APPLICATION_STATE.HIRED,
  APPLICATION_STATE.UNRESPONSIVE,
  APPLICATION_STATE.NOT_INTERESTED,
  APPLICATION_STATE.WITHDRAWN,
]

const checkboxes = Object.values(ORDERED_STATES).map(state =>
  e11FieldInputCheckBox(state, '', [stateToSelectOption(state)], undefined, {
    extraInputOption: {},
  })
)

@Component({
  selector: 'swimlane-watch-config-form',
  template: `
    <e11-dynamic-form-builder
      #form
      [initialValue]="_initialValue"
      [fieldGroups]="fieldGroups"
      (valueChanges)="onValueChange($event)"
      (onSubmit)="onSave($event)"
      [hideSubmitMenu]="!showSaveButton"
      [loading]="loading"
      [disabled]="!isEditable"
    ></e11-dynamic-form-builder>
  `,
})
export class SwimlaneWatchConfigFormComponent {
  @ViewChild('form') form!: E11DynamicFormBuilderComponent<SwimlaneNamesFormType>

  @Input() set initialValue(initialValue: APPLICATION_STATE[] | undefined) {
    this._initialValue = this.checkPossibleValues(Object.values(APPLICATION_STATE), initialValue ?? [])
  }

  @Input() isEditable = true

  @Input() loading = false
  @Input() showSaveButton = true
  @Output() watchConfigUpdated = new EventEmitter<APPLICATION_STATE[]>()
  @Output() saveClicked = new EventEmitter<APPLICATION_STATE[]>()

  _initialValue: SwimlaneWatchConfig = arrayToObject(Object.values(APPLICATION_STATE), () => false)

  fieldGroups = e11FormFor<SwimlaneNamesFormType>()([
    e11FormLayoutInlineGrid(checkboxes, {
      containerClass: 'e11-grid e11-grid-cols-1 e11-items-center e11-justify-center md:e11-grid-cols-2 e11-gap-8 e11-mb-8',
    }),
  ])

  constructor() {}

  onValueChange(output: SwimlaneWatchConfig) {
    if (isDeepEqual(output, this._initialValue)) return
    const swimlanes = Object.keys(output).filter(key => output[key as APPLICATION_STATE])
    this.watchConfigUpdated.emit(swimlanes as APPLICATION_STATE[])
  }

  onSave(output: SwimlaneWatchConfig) {
    const swimlanes = Object.keys(output).filter(key => output[key as APPLICATION_STATE])
    this.saveClicked.emit(swimlanes as APPLICATION_STATE[])
  }

  checkPossibleValues<T extends string | number>(possibleValues: T[], actualValues: T[]) {
    const result: Record<T, boolean> = {} as Record<T, boolean>

    possibleValues.forEach(possibleValue => {
      result[possibleValue] = actualValues.includes(possibleValue)
    })

    return result
  }
}

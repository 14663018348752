import { IEmployerSettingDisplay } from './employer-setting.component'

export const aboutDisplay: IEmployerSettingDisplay = {
  title: 'About the Company',
  subtitle: 'Explain why they would want to work for you!',
  subtitle2:
    'This is the company’s main marketing statement. It is on the company’s Career’s Page and every Job Post. If you have a company image, or even better, a company introduction video this will appear directly under it.',

  visibility: 'If turned on this is displayed on your Careers Page and every Job Post.',
  tip: 'Tip: We recommend standard text no longer than about 600 characters, and that’s even a bit long.',
  tip2: 'There is no limit here but know too much could look bad and might not be read.',
}

export const eeoPolicyDisplay: IEmployerSettingDisplay = {
  title: 'EEO Policy Statement',
  subtitle: 'This is your Equal Employment Opportunity small print or the “legal disclaimer”. It’s important. You should have it filled out.',

  visibility: 'If turned on this is displayed on your Careers Page and every Job Post.',
  tip: 'Info: Put what you need to here just remember that this is actually the “fine print”. It is the small grey text at the very bottom of the page (Job Post and Career’s Page)',

  tipIcon: 'info',
}

export const cultureAndValueDisplay: IEmployerSettingDisplay = {
  title: 'Culture and Value Statement',
  subtitle: 'Let future candidates know what your company stands for to attract them to work with you.',

  visibility: 'If turned on this is displayed on the company’s Careers Page.',
  tip: 'Tip: Boast about why people would want to work at your company.',
  tip2: 'Talk about your values and what you have to offer.',
}

export const companyHeaderNameDisplay: IEmployerSettingDisplay = {
  title: 'Company Name Display',
  subtitle: 'Choose whether to show or hide your company’s name in the header of the Careers Page.',

  visibility: 'If turned on, the company name will not be displayed in the header of your Careers Page.',
  tip: 'Tip: Hiding the company name allows more space for your logo, making it stand out more prominently.',
}

export const companyWebsiteDisplay: IEmployerSettingDisplay = {
  title: 'Company Website',
  subtitle: 'Display your company website to inform candidates about the organization, product offerings, and corporate information.',

  visibility: 'If turned on this is displayed on the company’s Careers Page.',
  tip: 'Tip: Provide a direct link to your company’s website for easy access to more detailed information.',
  tip2: 'Ensure the website is up-to-date and reflects the current state of the company, its values, and its products/services.',
}

import { Injectable } from '@angular/core'
import { isNotNil } from '@engineering11/utility'
import { E11ErrorHandlerService } from '@engineering11/web-api-error'
import { ComponentStore, tapResponse } from '@ngrx/component-store'
import { Store } from '@ngrx/store'
import { firstValueFrom, Observable, switchMap } from 'rxjs'
import { filter, map, withLatestFrom } from 'rxjs/operators'
import { getCurrentUserEmployer } from 'shared-lib'
import { IJobApplicationReview } from '@employer/app/models/job-application-review.model'
import { JobApplicationReviewService } from '@employer/app/services/job-application-review.service'
import { JobApplicationStore } from '@employer/app/modules/jobs/stores/job-application.store'
import { E11NotificationsService } from '@engineering11/ui-lib/e11-notifications'

export type AddApplicationReviewPayload = Partial<Pick<IJobApplicationReview, 'starRating' | 'thumbRating' | 'text'>>

export interface JobApplicationReviewState {
  reviews: IJobApplicationReview[]
}

const defaultState: JobApplicationReviewState = {
  reviews: [],
}

@Injectable({
  providedIn: 'root',
})
export class JobApplicationReviewStore extends ComponentStore<JobApplicationReviewState> {
  constructor(
    private errorHandler: E11ErrorHandlerService,
    private jobApplicationReviewService: JobApplicationReviewService,
    private store: Store,
    private jobApplicationStore: JobApplicationStore,
    private notificationService: E11NotificationsService
  ) {
    super(defaultState)
  }

  // Selectors
  readonly getState = this.select(s => s)
  readonly reviews$ = this.select(s => s.reviews)
  readonly reviewByUser$ = (userId: string) => this.select(s => s.reviews.find(r => r.userId === userId))
  readonly currentUserReview$ = this.store.pipe(
    getCurrentUserEmployer,
    filter(isNotNil),
    switchMap(user => this.reviewByUser$(user.id))
  )

  // Effects
  readonly onLoadApplicationReviews = this.effect((_$: Observable<void>) =>
    _$.pipe(
      switchMap(_ => this.jobApplicationStore.candidateApplication$),
      filter(isNotNil),
      switchMap(application => this.jobApplicationReviewService.listenOrderedForApplication(application.jobPostId, application.id)),
      map(reviews => this.setJobApplicationReviews(reviews))
    )
  )

  readonly onAddApplicationReview = this.effect((payload$: Observable<AddApplicationReviewPayload>) =>
    payload$.pipe(
      withLatestFrom(this.store.pipe(getCurrentUserEmployer), this.jobApplicationStore.candidateApplication$, payload$),
      switchMap(async ([payload, user, application]) => {
        const existingReview = await firstValueFrom(this.reviewByUser$(user!.id))
        const model: IJobApplicationReview = {
          text: payload.text ?? existingReview?.text,
          thumbRating: payload.thumbRating ?? existingReview?.thumbRating,
          starRating: payload.starRating ?? existingReview?.starRating ?? 0,
          id: user!.id,
          customerKey: user!.customerKey,
          userId: user!.id,
          jobPostId: application!.jobPostId,
          jobApplicationId: application!.id,
          displayName: user!.displayName ?? `${user!.firstName} ${user!.lastName}`,
          profilePhoto: user!.photoURL,
          createdDate: new Date(),
          textUpdatedDate: payload.text ? new Date() : existingReview?.textUpdatedDate,
          ratingUpdatedDate: payload.starRating ? new Date() : existingReview?.ratingUpdatedDate,
        }
        return this.jobApplicationReviewService.addReviewForUser(model)
      }),
      tapResponse(
        () => {
          this.notificationService.popNotificationMessage({
            type: 'success',
            message: 'Your feedback has been submitted',
            title: 'Feedback submitted',
          })
        },
        (err: Error) => this.errorHandler.handleError(err)
      )
    )
  )

  // Reducers
  readonly setJobApplicationReviews = this.updater((state, reviews: IJobApplicationReview[]) => ({
    ...state,
    reviews,
  }))
}

import { Component, Input } from '@angular/core'
import { E11LoaderDotsModule } from '@engineering11/ui-lib/e11-loader-dots'

@Component({
  selector: 'automation-loader',
  standalone: true,
  imports: [E11LoaderDotsModule],
  template: ` <e11-loader-dots
    [active]="isLoading"
    [size]="'lg'"
    [label]="label"
    fontColor="e11-text-skin-white"
    [hasBackdrop]="true"
    [fullScreen]="true"
  ></e11-loader-dots>`,
})
export class AutomationLoaderComponent {
  @Input() isLoading: boolean = false
  @Input() label: string = 'Uploading attachment...'
}

<div class="e11-flex e11-flex-col e11-justify-center e11-py-6 e11-px-6">
  <div class="sm:e11-mx-auto sm:e11-w-full sm:e11-max-w-md">
    <h1 class="e11-mb-2 e11-text-center">
      {{ 'Employer Sign In' | translate }}
    </h1>
  </div>

  <div class="sm:e11-mx-auto sm:e11-w-full sm:e11-max-w-md">
    <e11-panel [bgOpacity]="90">
      <div class="e11-w-full">
        <auth-login (onSubmit)="onSubmit($event)" (onForgottenPassword)="onForgottenPassword()"></auth-login>
      </div>
    </e11-panel>
  </div>
</div>

import { Component } from '@angular/core'
import { EmployerNavigationService } from '@employer/app/services/navigation.service'

@Component({
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.scss'],
})
export class AuthenticationRecoverComponent {
  constructor(private navigationService: EmployerNavigationService) {}

  toLogin() {
    this.navigationService.toLogin()
  }
}

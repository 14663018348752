import { NgClass, NgForOf } from '@angular/common'
import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { E11DynamicFormModule, IE11DynamicComponent } from '@engineering11/ui-lib/e11-form-builder'
import { E11InputModule } from '@engineering11/ui-lib/e11-input'
import { APPLICATION_STATE, SharedLibModule } from 'shared-lib'
import { AutomatedQuestionSwimlanes } from '../../auto-questions.model'

@Component({
  selector: 'automated-question-trigger-swimlanes',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [E11InputModule, E11DynamicFormModule, NgForOf, SharedLibModule, NgClass],
  template: `
    <div class="e11-grid e11-grid-cols-1 e11-gap-4 md:e11-grid-cols-2 md:e11-gap-8">
      <div
        (click)="swimlaneSelected(swimlane)"
        *ngFor="let swimlane of supportedSwimlanes"
        class="e11-cursor-pointer e11-p-2 e11-border e11-rounded e11-flex e11-gap-2 hover:e11-shadow"
        [ngClass]="{ 'e11-bg-skin-success e11-text-skin-white': value === swimlane }"
      >
        <span class="material-icons-outlined">view_column</span>
        <div>
          <p class="e11-mb-2">{{ 'Swimlane - ' }}{{ swimlane | applicationState }}</p>
          <p class="e11-mb-0">
            {{ 'This trigger activates the desired action the first time a job application candidate is assigned the status of '
            }}{{ swimlane | applicationState }}{{ '.' }}
          </p>
        </div>
      </div>
    </div>
  `,
  styles: ``,
  providers: [],
})
export class AutomatedQuestionTriggerSwimlanesComponent implements IE11DynamicComponent {
  @Input() loading: boolean = false
  @Input() value: AutomatedQuestionSwimlanes | undefined = APPLICATION_STATE.SCREENED

  supportedSwimlanes: AutomatedQuestionSwimlanes[] = [
    APPLICATION_STATE.APPLIED,
    APPLICATION_STATE.SCREENED,
    APPLICATION_STATE.INTERVIEWING,
    APPLICATION_STATE.FINALIST,
  ]

  onChange: Function = () => {}
  onTouch: Function = () => {}
  onValidatorChange: Function = () => {}

  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn
  }

  writeValue(value: AutomatedQuestionSwimlanes): void {
    this.value = value
  }

  swimlaneSelected(swimlane: AutomatedQuestionSwimlanes) {
    this.value = swimlane
    this.onChange(swimlane)
    this.onValidatorChange()
  }
}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core'
import { ConfigStore } from '@engineering11/config-web'
import { ConversationStore, openConversationFilter } from '@engineering11/messaging-web'
import { Store } from '@ngrx/store'
import { ConversationTypes } from 'shared-lib'

@Component({
  selector: 'custom-messaging-nav',
  styleUrls: ['./messaging-nav.component.scss'],
  template: `
    <ul>
      <li
        (click)="isMessagingMenuOpen = !isMessagingMenuOpen"
        [ngClass]="{
          'e11-border-b-transparent e11-bg-skin-white/20 e11-text-white': isMessagingMenuOpen,
          'e11-border-b-skin-grey/30': !isMessagingMenuOpen
        }"
        class="e11-flex e11-rounded-md e11-h-12 e11-px-2 e11-justify-between e11-items-center e11-border-b hover:e11-text-white e11-cursor-pointer e11-mb-2"
      >
        <div class="e11-flex e11-items-center e11-w-full">
          <span class="material-icons e11-mr-2 md-18"> message </span>
          <span class="e11-font-medium e11-text-md e11-relative"
            >{{ 'Messages' | translate }}
            <e11-badge
              *ngIf="unreadConversationCount$ | async"
              [color]="'error'"
              [limit]="UNREAD_LIMIT"
              [posAdjustX]="'-4px'"
              [posAdjustY]="'-11px'"
              [size]="'xs'"
              [value]="(unreadConversationCount$ | async) ?? 0"
            ></e11-badge>
          </span>
        </div>

        <span [ngClass]="{ 'nav-icon-active': isMessagingMenuOpen }" class="material-icons-outlined e11-text-skin-extra-light nav-icon">
          expand_more
        </span>
      </li>

      <div
        [ngClass]="{ 'nav-messages-container-active e11-border-b e11-border-b-skin-grey/30': isMessagingMenuOpen }"
        class="nav-messages-container e11-flex e11-flex-col e11-gap-2"
      >
        <div class="nav-message-group">
          <ng-container *ngTemplateOutlet="groupHeader; context: { title: 'Application', subtitle: 'Direct Messages' }"></ng-container>
          <nav-item-direct-messages></nav-item-direct-messages>
        </div>
        <div class="nav-message-group" *ngIf="(features$ | async)?.jobApplicationChannels">
          <ng-container *ngTemplateOutlet="groupHeader; context: { title: 'Application', subtitle: 'Group Discussion' }"></ng-container>
          <nav-item-job-application-messages></nav-item-job-application-messages>
        </div>
        <div class="nav-message-group">
          <ng-container *ngTemplateOutlet="groupHeader; context: { title: 'Candidate Pool', subtitle: 'Direct Messages' }"></ng-container>
          <nav-item-candidate-pool-messages></nav-item-candidate-pool-messages>
        </div>
      </div>
    </ul>

    <ng-template #groupHeader let-title="title" let-subtitle="subtitle">
      <div class="e11-flex e11-font-medium e11-justify-between e11-items-center e11-mb-2 e11-px-2 e11-text-skin-white">
        <p class="e11-text-md e11-mb-0">{{ title | translate }}</p>
        <p class="e11-text-xs e11-mb-0">({{ subtitle | translate }})</p>
      </div>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessagingNavComponent implements OnInit {
  features$ = this.configStore.features$
  @Input() isMessagingMenuOpen = false

  unreadConversationCount$ = this.conversationStore.getUnreadConversationCountForQuery({
    types: [ConversationTypes.JobApplication, ConversationTypes.JobApplicationDM, ConversationTypes.CandidatePoolDM],
    customFilter: openConversationFilter,
  })

  UNREAD_LIMIT = 9

  constructor(private conversationStore: ConversationStore, private store: Store, private configStore: ConfigStore) {}

  ngOnInit() {}
}

import { Component, Input, OnInit } from '@angular/core'
import { E11TooltipModule } from '@engineering11/ui-lib/e11-tooltip'
import { TranslateModule } from '@ngx-translate/core'

@Component({
  selector: 'automation-delay-info',
  standalone: true,
  imports: [TranslateModule, E11TooltipModule],
  template: `
    <div class="e11-flex e11-gap-1 e11-items-center e11-font-semibold e11-mb-2">
      {{ 'How long do we wait before sending this message?' | translate }}
      <e11-tooltip [color]="'dark'" tooltipText="{{ toolTipText | translate }}">
        <span class="material-icons-outlined e11-text-skin-primary-accent md-18">help_outline</span>
      </e11-tooltip>
    </div>
  `,
})
export class AutomationDelayInfoComponent implements OnInit {
  @Input() toolTipText = 'This is delayed from the time of application submission.'
  constructor() {}

  ngOnInit() {}
}

import { JobContentItem } from '../stores/job-profile.store'
import { stripHTML } from '@engineering11/utility'

export interface IJobItemValidation {
  jobTitle: boolean
  employmentType: boolean
  description: boolean
  minimumQualifications: boolean
  responsibilities: boolean
  employeeLocation: boolean
  locationAddress: boolean
}

export function getJobItemValidation(jobItem: JobContentItem | null): IJobItemValidation {
  if (!jobItem || !jobItem.document) {
    return {
      jobTitle: false,
      employmentType: false,
      description: false,
      minimumQualifications: false,
      responsibilities: false,
      employeeLocation: false,
      locationAddress: false,
    }
  }
  const { jobTitle, employeeLocation, employmentType, description, minimumQualifications, responsibilities, locationAddress } = jobItem.document
  return {
    jobTitle: !!jobTitle,
    employeeLocation: !!employeeLocation,
    employmentType: !!employmentType,
    description: validateRichEditorContent(description ?? ''),
    minimumQualifications: validateRichEditorContent(minimumQualifications ?? ''),
    responsibilities: validateRichEditorContent(responsibilities ?? ''),
    locationAddress: !!locationAddress,
  }
}

function validateRichEditorContent(content: string): boolean {
  return !!stripHTML(content)
    .replace(/&nbsp;|&#160;/g, ' ')
    .trim()
}

import { NgModule } from '@angular/core'
import { canActivate } from '@angular/fire/compat/auth-guard'
import { RouterModule, Routes } from '@angular/router'
import { loggedInOnlyGuard } from '@engineering11/auth-web'
import { ConfigModule } from '@engineering11/config-web'
import { CustomerClientDataNotFoundComponent } from '@engineering11/customer-web'
import { AccountDeactivationComponent } from './components/deactivation/deactivation.component'
import { MainLayoutComponent } from './components/layout/main-layout.component'

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule),
  },
  {
    path: 'registration',
    loadChildren: () => import('./modules/registration/registration.module').then(m => m.RegistrationModule),
  },
  {
    path: 'actions',
    loadChildren: () => import('./modules/email-actions/email-actions.module').then(m => m.EmailActionsModule),
  },
  {
    path: 'public',
    loadChildren: () => import('./modules/public/public.module').then(m => m.PublicModule),
  },
  {
    path: 'about',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
    ...canActivate(loggedInOnlyGuard('/auth/login')),
  },
  {
    path: 'account-deactivated',
    component: AccountDeactivationComponent,
  },
  {
    path: 'privacy',
    redirectTo: 'public/privacy',
  },
  {
    path: 'terms',
    data: { title: 'Terms of Use' },
    redirectTo: 'public/terms',
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: 'home/404',
        pathMatch: 'full',
        redirectTo: '/jobs/404',
      },
      {
        path: 'home',
        redirectTo: 'jobs/list',
        pathMatch: 'full',
      },
      {
        path: 'jobs',
        loadChildren: () => import('./modules/jobs/jobs.module').then(m => m.JobsModule),
        ...canActivate(loggedInOnlyGuard('/auth/login')),
      },
      {
        path: 'candidate-pool',
        loadChildren: () => import('./modules/candidate-pool/candidate-pool.module').then(m => m.CandidatePoolModule),
        ...canActivate(loggedInOnlyGuard('/auth/login')),
      },
      {
        path: 'customer',
        loadChildren: () => import('./modules/customer/customer.module').then(m => m.CustomerModule),
        ...canActivate(loggedInOnlyGuard('/auth/login')),
      },
      {
        path: 'customer-clients',
        loadChildren: () => import('./modules/customer-clients/customer-clients.module').then(m => m.CustomerClientModule),
        ...canActivate(loggedInOnlyGuard('/auth/login')),
      },
      {
        path: 'account',
        loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule),
        ...canActivate(loggedInOnlyGuard('/auth/login')),
      },
      {
        path: 'users',
        loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule),
        ...canActivate(loggedInOnlyGuard('/auth/login')),
      },
      {
        path: 'notification',
        loadChildren: () => import('./modules/notification/notification.module').then(m => m.NotificationModule),
        ...canActivate(loggedInOnlyGuard('/auth/login')),
      },
      {
        path: 'c',
        loadChildren: () => import('./modules/layout-container/layout-container.module').then(m => m.LayoutContainerModule),
        ...canActivate(loggedInOnlyGuard('/auth/login')),
      },

      {
        path: '',
        redirectTo: 'jobs/list',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'tenant-not-found',
    component: CustomerClientDataNotFoundComponent,
  },
  { path: 'config', loadChildren: () => ConfigModule },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  { path: '404', pathMatch: 'full', redirectTo: '/jobs/404' },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: 'enabled',
      scrollOffset: [0, 0],
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      // enableTracing: true, // Comment in for nice router debugging
    }),
  ],
  // providers: [{ provide: RouteReuseStrategy, useClass: E11RouteReuseStrategy }],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Injectable } from '@angular/core'
import { IEmployerUser } from '@cnect/user-shared'
import { IJobPostContentAssociation, IJobPostContentItem, JobPostStatus } from '@employer/app/models/job-post.model'
import { ContentItemService, IContentPublishRequest, IContentRevision } from '@engineering11/content-web'
import { Optional } from '@engineering11/types'
import { firstValueFrom } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class JobPostContentService extends ContentItemService<IJobPostContentItem> {
  async savePost(user: IEmployerUser, jobPostContentItem: Optional<IJobPostContentItem, 'contentId'>): Promise<IJobPostContentItem> {
    const revision: IContentRevision = {
      userId: user.id!,
      firstName: user.firstName,
      lastName: user.lastName,
      customerKey: user.customerKey,
      description: 'Job post modified',
      contentId: jobPostContentItem.document.contentId,
    }
    const result = await super.persist(structuredClone(jobPostContentItem), revision)
    return result
  }

  async publishPost(user: IEmployerUser, _jobPostContentItem: IJobPostContentItem): Promise<IJobPostContentItem> {
    let jobPostContentItem = structuredClone(_jobPostContentItem)
    jobPostContentItem.document.status = JobPostStatus.Published
    jobPostContentItem = await this.savePost(user, jobPostContentItem)

    const publishLogRequest: IContentPublishRequest = {
      firstName: user.firstName,
      lastName: user.lastName,
      userId: user.id,
      document: jobPostContentItem.document,
    }
    // TODO: Opportunity to update the latestPublishedDate by using the response of the publish request
    await this.publish.add(publishLogRequest) // super.publish does not work, must use this.publish
    return jobPostContentItem
  }

  async deletePost(user: IEmployerUser, jobPostContentItem: IJobPostContentItem) {
    jobPostContentItem.document.status = JobPostStatus.Deleted
    const [summary, _] = await Promise.all([firstValueFrom(this.summary.get(jobPostContentItem.contentId)), this.update(jobPostContentItem)])
    if (summary) this.summary.delete(summary)
    const content = await firstValueFrom(this.get(jobPostContentItem.contentId))
    return content!
  }

  getLatestPublishedDate(contentId: string, customerKey: string) {
    return this.publish.getLatestPublishedDate(contentId, customerKey)
  }

  isPublishing(contentId: string, customerKey: string) {
    return this.publish.isPublishing(contentId, customerKey)
  }

  getPublishingItems(contentId: string, customerKey: string) {
    return this.publish.getAllPublishingLogsValueChanges(contentId, customerKey)
  }

  updateAssociations(contentId: string, associations: Map<string, IJobPostContentAssociation>) {
    return this.summary.updateAssociations(contentId, associations)
  }
}

import { Component } from '@angular/core'
@Component({
  template: `
    <e11-panel>
      <div class="e11-w-full e11-flex e11-mb-6 e11-items-start">
        <div class="e11-grow">
          <p class="e11-font-bold e11-mb-0">{{ 'Manage your companys departments' | translate }}</p>
          <p class="e11-mb-0 e11-text-sm">{{ 'These are used to assign specific departments to job posts.' | translate }}</p>
        </div>
        <div class="e11-flex e11-justify-end">
          <e11-button
            id="submit_account"
            value="{{ 'New Department' | translate }}"
            [icon]="'add'"
            [iconPosition]="'left'"
            [iconSize]="'md-14'"
            [size]="'sm'"
            [color]="'primary-accent'"
            [type]="'button'"
            (click)="departments.addClicked()"
          >
          </e11-button>
        </div>
      </div>
      <sdk-customer-departments #departments [suppressHeader]="true"></sdk-customer-departments>
    </e11-panel>
  `,
})
export class CustomerDepartmentsComponent {
  constructor() {}
}

import { Component, EventEmitter, Input, Output } from '@angular/core'
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms'
import { JobPostApplicationShareStore } from '@employer/app/modules/jobs/components/candidate-share-profile/job-post-application-share.store'
import { E11NotificationMessage, E11NotificationsService } from '@engineering11/ui-lib/e11-notifications'
import { E11Logger } from '@engineering11/web-api-error'
import { FormCustomValidationService, IJobPostApplication, InArrayPipe, ProfileSection, ProfileSectionIconsMap, ProfileSectionPipe } from 'shared-lib'
import {
  CreateJobApplicationTokenDTO,
  maskedFields,
} from '@employer/app/modules/jobs/components/candidate-share-profile/job-post-application-share.model'
import { E11InputErrorsModule, Validation } from '@engineering11/ui-lib/e11-input-errors'
import { NgClass, NgForOf } from '@angular/common'
import { E11InputModule } from '@engineering11/ui-lib/e11-input'
import { TranslateModule } from '@ngx-translate/core'
import { E11TooltipModule } from '@engineering11/ui-lib/e11-tooltip'
import { E11RadioInputModule } from '@engineering11/ui-lib/e11-radio-input'
import { E11SelectorModule } from '@engineering11/ui-lib/e11-selector'
import { CandidateShareProfilePreviewComponent } from '@employer/app/modules/jobs/components/candidate-share-profile/candidate-share-profile-preview.component'
import { E11ButtonComponent } from '@engineering11/ui-lib/e11-button'

@Component({
  selector: 'ui-candidate-share-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgClass,
    E11InputModule,
    E11InputErrorsModule,
    TranslateModule,
    E11TooltipModule,
    E11RadioInputModule,
    E11SelectorModule,
    NgForOf,
    InArrayPipe,
    ProfileSectionPipe,
    CandidateShareProfilePreviewComponent,
    E11ButtonComponent,
  ],
  template: `
    <form [formGroup]="shareApplicationForm" (ngSubmit)="candidateProfileShare()" novalidate>
      <div class="e11-grid e11-grid-cols-1 md:e11-grid-cols-2 e11-gap-2 md:e11-gap-8 e11-mb-8 md:e11-mb-0">
        <div>
          <p class="e11-font-bold e11-mb-1" [ngClass]="{ 'e11-text-skin-error': f.shareName.invalid && f.shareName.touched }">
            {{ 'Share name' | translate }}
          </p>
          <e11-input
            [type]="'text'"
            formControlName="shareName"
            name="shareName"
            placeholder="{{ 'Application Share Name...' | translate }}"
            id="id_profileShareName"
            [parentForm]="shareApplicationForm"
            autocomplete="off"
            [required]="true"
            [hasError]="f.shareName.invalid && f.shareName.touched"
            [maxLength]="profileNameCharLimit"
            [enforceCharacterLimit]="true"
          >
            <div class="e11-flex e11-w-full">
              <div class="e11-grow">
                <e11-input-errors [parentForm]="shareApplicationForm" [formControl]="f.shareName" label="{{ 'Name' | translate }}"></e11-input-errors>
              </div>
              <div class="e11-flex e11-justify-end e11-text-xs e11-text-skin-light">
                <span class="">{{ f.shareName.value ? f.shareName.value.length : 0 }} / {{ profileNameCharLimit }}</span>
              </div>
            </div>
          </e11-input>
        </div>
        <div class="">
          <div class="e11-flex">
            <p class="e11-font-bold e11-mr-1 e11-mb-1 e11-line-clamp-1">{{ "Share candidate's contact info?" | translate }}</p>
            <e11-tooltip [color]="'dark'" tooltipText="{{ shareTooltip | translate }}">
              <span class="material-icons-outlined md-14 e11-text-skin-primary-accent hover:e11-text-skin-primary e11-float-left">help_outline</span>
            </e11-tooltip>
          </div>
          <e11-radio-input
            [id]="'id_includeCandidateDetails_yes'"
            name="includeCandidateDetails"
            [value]="f.includeCandidateDetails.value === true"
            [radioValue]="'true'"
            label="{{ '&nbsp;Yes. Show candidate contact info.' | translate }}"
            [color]="'primary-accent'"
            (valueChange)="f.includeCandidateDetails.setValue(true)"
            [size]="'sm'"
            [required]="true"
          >
          </e11-radio-input>
          <e11-radio-input
            [id]="'id_includeCandidateDetails_no'"
            name="includeCandidateDetails"
            [value]="f.includeCandidateDetails.value === false"
            [radioValue]="'false'"
            label="{{ '&nbsp;No. Hide candidate contact info.' | translate }}"
            [color]="'primary-accent'"
            (valueChange)="f.includeCandidateDetails.setValue(false)"
            [size]="'sm'"
            [required]="true"
          >
          </e11-radio-input>
        </div>
      </div>

      <div class="e11-block" [ngClass]="{ 'e11-flex-col e11-gap-4': !f.includeCandidateDetails.value }">
        <div class="e11-w-full e11-mb-4">
          <p class="e11-font-bold e11-mb-2">{{ 'Select the application sections to be displayed:' | translate }}</p>
          <div class="e11-mb-6">
            <e11-selector
              *ngFor="let section of profileSections"
              [size]="'md'"
              [color]="'success'"
              [hasCheckmark]="true"
              [label]="section | profileSection"
              [selected]="section | inArray : selectedSections"
              [selectedIcon]="'done'"
              [selectedIconSize]="'md-14'"
              (toggled)="toggleSection(section, $event)"
              title="{{
                (selectedSections.includes(section) ? 'Selected and visible on shared application' : 'Not displayed on the shared application')
                  | translate
              }}"
              [containerClassOverrides]="'e11-float-left e11-mr-2 e11-mb-2'"
            ></e11-selector>
          </div>
        </div>
      </div>

      <div class="e11-w-full e11-flex e11-items-end e11-justify-end e11-gap-4">
        <e11-button
          [type]="'button'"
          [ghost]="true"
          value="{{ 'Preview' | translate }}"
          [color]="'primary-accent'"
          (click)="previewApplicationShare()"
        ></e11-button>
        <e11-button [type]="'submit'" value="{{ 'Save' | translate }}" [color]="'primary-accent'"></e11-button>
      </div>
    </form>
  `,
})
export class CandidateShareFormComponent {
  @Input({ required: true }) jobPostId!: string
  @Input({ required: true }) jobApplicationId!: string
  @Input() candidateApplication!: IJobPostApplication | undefined

  @Output() formSubmitted = new EventEmitter<any>()

  shareApplicationForm = this.formBuilder.group({
    shareName: new FormControl(null, [this.customValidator.whitespaceValidator(), Validators.required, Validators.minLength(1)]),
    includeCandidateDetails: new FormControl(true, [Validators.required]),
  })

  profileNameCharLimit = 24
  shareTooltip =
    'Choosing "yes" will include all candidate details and profile sections. Choosing "no" will hide the candidate\'s name, email, phone number, address, and website.'

  profileSections: ProfileSection[] = Object.values(ProfileSection)
  selectedSections: ProfileSection[] = this.profileSections // no default sections selected

  profileSectionIconsMap = ProfileSectionIconsMap

  constructor(
    private formBuilder: FormBuilder,
    private customValidator: FormCustomValidationService,
    private notificationsService: E11NotificationsService,
    private jobPostApplicationShareStore: JobPostApplicationShareStore,
    private logger: E11Logger
  ) {}

  get f() {
    return this.shareApplicationForm.controls as {
      [key: string]: FormControl
    }
  }

  async candidateProfileShare() {
    const formData = this.shareApplicationForm.value

    if (this.shareApplicationForm.invalid) {
      this.shareApplicationForm.updateValueAndValidity()
      Validation.validateAll(this.shareApplicationForm)
      this.logger.log('shareApplicationForm', this.shareApplicationForm)
      return
    }

    const share: CreateJobApplicationTokenDTO = {
      jobApplicationId: this.jobApplicationId,
      jobPostId: this.jobPostId,
      shareName: formData.shareName!,
      maskPersonalDetails: !formData.includeCandidateDetails,
      sections: this.selectedSections,
    }
    this.logger.log(share)

    this.jobPostApplicationShareStore.onCreateShare(share)
    this.notificationsService.popNotificationMessage(copySuccessMessage)
    this.formSubmitted.emit(share)
    this.shareApplicationForm.reset({ includeCandidateDetails: formData.includeCandidateDetails }, { emitEvent: false })
    this.selectedSections = this.profileSections
  }

  toggleSection(section: ProfileSection, selected: boolean) {
    if (selected) {
      this.selectedSections = [...this.selectedSections, section]
      return
    }
    this.selectedSections = this.selectedSections.filter(selectedSection => selectedSection !== section)
  }

  previewApplicationShare() {
    const sections = this.f.includeCandidateDetails.value ? [...this.selectedSections, ...maskedFields] : this.selectedSections
    this.jobPostApplicationShareStore.onGenerateSharePreview({
      sections,
      application: this.candidateApplication!,
    })
  }
}

const copySuccessMessage: E11NotificationMessage = {
  title: 'Share Link Copied',
  message: `Your candidate share has been copied to your clipboard! View in Manage Shared Candidates tab!`,
  type: 'success',
  autoClose: true,
  dismissOnRouteChange: true,
}

import { JobPostStatus } from '@employer/app/models/job-post.model'
import { IToken } from '@engineering11/access-web'
import { Optional, Timestamp } from '@engineering11/types'
import { IJobPostApplication, ProfileSection } from 'shared-lib'

export const JOB_APPLICATION_SHARE_TOKEN_TYPE = 'jobApplicationShare'

export interface IJobApplicationTokenData {
  jobPostId: string
  jobApplicationId: string
  candidateId: string
  candidateFirstName: string
  candidateLastName: string
  shareName: string
  type: typeof JOB_APPLICATION_SHARE_TOKEN_TYPE
  maskPersonalDetails: boolean
  sections: ProfileSection[]
  link?: string
}

export interface IJobApplicationShareData {
  jobTitle: string
  jobPostStatus: JobPostStatus
  jobApplication: IMaskedJobPostApplication
  /**The short link of the share */
  link: string
}

export const maskedFields = ['firstName', 'lastName', 'email', 'phoneNumber', 'address', 'website'] as const
export type MaskedFields = (typeof maskedFields)[number]

export type IMaskedJobPostApplication = Optional<IJobPostApplication, MaskedFields>

export type IJobApplicationToken = Timestamp<IToken<IJobApplicationTokenData>>

export type CreateJobApplicationTokenDTO = Pick<
  IJobApplicationTokenData,
  'shareName' | 'jobApplicationId' | 'jobPostId' | 'maskPersonalDetails' | 'sections'
>

import { Component, Input } from '@angular/core'
import { IMaskedJobPostApplication } from '@employer/app/modules/jobs/components/candidate-share-profile/job-post-application-share.model'
import { ListMode, SharedLibModule, UserMode } from 'shared-lib'
import { TranslateModule } from '@ngx-translate/core'
import { NgForOf, NgIf } from '@angular/common'
import { E11EmptyStateComponent } from '@engineering11/ui-lib/e11-empty-state'

@Component({
  selector: 'shared-application-right-sections',
  standalone: true,
  imports: [NgIf, TranslateModule, SharedLibModule, NgForOf, E11EmptyStateComponent],
  template: `
    <ng-container>
      <!-- Skills -->
      <div *ngIf="candidateApplication && candidateApplication.skills?.length" class="section-wrapper e11-mb-8">
        <div class="e11-flex e11-w-full e11-items-center e11-border-b e11-border-skin-grey/50 e11-pb-2 e11-mb-4">
          <div class="e11-grow">
            <h4 class="e11-font-skin-secondary e11-font-bold">
              {{ 'Skills' | translate }}
            </h4>
          </div>
        </div>
        <div *ngIf="!candidateApplication" class="e11-panel-skeleton"></div>
        <skills-result
          *ngIf="candidateApplication"
          [missingSkills]="candidateApplication.missingSkills"
          [skills]="candidateApplication.skills"
          [userMode]="userMode"
          [showRequestButton]="showRequestButton"
          [pdfMode]="pdfMode"
        ></skills-result>
      </div>
      <!-- Certs -->
      <div *ngIf="candidateApplication && candidateApplication.certifications?.length" class="section-wrapper e11-mb-8">
        <div class="e11-flex e11-w-full e11-items-center e11-border-b e11-border-skin-grey/50 e11-pb-2 e11-mb-4">
          <div class="e11-grow">
            <h4 class="e11-font-skin-secondary e11-font-bold">
              {{ 'Certifications' | translate }}
            </h4>
          </div>
        </div>
        <div *ngIf="!candidateApplication" class="e11-panel-skeleton"></div>
        <certifications-result
          *ngIf="candidateApplication"
          [certifications]="candidateApplication.certifications"
          [missingCertifications]="candidateApplication.missingCertifications"
          [userMode]="userMode"
          [showRequestButton]="showRequestButton"
          [pdfMode]="pdfMode"
        ></certifications-result>
      </div>
      <!-- Portfolio -->
      <div *ngIf="candidateApplication && candidateApplication.portfolio?.length" class="section-wrapper e11-mb-8">
        <manage-portfolio
          *ngIf="candidateApplication"
          [showRequestButton]="showRequestButton"
          [userMode]="userMode"
          [portfolio]="candidateApplication.portfolio || []"
          [pdfMode]="pdfMode"
          [cleanURL]="cleanURL"
        ></manage-portfolio>
      </div>
      <!-- References -->
      <div *ngIf="candidateApplication && candidateApplication.references?.length" class="section-wrapper e11-mb-8">
        <div class="e11-flex e11-w-full e11-items-center e11-border-b e11-border-skin-grey/50 e11-pb-2 e11-mb-4">
          <div class="e11-grow">
            <h4 class="e11-font-skin-secondary e11-font-bold">
              {{ 'References' | translate }}
            </h4>
          </div>
        </div>
        <div *ngIf="!candidateApplication" class="e11-panel-skeleton"></div>
        <ui-reference
          *ngIf="candidateApplication"
          [userMode]="userMode"
          [listMode]="listMode"
          [references]="candidateApplication.references || null"
        ></ui-reference>
      </div>
      <!-- Social Media -->
      <div *ngIf="candidateApplication && candidateApplication.socialMedia" class="section-wrapper e11-mb-8">
        <div class="e11-flex e11-w-full e11-items-center e11-border-b e11-border-skin-grey/50 e11-pb-2 e11-mb-4">
          <div class="e11-grow">
            <h4 class="e11-font-skin-secondary e11-font-bold">
              {{ 'Social Media' | translate }}
            </h4>
          </div>
        </div>
        <div *ngIf="!candidateApplication" class="e11-panel-skeleton"></div>
        <view-social-media
          *ngIf="candidateApplication"
          [socialMedia]="candidateApplication.socialMedia"
          [readonlyMode]="true"
          [userMode]="userMode"
          [showRequestButton]="showRequestButton"
        ></view-social-media>
      </div>
      <!-- Languages -->
      <div *ngIf="candidateApplication && candidateApplication.languages?.length" class="section-wrapper e11-mb-8">
        <div class="e11-flex e11-w-full e11-items-center e11-border-b e11-border-skin-grey/50 e11-pb-2 e11-mb-4">
          <div class="e11-grow">
            <h4 class="e11-font-skin-secondary e11-font-bold">
              {{ 'Languages' | translate }}
            </h4>
          </div>
        </div>
        <div *ngIf="!candidateApplication" class="e11-panel-skeleton"></div>
        <ui-language
          *ngFor="let item of candidateApplication?.languages"
          [loading]="!candidateApplication"
          [language]="item"
          [closer]="false"
          [userMode]="userMode"
        ></ui-language>
        <e11-empty-state
          *ngIf="!candidateApplication?.languages?.length"
          [icon]="'language'"
          [iconSize]="'md-36'"
          title="{{ 'No Languages' | translate }}"
          [clickable]="false"
          subtitle="{{ 'Candidate did not supply any languages.' | translate }}"
        >
        </e11-empty-state>
      </div>
      <!-- Military Affiliation -->
      <div *ngIf="candidateApplication && candidateApplication.militaryAffiliation?.length" class="section-wrapper e11-mb-8">
        <div class="e11-flex e11-w-full e11-items-center e11-border-b e11-border-skin-grey/50 e11-pb-2 e11-mb-4">
          <div class="e11-grow">
            <h4 class="e11-font-skin-secondary e11-font-bold">
              {{ 'Military Affiliation' | translate }}
            </h4>
          </div>
        </div>
        <div *ngIf="!candidateApplication" class="e11-panel-skeleton"></div>
        <view-military-affiliations
          *ngIf="candidateApplication"
          [militaryAffiliations]="candidateApplication.militaryAffiliation"
          [readonlyMode]="true"
          [userMode]="userMode"
          [showRequestButton]="showRequestButton"
        ></view-military-affiliations>
      </div>
    </ng-container>
  `,
})
export class SharedApplicationRightSectionsComponent {
  @Input() pdfMode: boolean = false
  @Input() cleanURL!: string
  @Input() candidateApplication!: IMaskedJobPostApplication

  listMode: ListMode = ListMode.Vertical
  userMode: UserMode = UserMode.Employer
  showRequestButton = false
}

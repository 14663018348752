<div class="container-view">
  <app-header-register *ngIf="header === 'register' || header === 'deactivation'"> </app-header-register>
  <app-header-login *ngIf="header === 'login' || header === 'recover'"></app-header-login>
  <app-header-landing *ngIf="isLanding"> </app-header-landing>

  <main class="authentication" [ngClass]="{ registration: header === 'register' }">
    <div class="e11-container-full container-content block employer-margin-top">
      <router-outlet></router-outlet>
    </div>
  </main>
</div>

import { Injectable, inject } from '@angular/core'
import { environment } from '@employer/environments/environment'
import { IDispositionCode, IJobPostApplication, TokenStorage } from 'shared-lib'
import { RestApiClient } from '@engineering11/web-api-rest'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { E11ErrorHandlerService, isE11ErrorOfType } from '@engineering11/web-api-error'

export type DispositionCodesRequest = Pick<IJobPostApplication, 'id' | 'jobPostId'>

@Injectable({ providedIn: 'root' })
export class JobsApiService {
  private errorHandler = inject(E11ErrorHandlerService)
  private restClient: RestApiClient
  constructor(private tokenStorage: TokenStorage) {
    this.restClient = new RestApiClient({
      baseUrl: environment.services.jobs,
      token: () => tokenStorage.getAccessToken(),
    })
  }

  getJobProfileSearchKey(): Observable<string> {
    return this.restClient.get<string>(`search-keys/job-profile`).pipe(map(item => item.data))
  }
  getJobPostSearchKey(): Observable<string> {
    return this.restClient.get<string>(`search-keys/job-post`).pipe(map(item => item.data))
  }
  getJobPostCandidateKey(jobPostId: string): Observable<string> {
    return this.restClient.get<string>(`search-keys/candidate/${jobPostId}`).pipe(map(item => item.data))
  }
  getAllCandidatesSearchKey() {
    const userId = this.tokenStorage.getItem('user')?.id
    return this.restClient.get(`search-keys/candidate/${userId}/all`).pipe(map(item => item.data))
  }

  getApplicationDispositionCodes(request: DispositionCodesRequest) {
    const { id, jobPostId } = request
    return this.restClient.get<IDispositionCode[]>(`job-posts/${jobPostId}/job-post-applications/${id}/disposition-codes`).pipe(
      map(item => item.data),
      catchError(err => {
        if (isE11ErrorOfType(err, 'compliance/not-found')) this.errorHandler.handleError(err, { level: 'warning' })
        else this.errorHandler.handleError(err, { alertUser: true })
        return of([])
      })
    )
  }
}

<div class="header-container e11-w-full">
  <header class="header-landing e11-container-full e11-mx-auto">
    <div class="e11-w-full e11-flex e11-items-center">
      <div class="e11-w-2/3 e11-flex e11-items-center" [routerLink]="['/home']">
        <img [src]="logoUrl" class="e11-h-24" />
      </div>
      <div class="col-xs-4 flex-align-center-justify-end" *ngIf="!loggedIn">
        <e11-button color="secondary" [routerLink]="['/auth/login']" value="{{ 'Sign In' | translate | lowercase }}" [buttonGroup]="true">
        </e11-button>
      </div>
      <div class="e11-w-2/3 e11-flex e11-items-center e11-justify-end" *ngIf="loggedIn">
        <e11-button color="secondary" (click)="signOut()" value="{{ 'Sign Out' | translate | lowercase }}" [buttonGroup]="true"> </e11-button>
      </div>
    </div>
  </header>
</div>

import { Injectable } from '@angular/core'
import { MESSAGING_NOTIFICATION_IDS, MessagingOperationService } from '@engineering11/messaging-web'
import { INotificationHandler, INotificationView, UserAppNotificationService } from '@engineering11/notifications-web'
import { UserMentionedNotification } from 'shared-lib'

@Injectable({ providedIn: 'root' })
export class MessagingUserMentionedEmployerHandler implements INotificationHandler<UserMentionedNotification, INotificationView> {
  notificationType: string = MESSAGING_NOTIFICATION_IDS.messaging_user_mentioned
  constructor(private userAppNotificationService: UserAppNotificationService, private messagingOperationService: MessagingOperationService) {}

  onClick(notification: UserMentionedNotification): boolean | Promise<boolean> {
    if (!notification.viewed) {
      this.userAppNotificationService.markNotificationAsRead(notification.id)
    }
    this.messagingOperationService.navigateToConversationFromId(notification.conversationId)
    return true
  }
}

import { Component, inject, Input, TemplateRef, ViewChild } from '@angular/core'
import { E11ModalGlobalService } from '@engineering11/ui-lib/e11-modal'
import { E11TooltipModule } from '@engineering11/ui-lib/e11-tooltip'
import { TranslateModule } from '@ngx-translate/core'

@Component({
  selector: 'automation-upgrade-prompt',
  standalone: true,
  imports: [TranslateModule, E11TooltipModule],
  template: `
    <div
      class="e11-text-skin-primary-accent hover:e11-text-skin-primary e11-flex e11-mb-4 e11-items-center e11-cursor-pointer e11-w-fit"
      (click)="upgradeClicked()"
    >
      <span class="material-icons md-18 e11-mr-2"> help_outline</span>
      <span class="e11-text-sm">{{ 'Why am I unable to edit this?' | translate }}</span>
    </div>
    @defer () {
    <ng-template #upgradeModalBody>
      <div class="e11-text-skin-primary-accent e11-flex e11-gap-2 e11-items-center e11-mb-4">
        <span class="material-icons md-24">help_outline</span>
        <h3>{{ 'Why am I unable to edit this?' | translate }}</h3>
      </div>
      <div class="2xl:e11-max-w-screen-md">
        <p>
          {{ 'If you are unable to edit the automation settings for ' | translate }}
          <span class="e11-text-skin-danger e11-font-primary-bold">{{ automationType }}</span>
          {{
            ' it is most likely  because the plan your company currently has only includes the basic version of automation, so the message text, delays, and message sender can’t be edited.'
              | translate
          }}
        </p>
        <p>
          {{
            'To be able to edit the message as well as change the message sender you will need to upgrade to the premium version of automation. You will then be able to fully customize the message text that gets sent out, including the ability to send videos and attachments.'
              | translate
          }}
        </p>

        <p>
          {{
            'To learn more and change your plan contact your account Administrator or contact a Cnect Customer Success representative.' | translate
          }}
        </p>
      </div>
    </ng-template>
    }
  `,
})
export class AutomationUpgradePromptComponent {
  @ViewChild('upgradeModalBody') upgradeModalBody!: TemplateRef<any>
  @Input({ required: true }) automationType!: 'Application Auto Response' | 'Requested Update' | 'Automated Questions'
  #modalGlobalService = inject(E11ModalGlobalService)

  constructor() {}

  upgradeClicked() {
    this.#modalGlobalService.openModal({
      title: `Automation Configuration - ${this.automationType}`,
      body: this.upgradeModalBody,
      width: 'lg',
    })
  }
}

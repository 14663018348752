import { Component, Input, ViewChild } from '@angular/core'
import { E11ConfirmationComponent, E11ConfirmationModule } from '@engineering11/ui-lib/e11-confirmation'
import { E11NotificationsService } from '@engineering11/ui-lib/e11-notifications'
import { e11Error, E11Logger } from '@engineering11/web-api-error'
import { copyToClipboard } from '@engineering11/web-utilities'
import { IJobApplicationToken } from './job-post-application-share.model'
import { JobPostApplicationShareStore } from './job-post-application-share.store'
import { LetDirective } from '@ngrx/component'
import { CandidateShareCardComponent } from '@employer/app/modules/jobs/components/candidate-share-profile/candidate-share-card.component'
import { JsonPipe, NgForOf, NgIf } from '@angular/common'
import { TranslateModule } from '@ngx-translate/core'
import { E11EmptyStateComponent } from '@engineering11/ui-lib/e11-empty-state'

@Component({
  selector: 'candidate-shares-list',
  standalone: true,
  imports: [LetDirective, CandidateShareCardComponent, NgForOf, E11ConfirmationModule, TranslateModule, E11EmptyStateComponent, NgIf, JsonPipe],
  template: `
    <div *ngrxLet="candidateShares$ as shares" class="e11-pb-36">
      <div
        *ngrxLet="deletingTokenIds$ as deletingTokenIds"
        class="e11-grid e11-grid-cols-1 lg:e11-grid-cols-2 xl:e11-grid-cols-3 2xl:e11-grid-cols-4 e11-gap-4"
      >
        <candidate-share-card
          *ngFor="let share of shares"
          [share]="share"
          [showCandidateName]="showCandidateName"
          [deletingTokenIds]="deletingTokenIds"
          (deleteShareClicked)="deleteShare($event)"
          (copyShareClicked)="copyShareLink($event)"
        ></candidate-share-card>
      </div>

      <e11-confirmation
        #confirmDelete
        confirmTitle="{{ 'Delete Candidate Share' | translate }}"
        confirmText="{{ 'Delete' | translate }}"
        cancelText="{{ 'Cancel' | translate }}"
        [backdropStyle]="'dark'"
        (answer)="confirmDeletion($event)"
      >
        {{ 'Are you sure you want to delete this candidate share?' | translate }}
      </e11-confirmation>

      <ng-container *ngrxLet="candidateSharesTokensLoaded$ as tokensLoaded">
        <e11-empty-state
          *ngIf="!shares.length && tokensLoaded"
          [title]="'You have no shares for this job post'"
          subtitle="{{ emptyStateSubTitle | translate }}"
          [clickable]="false"
        ></e11-empty-state>
      </ng-container>
    </div>
  `,
})
export class CandidateSharesListComponent {
  @ViewChild('confirmDelete') confirmDelete!: E11ConfirmationComponent
  @Input() showCandidateName: boolean = true

  deletingTokenIds$ = this.jobPostApplicationShareStore.deletingTokenIds$

  candidateShares$ = this.jobPostApplicationShareStore.jobPostShares$
  candidateSharesTokensLoaded$ = this.jobPostApplicationShareStore.tokensLoaded$

  emptyStateSubTitle = "Go to the candidate's tab to share a candidate's application!"

  tokenStagedForDeletion?: IJobApplicationToken

  constructor(
    private jobPostApplicationShareStore: JobPostApplicationShareStore,
    private logger: E11Logger,
    private notificationService: E11NotificationsService
  ) {}

  generate = (i: number) => new Array(i)

  deleteShare(stashToken: IJobApplicationToken) {
    this.tokenStagedForDeletion = stashToken
    this.confirmDelete.open()
  }

  confirmDeletion(shouldDelete: boolean) {
    if (shouldDelete && this.tokenStagedForDeletion) this.jobPostApplicationShareStore.onDeleteShare(this.tokenStagedForDeletion)
  }

  async copyShareLink(shareToken: IJobApplicationToken) {
    const link = shareToken.link
    if (!link) {
      throw e11Error({ title: 'Share link missing', type: 'share/copy-link-missing', alertUser: true, additionalData: shareToken })
    }
    copyToClipboard(link)

    this.notificationService.popNotificationMessage({
      title: 'Application Share URL copied',
      message: 'Link copied to clipboard',
      type: 'success',
      autoClose: true,
      dismissOnRouteChange: true,
    })
  }
}

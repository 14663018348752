import { Component, Input } from '@angular/core'
import { environment } from '@employer/environments/environment'
import { sleep } from '@engineering11/utility'
import { copyToClipboard } from '@engineering11/web-utilities'

@Component({
  selector: 'careers-page-link',
  template: `
    <div class="e11-flex e11-w-full e11-mb-2">
      <div class="e11-grow">
        <h2 class="e11-text-skin-primary-accent">{{ 'Careers Page' | translate }}</h2>
      </div>
      <div class="e11-flex e11-justify-end">
        <e11-button
          id="submit_preview"
          value="{{ 'Careers Page' | translate }}"
          color="primary-accent"
          [ghost]="true"
          [size]="'sm'"
          [icon]="'open_in_new'"
          [iconPosition]="'left'"
          [iconSize]="'md-14'"
          [skeletonLoading]="loading"
          (click)="preview()"
        >
        </e11-button>
      </div>
    </div>

    <div class="e11-border-b e11-border-skin-grey/50 e11-mb-8">
      <h4 class="e11-font-primary-bold e11-w-3/4 e11-mb-2">{{ 'The URL / Link' | translate }}</h4>
      <p class="!e11-mb-4 e11-w-3/4">
        {{
          'This is the location of your Cnect careers page that displays all of your Cnect Job Posts. Use this URL to control the redirect from your company website over to the cnect Careers Page.'
            | translate
        }}
      </p>
      <p *ngIf="loading" class="e11-skeleton"></p>
      <p *ngIf="!loading">
        <a
          href="{{ linkToCareersPage }}"
          target="_blank"
          rel="noopener noreferrer"
          class="e11-text-skin-primary-accent hover:e11-text-skin-primary e11-skeleton-empty"
          >{{ linkToCareersPage }}</a
        >
      </p>
      <div class="e11-flex e11-w-full e11-pb-4">
        <div class="e11-flex e11-items-center e11-text-skin-primary-accent hover:e11-text-skin-primary e11-cursor-pointer" (click)="copyLink()">
          <span class="material-icons-outlined e11-mr-2" *ngIf="!showCopiedMessage">content_copy</span>
          <span class="material-icons-outlined e11-mr-2" *ngIf="showCopiedMessage">done</span>
          <span *ngIf="!showCopiedMessage">{{ 'Copy link' | translate }}</span>
          <span *ngIf="showCopiedMessage">{{ 'Copied to clipboard' | translate }}</span>
        </div>

        <div class="e11-flex e11-items-center e11-ml-4 e11-text-skin-primary-accent hover:e11-text-skin-primary e11-cursor-pointer">
          <a
            href="{{ linkToCareersPage }}"
            target="_blank"
            rel="noopener noreferrer"
            class="e11-flex e11-items-center e11-text-skin-primary-accent hover:e11-text-skin-primary"
          >
            <span class="material-icons-outlined e11-mr-2">open_in_new</span>
            <span>{{ 'Visit link' | translate }}</span>
          </a>
        </div>
      </div>
    </div>
  `,
})
export class CareersPageLinkComponent {
  linkToCareersPage?: string
  loading = true

  @Input() set employerId(employerId: string | undefined) {
    if (!employerId) return
    this.loading = false
    this.linkToCareersPage = `${this.linkToCareersPageBaseURL}?client=${employerId}`
  }

  linkToCareersPageBaseURL = environment.careersPageBaseLink
  showCopiedMessage = false
  constructor() {}

  preview() {
    window.open(this.linkToCareersPage, '_blank')
  }

  async copyLink() {
    if (!this.linkToCareersPage) return
    this.showCopiedMessage = true
    copyToClipboard(this.linkToCareersPage)
    await sleep(2000)
    this.showCopiedMessage = false
  }
}

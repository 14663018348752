import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { E11ModalComponent, E11ModalModule } from '@engineering11/ui-lib/e11-modal'
import { JobPostApplicationShareStore } from './job-post-application-share.store'
import { IJobPostApplication } from 'shared-lib'
import { TranslateModule } from '@ngx-translate/core'
import { LetDirective } from '@ngrx/component'
import { CandidateShareFormComponent } from '@employer/app/components/algolia/ui-alg-card/ui-candidate-share-form.component'
import { NgClass, NgIf } from '@angular/common'
import { E11DividerComponent } from '@engineering11/ui-lib/e11-divider'
import { E11LoaderDotsModule } from '@engineering11/ui-lib/e11-loader-dots'
import { CandidateSharesListComponent } from '@employer/app/modules/jobs/components/candidate-share-profile/candidate-shares-list.component'
import { CandidateShareProfilePreviewComponent } from '@employer/app/modules/jobs/components/candidate-share-profile/candidate-share-profile-preview.component'

@Component({
  selector: 'candidate-profile-share-modal',
  standalone: true,
  imports: [
    TranslateModule,
    E11ModalModule,
    LetDirective,
    CandidateShareFormComponent,
    NgClass,
    E11DividerComponent,
    E11LoaderDotsModule,
    NgIf,
    CandidateSharesListComponent,
    CandidateShareProfilePreviewComponent,
  ],
  template: `
    <e11-modal
      #modalCandidateProfileShare
      [width]="'lg'"
      [bodyHeight]="'large'"
      [showHeader]="true"
      [closer]="false"
      [showFooter]="false"
      [title]="'Manage Shared Applications' | translate"
      [hideCloseButton]="false"
      [headerClassOverrides]="'e11-text-skin-primary-accent'"
    >
      <ng-template e11Template="Body">
        <!-- <h3 class="e11-text-skin-primary-accent">New shared application for:</h3> -->
        <p class="e11-line-clamp-1">
          <span class="e11-font-bold">{{ 'New share:' | translate }} {{ candidateApplication?.firstName }} {{ candidateApplication?.lastName }} </span
          ><span *ngIf="jobPostTitle">( {{ jobPostTitle }} )</span>
        </p>
        <ng-container *ngrxLet="preview$ as preview">
          <div class="e11-flex e11-flex-col" [ngClass]="{ 'e11-hidden': preview }">
            <div class="e11-w-full e11-mb-8 ">
              <div class="e11-border e11-border-skin-primary-accent/50 e11-rounded-md e11-p-4">
                <ui-candidate-share-form
                  [jobApplicationId]="jobApplicationId"
                  [jobPostId]="jobPostId"
                  [candidateApplication]="candidateApplication"
                ></ui-candidate-share-form>
              </div>
            </div>
            <div class="e11-mb-4">
              <h3 class="e11-text-skin-primary-accent e11-mb-2">{{ 'Shared Applications' | translate }}</h3>
              <e11-divider [margin]="0"></e11-divider>
            </div>
            <div class="e11-w-full">
              <ng-container *ngrxLet="tokensLoaded$ as tokensLoaded">
                <e11-loader-dots
                  *ngIf="!tokensLoaded"
                  [size]="'md'"
                  [color]="'e11-bg-skin-primary-accent'"
                  label="{{ '...loading...' | translate }}"
                ></e11-loader-dots>

                <div *ngIf="tokensLoaded">
                  <ng-container *ngrxLet="candidateShares$ as shares">
                    <candidate-shares-list [showCandidateName]="false"></candidate-shares-list>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </div>
          <candidate-share-profile-preview *ngIf="preview" [applicationShare]="preview"></candidate-share-profile-preview>
        </ng-container>
      </ng-template>
    </e11-modal>
  `,
})
export class CandidateProfileShareModalComponent implements OnInit {
  @ViewChild('modalCandidateProfileShare') modalCandidateProfileShare!: E11ModalComponent
  @Input({ required: true }) jobPostId!: string
  @Input({ required: true }) jobApplicationId!: string
  @Input() jobPostTitle?: string | undefined = undefined
  @Input() candidateApplication?: IJobPostApplication

  candidateShares$ = this.jobPostApplicationShareStore.jobPostShares$
  tokensLoaded$ = this.jobPostApplicationShareStore.tokensLoaded$
  preview$ = this.jobPostApplicationShareStore.preview$

  constructor(private jobPostApplicationShareStore: JobPostApplicationShareStore) {}

  openModal() {
    this.modalCandidateProfileShare?.open()
    this.jobPostApplicationShareStore.onGetAllByJobApplication({ jobPostId: this.jobPostId, jobApplicationId: this.jobApplicationId })
  }

  ngOnInit() {}
}

import { Injectable } from '@angular/core'
import { RestApiClient } from '@engineering11/web-api-rest'
import { Store } from '@ngrx/store'
import { getCurrentToken } from 'shared-lib'
import { isNotNil } from '@engineering11/utility'
import { filter, map } from 'rxjs/operators'
import { environment } from '@employer/environments/environment'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private restApiClient: RestApiClient

  constructor(private store: Store) {
    this.restApiClient = new RestApiClient({
      baseUrl: environment.services.auth,
      token: store.select(getCurrentToken).pipe(filter(isNotNil)),
    })
  }

  public customLoginToken() {
    return this.restApiClient.get<{ token: string; tenantId: string }>('/custom-token').pipe(map(item => item.data))
  }
}

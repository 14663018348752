import { Component } from '@angular/core'
import { AppConfigService } from '@engineering11/tenant-bootstrap-web'

@Component({
  selector: 'app-header-login',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class AppHeaderLoginComponent {
  navigateToCandidate() {
    window.location.href = 'https://network.cnected.com/'
  }

  logoUrl = this.appConfig.config.brand.logoImageDarkUrl
  constructor(private appConfig: AppConfigService) {}
}

import { IMarkdownFile } from '@engineering11/ui-lib/e11-markdown-editor'
import { JOBS_AUTOMATION_MULTIMEDIA_CONFIG_OPTIONS } from './automation.constants'
import { IFileReceipt } from '@engineering11/files-web'

type ValidateFileResponse = { status: false; message: string; title: string } | { status: true }

export function validateFile(markdownFile: IMarkdownFile[], receiptFile: IFileReceipt[]): ValidateFileResponse {
  const maxNumberOfFiles = JOBS_AUTOMATION_MULTIMEDIA_CONFIG_OPTIONS.maxNumberOfFiles
  const totalFilesLength = markdownFile.length + receiptFile.length
  const maxFileSize = JOBS_AUTOMATION_MULTIMEDIA_CONFIG_OPTIONS.maxFileSize
  const maxFileSizeInBytes = maxFileSize * 1024 * 1024

  if (markdownFile.some(file => file.file.size > maxFileSizeInBytes)) {
    return {
      status: false,
      message: `Each file must be smaller than ${maxFileSize} MB.`,
      title: 'File size exceeded',
    }
  }
  if (totalFilesLength > maxNumberOfFiles) {
    return {
      status: false,
      message: `You can only upload ${maxNumberOfFiles} files.`,
      title: 'Max number of files exceeded',
    }
  }
  return { status: true }
}

import { Component, EventEmitter, Input, Output } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ReplaceValues } from '@engineering11/types'
import { MODERN_URL_REGEX } from '@engineering11/utility'
import { FormCustomValidationService } from 'shared-lib'

export enum SettingMode {
  TextEditor = 'TextEditor',
  UrlInput = 'UrlInput',
  SwitchOnly = 'SwitchOnly',
}
export interface EmployerSettingData {
  setting: string
  switchOn: boolean
}

export interface IEmployerSettingDisplay {
  title: string
  subtitle: string
  subtitle2?: string

  visibility: string
  tip: string
  tip2?: string

  tipIcon?: 'lightbulb' | 'info'
}

type EmployerSettingForm = ReplaceValues<EmployerSettingData, FormControl>

@Component({
  selector: 'employer-setting',
  template: `
    <form [formGroup]="settingForm" novalidate>
      <div class="form-section e11-mb-16">
        <div class="e11-w-full e11-mb-2">
          <div class="e11-w-full">
            <h4 class="e11-font-primary-bold">{{ settingDisplay.title | translate }}</h4>
            <p>
              {{ settingDisplay.subtitle | translate }}
              <br />
              {{ settingDisplay.subtitle2 ?? '' | translate }}
            </p>
          </div>
          <div *ngIf="loading" class="e11-skeleton-empty !e11-h-20 !e11-w-full"></div>
          <e11-text-editor *ngIf="!loading && settingMode === SettingMode.TextEditor" [required]="false" formControlName="setting"> </e11-text-editor>
          <e11-input
            *ngIf="!loading && settingMode === SettingMode.UrlInput"
            [type]="'url'"
            name="companyWebsite"
            id="companyWebsite"
            placeholder="{{ 'https://' | translate }}"
            [required]="false"
            formControlName="setting"
            [autofocus]="false"
            [parentForm]="settingForm"
          >
            <e11-input-errors [parentForm]="settingForm" [formControl]="f.setting" label="{{ 'New Company Website' | translate }}"></e11-input-errors>
          </e11-input>
        </div>
        <div *ngIf="!loading" class="e11-w-full e11-flex e11-flex-wrap md:e11-flex-nowrap e11-gap-4">
          <div class="e11-grow e11-mr-4">
            <div class="e11-flex e11-items-start e11-mb-2">
              <div class="">
                <e11-switch
                  [checked]="f.switchOn.value"
                  (changes)="f.switchOn.setValue($event)"
                  [formControlName]="'switchOn'"
                  [size]="'xs'"
                ></e11-switch>
              </div>
              <div class="">
                <p class="e11-font-primary-demibold e11-mb-0">
                  {{ settingMode === SettingMode.SwitchOnly ? ('Hide' | translate) : ('On' | translate) }}
                </p>
              </div>
            </div>
            <div class="e11-flex e11-flex-col e11-gap-0">
              <p class="e11-flex e11-text-sm e11-gap-1 e11-mb-0">
                <span class="material-icons-outlined e11-text-sm">visibility</span>
                {{ settingDisplay.visibility | translate }}
              </p>
              <p class="e11-flex e11-text-sm e11-gap-1 e11-mb-0">
                <span class="material-icons-outlined e11-text-sm e11-text-skin-success">{{ settingDisplay.tipIcon ?? 'lightbulb' }}</span>
                {{ settingDisplay.tip | translate }}
                <br />
                {{ settingDisplay.tip2 ?? '' | translate }}
              </p>
            </div>
          </div>
          <div class="e11-flex e11-items-start e11-justify-end">
            <e11-button (click)="onSubmit()" [type]="'submit'" color="primary-accent" value="{{ 'Save' | translate }}"> </e11-button>
          </div>
        </div>
      </div>
    </form>
  `,
})
export class EmployerSettingComponent {
  SettingMode = SettingMode
  @Input() settingMode: SettingMode = SettingMode.TextEditor
  @Input() loading = false
  @Input() settingDisplay!: IEmployerSettingDisplay
  @Input() set settingData(settingData: Partial<EmployerSettingData>) {
    this.settingForm.patchValue({ setting: settingData.setting ?? '', switchOn: settingData.switchOn ?? false }, { emitEvent: false })
  }

  @Output() settingSubmitted = new EventEmitter<EmployerSettingData>()

  settingForm = new FormGroup<EmployerSettingForm>({
    setting: new FormControl('', this.settingMode === SettingMode.UrlInput ? Validators.pattern(MODERN_URL_REGEX) : null),
    switchOn: new FormControl(),
  })

  constructor(private customValidator: FormCustomValidationService) {}

  get f() {
    return this.settingForm.controls
  }

  onSubmit() {
    if (!this.settingForm.valid) return
    const formValue = this.settingForm.value as EmployerSettingData
    this.settingSubmitted.emit(formValue)
  }
}

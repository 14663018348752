import { Component, Input } from '@angular/core'
import { isNotNil } from '@engineering11/utility'
import { IRequestedUpdate } from 'shared-lib'

type IconStatus = 'none' | 'pending' | 'completed'
type CountType = { pendingCount?: number; completedCount?: number }
@Component({
  selector: 'ui-alg-card-icons',
  template: `
    <div class="e11-inline-flex e11-gap-1 e11-py-1 e11-items-center e11-text-sm">
      <!-- REQUESTED UPDATES -->
      <span class="e11-flex e11-items-center e11-mr-1 e11-text-skin-light">
        <e11-tooltip [color]="'dark'" [tooltipText]="getRequestedUpdatesTooltipText(_requestedUpdatesStatus)">
          <span class="material-icons-outlined md-18" [ngClass]="getStatusClass(_requestedUpdatesStatus)">quiz</span>
        </e11-tooltip>
      </span>

      <!-- FEEDBACK -->
      <span class="e11-flex e11-items-center e11-mr-1 e11-text-skin-light">
        <e11-tooltip [color]="'dark'" [tooltipText]="hasFeedback ? 'Feedback available' : 'No feedback given'">
          <span class="material-icons-outlined md-18" [ngClass]="hasFeedback ? 'e11-text-skin-primary-accent' : 'e11-text-skin-grey'"
            >rate_review</span
          >
        </e11-tooltip>
      </span>

      <!-- VIRTUAL DIALOGUES -->
      <ng-template #tooltipContent>
        <div
          [innerHTML]="getVirtualDialoguesTooltipText(_virtualDialoguesStatus, {
          pendingCount: _pendingVirtualDialogueCounts,
          completedCount: _completedVirtualDialogueCounts,
        })"
        ></div>
      </ng-template>
      <span class="e11-flex e11-items-center e11-mr-1 e11-text-skin-light">
        <e11-tooltip [color]="'dark'" [tooltipContent]="tooltipContent">
          <span class="material-icons-outlined md-18" [ngClass]="getStatusClass(_virtualDialoguesStatus)">forum</span>
        </e11-tooltip>
      </span>

      <!-- APPLICATION SHARES -->
      <span class="e11-flex e11-items-center e11-mr-1 e11-text-skin-light">
        <e11-tooltip [color]="'dark'" [tooltipText]="getApplicationSharesTooltipText(totalApplicationShares)">
          <span class="material-icons-outlined md-18" [ngClass]="getTotalApplicationSharesClass(totalApplicationShares)">share</span>
        </e11-tooltip>
      </span>
    </div>
  `,
})
export class JobsApplicationInfoIconsComponent {
  _pendingRequestedUpdateCounts?: number = 0
  _completedRequestedUpdateCounts?: number = 0
  _requestedUpdatesStatus: IconStatus = 'none'

  @Input() set requestedUpdates(requestedUpdates: IRequestedUpdate[] | undefined) {
    this._pendingRequestedUpdateCounts = requestedUpdates?.filter(request => !request.fulfilled).length || 0
    this._completedRequestedUpdateCounts = requestedUpdates?.filter(request => request.fulfilled).length || 0
    this._requestedUpdatesStatus = this.calculateStatus({
      pendingCount: this._pendingRequestedUpdateCounts,
      completedCount: this._completedRequestedUpdateCounts,
    })
  }

  @Input() hasFeedback: boolean = false
  _pendingVirtualDialogueCounts: number = 0
  _completedVirtualDialogueCounts: number = 0
  _virtualDialoguesStatus: IconStatus = 'none'

  @Input() set virtualDialogueCounts(dialogueCounts: CountType | undefined) {
    this._pendingVirtualDialogueCounts = dialogueCounts?.pendingCount ?? 0
    this._completedVirtualDialogueCounts = dialogueCounts?.completedCount ?? 0
    this._virtualDialoguesStatus = this.calculateStatus({
      pendingCount: this._pendingVirtualDialogueCounts,
      completedCount: this._completedVirtualDialogueCounts,
    })
  }

  @Input() totalApplicationShares?: number = 0

  getRequestedUpdatesTooltipText(status: IconStatus): string {
    if (status === 'none') {
      return 'No outstanding requests'
    } else if (status === 'pending') {
      return 'Pending requests for more'
    } else {
      return 'Requested info supplied'
    }
  }

  getVirtualDialoguesTooltipText(status: IconStatus, { pendingCount, completedCount }: CountType): string {
    if (status === 'none') return 'No interview sessions'
    return `
        <div>Pending interview sessions: ${pendingCount}</div>
        <div>Completed interview sessions: ${completedCount}</div>
      `
  }

  getStatusClass(status: IconStatus) {
    return {
      'e11-text-skin-primary-accent': status === 'completed',
      'e11-text-skin-secondary': status === 'pending',
      'e11-text-skin-grey': status === 'none',
    }
  }

  getTotalApplicationSharesClass(count?: number) {
    return {
      'e11-text-skin-primary-accent': count && count > 0,
      'e11-text-skin-grey': !count || count === 0,
    }
  }

  getApplicationSharesTooltipText(count?: number): string {
    return count && count > 0 ? `Total shares: ${count}` : 'This candidate has not been shared'
  }

  private calculateStatus({ pendingCount, completedCount }: CountType): IconStatus {
    if (isNotNil(pendingCount) && pendingCount > 0) {
      return 'pending'
    } else if (isNotNil(completedCount) && completedCount > 0) {
      return 'completed'
    } else {
      return 'none'
    }
  }
}

import { CommonModule } from '@angular/common'
import { Component, TemplateRef, ViewChild } from '@angular/core'
import { E11CardModule } from '@engineering11/ui-lib/e11-card'
import { E11ModalGlobalService } from '@engineering11/ui-lib/e11-modal'
import { TranslateModule } from '@ngx-translate/core'
import { WHAT_HAPPENS, WHAT_TO_DO } from './automated-question.constants'
import { AutomationHelperGroupComponent } from '@employer/app/components/jobs-automation/partials/automation-helper-group.component'

@Component({
  selector: 'automated-question-popup-helper-modal',
  standalone: true,
  imports: [TranslateModule, CommonModule, E11CardModule, AutomationHelperGroupComponent],
  template: `
    <ng-container>
      <ng-template #content>
        <div class="e11-flex e11-flex-col e11-gap-6">
          <div class="e11-flex e11-flex-col e11-gap-2">
            <div class="e11-flex e11-flex-row e11-gap-2 e11-text-skin-primary-accent">
              <span class="material-icons">auto_mode</span>
              <h3>
                {{ 'Automatically engage candidates with questions' | translate }}
              </h3>
            </div>
            <p>
              {{
                'Automate the process of asking your candidates important and relevant questions about the job they are applying for. These questions will be sent to the candidate after the conditions you configure are met.'
                  | translate
              }}
            </p>
          </div>
          <div class="e11-mb-4">
            <div>
              <h3 class="e11-mb-8">{{ 'What you do:' | translate }}</h3>
              <automation-helper-group [groupItems]="WHAT_TO_DO" />
            </div>
            <hr class="e11-my-8" />
            <div>
              <h3 class="e11-mb-8">{{ 'What happens?' | translate }}</h3>
              <automation-helper-group [groupItems]="WHAT_HAPPENS" />
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  `,
  styles: ``,
})
export class AutomatedQuestionPopupHelperModalComponent {
  @ViewChild('content') content!: TemplateRef<any>

  WHAT_TO_DO = WHAT_TO_DO
  WHAT_HAPPENS = WHAT_HAPPENS

  constructor(private modalService: E11ModalGlobalService) {}

  open() {
    this.modalService.openModal({
      body: this.content,
      title: 'Automation Configuration - Candidate Questions',
      width: 'xl',
    })
  }
}

<div class="container-view">
  <app-header></app-header>
  <main>
    <div class="e11-container-full container-content block employer-margin-top">
      <loader-view></loader-view>
      <router-outlet></router-outlet>
    </div>
  </main>
  <app-footer></app-footer>
</div>
Ø

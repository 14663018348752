import { Injectable } from '@angular/core'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { BaseFirestoreRepository, FirestoreQueryBuilder, IRead, IWrite, QueryFunction } from '@engineering11/web-api-firebase'
import { Observable } from 'rxjs'
import { IPublishedJobProfile } from '../models/job-profile.model'

const COLLECTION = 'jobs_job_profile'

@Injectable({
  providedIn: 'root',
})
export class PublishedJobProfileRepository
  extends BaseFirestoreRepository<IPublishedJobProfile>
  implements IWrite<IPublishedJobProfile>, IRead<IPublishedJobProfile>
{
  constructor(afs: AngularFirestore) {
    super(afs)
  }
  getAll(...ids: string[]): Observable<IPublishedJobProfile[]> {
    throw new Error('Method not implemented.')
  }
  getAllValueChanges(...ids: string[]): Observable<IPublishedJobProfile[]> {
    throw new Error('Method not implemented.')
  }
  //
  protected buildPath(id = ''): string {
    return `${COLLECTION}/${id}`
  }

  get(id: string): Observable<IPublishedJobProfile | undefined> {
    const path: string = this.buildPath(id)
    return super._get(path)
  }

  getValueChanges(id: string): Observable<IPublishedJobProfile | undefined> {
    const path: string = this.buildPath(id)
    return super._getValueChanges(path)
  }

  getAllByCustomer(customerKey: string): Observable<IPublishedJobProfile[]> {
    const path: string = this.buildPath()
    const queryBuilder = new FirestoreQueryBuilder()
    queryBuilder.where('customerKey', '==', customerKey)
    return super._getAll(path, queryBuilder.build())
  }

  getAllValueChangesByCustomer(customerKey: string): Observable<IPublishedJobProfile[]> {
    const path: string = this.buildPath()
    const queryBuilder = new FirestoreQueryBuilder()
    queryBuilder.where('customerKey', '==', customerKey)
    return super._getAllValueChanges(path, queryBuilder.build())
  }

  queryValueChanges(queryFn: QueryFunction): Observable<IPublishedJobProfile[]> {
    const path = this.buildPath()
    return super._queryValueChanges(path, queryFn)
  }

  async add(model: IPublishedJobProfile): Promise<string> {
    throw new Error('Unsupported Operation.')
  }

  set(): Promise<any> {
    throw new Error('Unsupported Operation.')
  }

  update(model: IPublishedJobProfile): Promise<any> {
    throw new Error('Unsupported Operation.')
  }

  delete(contentId: string, approvalId: string): Promise<any> {
    throw new Error('Unsupported Operation.')
  }
}

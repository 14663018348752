import { Injectable } from '@angular/core'
import { CandidateDetailTabs, EmployerNavigationService } from '@employer/app/services/navigation.service'
import { IConversation, MessagingNavigationService } from '@engineering11/messaging-web'
import { E11ErrorHandlerService, toE11Error } from '@engineering11/web-api-error'
import { ApplicationConversationConfigProvider, ConversationTypes, IApplicationConversationMetadata } from 'shared-lib'
import { EmployerApplicationConversationProvider } from './application-conversation-config.provider'

@Injectable({ providedIn: 'root' })
export class EmployerApplicationConversationDMProvider extends ApplicationConversationConfigProvider {
  conversationType = ConversationTypes.JobApplicationDM
  constructor(
    private navigationService: EmployerNavigationService,
    messagingNavigationService: MessagingNavigationService,
    private errorHandler: E11ErrorHandlerService
  ) {
    super(messagingNavigationService)
  }

  onConversationClick(conversation: IConversation<IApplicationConversationMetadata>): void {
    const { jobPostId, jobPostApplicationId } = conversation.clientMetadata!

    try {
      this.navigationService.toCandidateDetailPage(jobPostId, jobPostApplicationId, CandidateDetailTabs.DirectMessage)
    } catch (err: any) {
      this.errorHandler.handleError(toE11Error(err, { type: 'messaging/route-failure', title: 'Failed to navigate', additionalData: conversation }))
      super.onConversationClick(conversation) // fallback if navigation fails
    }
  }
}

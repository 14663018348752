import {
  autoQuestionFollowUpDefaults,
  autoQuestionInitialMessageDefaults,
  autoQuestionWarningDefaults,
} from '@employer/app/components/jobs-automation/automation-auto-question/auto-questions.model'
import { ISendMessageFormData } from '@employer/app/components/jobs-automation/job-automation.model'

export type IAutomatedQuestionSendMessage = Omit<ISendMessageFormData, 'enabled'>

export interface IAutomatedQuestionMessageFormData {
  initialMessage: IAutomatedQuestionSendMessage
  followUp: IAutomatedQuestionSendMessage
  warning: IAutomatedQuestionSendMessage
}

export const automatedQuestionMessageFormDefaults: IAutomatedQuestionMessageFormData = {
  initialMessage: autoQuestionInitialMessageDefaults,
  followUp: autoQuestionFollowUpDefaults,
  warning: autoQuestionWarningDefaults,
}

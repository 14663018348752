import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { IAuthService } from '@engineering11/auth-web'
import { AppConfigService } from '@engineering11/tenant-bootstrap-web'
import { TokenStorage } from 'shared-lib'
@Component({
  selector: 'app-header-register',
  templateUrl: './header-register.component.html',
  styleUrls: ['./header-register.component.scss'],
})
export class AppHeaderRegisterComponent {
  logoUrl = this.appConfig.config.brand.logoImageUrl
  constructor(private tokenStorage: TokenStorage, private authService: IAuthService, private router: Router, private appConfig: AppConfigService) {}

  signOut(): void {
    this.authService.signOut()
    this.tokenStorage.clear()
    this.router.navigate(['/home'])
  }
}

<div class="row flex" *ngIf="mode == 'resetPassword' && oobCode">
  <div class="col-xs-12">
    <div class="wrapper-panel">
      <e11-panel>
        <div class="row">
          <div class="col-xs-12 text-center">
            <h3 class="bold color-head">{{ 'Reset Password' | translate }}</h3>
            <div class="spacer-2rem"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <auth-password-reset [actionCode]="oobCode" (onSubmit)="onResetSubmit($event)"></auth-password-reset>
          </div>
        </div>
      </e11-panel>
    </div>
  </div>
</div>
<div class="row" *ngIf="mode == 'verifyEmail'">
  <div class="col-xs-12 text-center">
    <auth-verify-email [actionCode]="oobCode" (onSubmit)="onVerifySubmit()"> </auth-verify-email>
  </div>
</div>

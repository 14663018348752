import { Injectable } from '@angular/core'
import { ComponentStore } from '@ngrx/component-store'
import { APPLICATION_STATE, IJobPostApplication } from 'shared-lib'
import { CandidateApplicationStatusUpdateResponse } from '../services/candidate-application-swimlane.service'
import { uniqBy } from 'lodash'
import { transferArrayItem } from '@angular/cdk/drag-drop'
import { Timestamp } from '@engineering11/web-api-firebase'
import { deepCopy } from '@engineering11/utility'

interface UpdateHitsByStatusPayload {
  status: APPLICATION_STATE
  hits: Timestamp<IJobPostApplication>[]
  total: number
  isFirstPage: boolean
}

interface TransferHitsByStatusPayload {
  targetId: string
  currentStatus: APPLICATION_STATE
  targetStatus: APPLICATION_STATE
}

export interface JobApplicationState {
  swimlaneHits: { [key in APPLICATION_STATE]: { hits: Timestamp<IJobPostApplication>[]; total: number } }
  gridHits: Timestamp<IJobPostApplication>[]
}

const defaultState: JobApplicationState = {
  swimlaneHits: {} as any, // using any to avoid having to define all the APPLICATION_STATE keys
  gridHits: [],
}

@Injectable({
  providedIn: 'root',
})
export class CandidateListStore extends ComponentStore<JobApplicationState> {
  constructor() {
    super(defaultState)
  }

  // Selectors
  readonly getState = this.select(s => s)
  readonly swimlaneHits$ = this.select(s => s.swimlaneHits)
  readonly gridHits$ = this.select(s => s.gridHits)

  // Reducers
  readonly onUpdateHitsByStatus = this.updater((state, payload: UpdateHitsByStatusPayload) => {
    const exitingHits = state.swimlaneHits[payload.status]?.hits ?? []
    const newHits = payload.hits ?? []

    const updatedState = { ...state.swimlaneHits }
    updatedState[payload.status] = {
      hits: payload.isFirstPage ? newHits : uniqBy([...exitingHits, ...newHits], 'id'),
      total: payload.total,
    }
    return { ...state, swimlaneHits: updatedState }
  })

  readonly onTransferSwimlaneHit = this.updater((state, payload: TransferHitsByStatusPayload) => {
    const currentStatus = Object.keys(state.swimlaneHits).find(key =>
      state.swimlaneHits[key as APPLICATION_STATE].hits.find(hit => hit.id === payload.targetId)
    ) as APPLICATION_STATE | undefined

    if (!currentStatus) {
      return { ...state }
    }

    const updatedState = deepCopy(state.swimlaneHits)
    const currentHits = updatedState[currentStatus]?.hits ?? []
    const targetHits = updatedState[payload.targetStatus]?.hits ?? []
    const currentIndex = currentHits.findIndex(hit => hit.id === payload.targetId)

    if (currentIndex === -1) {
      return { ...state }
    }

    transferArrayItem(currentHits, targetHits, currentIndex, 0)

    updatedState[currentStatus] = {
      hits: currentHits,
      total: updatedState[currentStatus].total - 1,
    }

    updatedState[payload.targetStatus] = {
      hits: targetHits,
      total: (updatedState[payload.targetStatus]?.total ?? 0) + 1,
    }

    return { ...state, swimlaneHits: updatedState }
  })

  readonly onUpdateGridHits = this.updater((state, hits: Timestamp<IJobPostApplication>[]) => ({
    ...state,
    gridHits: hits,
  }))

  readonly onCandidateApplicationStatusUpdated = this.updater((state, statusUpdate: CandidateApplicationStatusUpdateResponse) => {
    const status = statusUpdate.applicationState
    const previousStatus = statusUpdate.previousApplicationState

    // Update swimlaneHits
    const hitStatus = Object.keys(state.swimlaneHits).find(key =>
      state.swimlaneHits[key as APPLICATION_STATE].hits.find(hit => hit.id === statusUpdate.id)
    )

    const clonedSwimlaneHits = deepCopy(state.swimlaneHits)
    if (hitStatus) {
      const hitIndex = state.swimlaneHits[hitStatus as APPLICATION_STATE].hits.findIndex(hit => hit.id === statusUpdate.id)
      clonedSwimlaneHits[hitStatus as APPLICATION_STATE].hits[hitIndex].applicationState = status
      clonedSwimlaneHits[hitStatus as APPLICATION_STATE].hits[hitIndex].previousApplicationState = previousStatus
    }

    const gridHits = state.gridHits.filter(hit => hit.id !== statusUpdate.id)

    return { ...state, swimlaneHits: clonedSwimlaneHits, gridHits: gridHits }
  })

  readonly onResetState = this.updater(() => defaultState)
}

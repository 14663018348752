<div class="header-container e11-bg-skin-primary e11-w-full">
  <header class="e11-container-full e11-mx-auto">
    <div class="row flex">
      <div class="col-xs-8 nopadding flex-vertical-center">
        <img [routerLink]="['/home']" [src]="logoUrl" class="e11-h-16 e11-max-h-16 e11-object-contain e11-object-left" />
      </div>
      <div class="col-xs-4 flex-align-center-justify-end"></div>
    </div>
  </header>
</div>

import { Component, ViewChild } from '@angular/core'
import { Validators } from '@angular/forms'
import {
  E11DynamicFormBuilderComponent,
  E11FormForType,
  e11FieldInputRadio,
  e11FormFor,
  e11FormLayoutFullWidth,
} from '@engineering11/ui-lib/e11-form-builder'
import { IRadioOptions } from '@engineering11/ui-lib/e11-radio-input'
import { E11Logger } from '@engineering11/web-api-error'
import { map } from 'rxjs'
import { IEmployer } from 'shared-lib'
import { EmployerStore } from '../employer.store'
import { EmployerSettingData } from './manage-employer-settings/employer-setting.component'
import { eeoPolicyDisplay } from './manage-employer-settings/setting-display'
import { INotificationMessage } from '@engineering11/ui-lib/e11-notifications'
import { NotificationHelper } from '@engineering11/web-ui-helpers'

enum FederalContractorResponse {
  Yes = 'Yes',
  No = 'No',
}

// const options: IRadioOptions[] = toSelectOptions([FederalContractorResponse.Yes, FederalContractorResponse.No])
const options: IRadioOptions<boolean>[] = [
  { value: true, name: FederalContractorResponse.Yes },
  { value: false, name: FederalContractorResponse.No },
]

type FederalContractorData = Pick<IEmployer, 'federalContractor'> //ReplaceValues<Pick<IEmployer, 'federalContractor'>, FederalContractorResponse>

@Component({
  template: `
    <e11-panel *ngrxLet="employer$ as employer">
      <div class="e11-w-full e11-flex e11-mb-6 e11-items-start">
        <div class="e11-grow">
          <h3 class="e11-font-primary-bold">{{ 'Compliance' | translate }}</h3>
          <p class="e11-mb-0 e11-text-sm">
            {{ 'This information is used for OFCCP regulations. You may need this if you do business with the Federal Government.' | translate }}
          </p>
        </div>
      </div>

      <ng-container>
        <e11-dynamic-form-builder
          #formFederalContractor
          [loading]="(loading$ | async) ?? true"
          [initialValue]="{ federalContractor: employer?.federalContractor }"
          [fieldGroups]="federalContractorForm"
          [hideSubmitMenu]="true"
        ></e11-dynamic-form-builder>
      </ng-container>

      <employer-setting
        [loading]="(loading$ | async) ?? true"
        [settingData]="{ setting: employer?.legalDisclaimer, switchOn: employer?.showLegalDisclaimer }"
        [settingDisplay]="eeoPolicyDisplay"
        (settingSubmitted)="onSubmitDisclaimer($event)"
      ></employer-setting>
    </e11-panel>
  `,
})
export class CustomerComplianceComponent {
  @ViewChild('formFederalContractor') formFederalContractor!: E11DynamicFormBuilderComponent<FederalContractorData>

  employer$ = this.employerStore.employer$
  loading$ = this.employerStore.employerLoaded$.pipe(map(loaded => !loaded))

  eeoPolicyDisplay = eeoPolicyDisplay

  federalContractorForm = e11FormFor<Required<FederalContractorData>>()([
    e11FormLayoutFullWidth([
      e11FieldInputRadio('federalContractor', 'Is your company a federal contractor or subcontractor?', options, undefined, {
        extraInputOption: { tooltipConfig: { tooltipText: 'Answer yes if you do business with the federal government', tooltipPosition: 'beside' } },
        validatorOrOpts: [Validators.required],
      }),
    ]),
  ])

  constructor(private employerStore: EmployerStore, private logger: E11Logger, private notificationHelper: NotificationHelper) {}

  async onSubmitDisclaimer(data: EmployerSettingData) {
    const contractorData: FederalContractorData = this.formFederalContractor.form.value
    const federalContractor = contractorData.federalContractor
    this.formFederalContractor.onSubmitClick()
    this.logger.log({ data, federalContractor })
    if (!this.formFederalContractor.form.valid) return
    if (federalContractor && (!data.switchOn || !data.setting)) {
      this.notificationHelper.popNotification(eeoRequiredNotif)
      return
    }
    this.employerStore.onUpdateEmployer({ legalDisclaimer: data.setting, showLegalDisclaimer: data.switchOn, federalContractor })
  }
}
const eeoRequiredNotif: INotificationMessage = {
  title: 'EEO Policy Required',
  message: 'An EEO Policy is required for all federal contractors to remain compliant with federal regulations',
  type: 'warning',
  autoClose: false,
}

<!-- Profile dropdown -->
<div
  class="e11-z-50 e11-flex e11-justify-between e11-items-center e11-relative"
  (closeOnClickOutside)="dropDownOpen = false"
  *ngrxLet="currentUser$ as currentUser"
>
  <e11-avatar
    [imageUrl]="currentUser?.photoURL"
    [size]="'md'"
    [initials]="currentUser?.firstName!.charAt(0) + currentUser?.lastName!.charAt(0)"
    (click)="dropDownOpen = !dropDownOpen"
  ></e11-avatar>

  <div
    (click)="dropDownOpen = !dropDownOpen"
    [ngClass]="{
      'e11-transition e11-ease-out e11-duration-200 e11-transform e11-opacity-0 e11-scale-95 e11-invisible': !dropDownOpen,
      'e11-transition e11-ease-in duration-75 e11-e11-transform e11-opacity-100 e11-scale-100': dropDownOpen
    }"
    class="e11-origin-top-right e11-absolute e11-right-0 e11-top-10 e11-mt-2 e11-w-48 e11-rounded-md e11-shadow-lg e11-py-1 e11-bg-white e11-border e11-border-skin-app-borders focus:e11-outline-none focus:e11-ring-opacity-100"
    role="menu"
    aria-orientation="vertical"
    aria-labelledby="user-menu-button"
    tabindex="-1"
  >
    <a
      [routerLink]="[routes.ACCOUNT]"
      class="e11-block e11-px-4 e11-py-2 e11-text-sm e11-text-gray-700 hover:e11-bg-gray-100 e11-border-b e11-border-gray-200"
      role="menuitem"
      tabindex="-1"
      id="user-menu-item-0"
    >
      {{ 'My Account' | translate }}
    </a>

    <ng-container *ngrxLet="userPermissions$ as permissions">
      <!-- Company manage permissions -->
      <ng-container *ngIf="permissions?.has(Permission.COMPANY_MANAGE)">
        <a
          [routerLink]="[routes.COMPANY_ACCOUNT]"
          class="e11-block e11-px-4 e11-py-2 e11-text-sm e11-text-gray-700 hover:e11-bg-gray-100 e11-border-b e11-border-gray-200"
          role="menuitem"
          tabindex="-1"
          id="user-menu-item-1"
        >
          {{ 'Company Settings' | translate }}
        </a>
      </ng-container>

      <!-- Company manage permissions -->
      <ng-container *ngIf="permissions?.has(CustomerPermissions.ViewClient)">
        <a
          [routerLink]="[routes.CLIENT_MANAGEMENT]"
          class="e11-block e11-px-4 e11-py-2 e11-text-sm e11-text-gray-700 hover:e11-bg-gray-100 e11-border-b e11-border-gray-200"
          role="menuitem"
          tabindex="-1"
          id="user-menu-item-1"
        >
          {{ 'Client Management' | translate }}
        </a>
      </ng-container>
    </ng-container>

    <a
      href="#"
      class="e11-block e11-px-4 e11-py-2 e11-text-sm e11-text-gray-700 hover:e11-bg-gray-100 e11-border-b e11-border-gray-200"
      role="menuitem"
      tabindex="-1"
      id="user-menu-item-2"
      (click)="openQRCodeModal()"
    >
      {{ 'Sign in on mobile' | translate }}
    </a>

    <a
      href="#"
      class="e11-block e11-px-4 e11-py-2 e11-text-sm e11-text-gray-700 hover:e11-bg-gray-100"
      role="menuitem"
      tabindex="-1"
      id="user-menu-item-3"
      (click)="signOut()"
    >
      {{ 'Sign out' | translate }}
    </a>
  </div>

  <span
    (click)="dropDownOpen = !dropDownOpen"
    *ngrxLet="customerName$ as customerName"
    class="e11-flex e11-items-center sm:e11-visible e11-pl-4 e11-py-2 e11-rounded-md e11-text-sm e11-text-skin-primary-accent hover:e11-text-skin-primary e11-cursor-pointer"
  >
    <div class="e11-flex-col e11-min-w-[100px]">
      <p [ngClass]="!currentUser ? 'e11-mb-1' : 'e11-mb-0'" class="empty:e11-skeleton empty:e11-w-full">
        {{ currentUser?.firstName?.concat(' ') }}{{ currentUser?.lastName }}
      </p>
      <p class="e11-text-skin-light e11-mb-0 empty:e11-skeleton empty:e11-w-full">{{ customerName }}</p>
    </div>
    <span class="material-icons e11-ml-2">expand_more</span>
  </span>

  <ng-template #qrCodeModalContent>
    <ng-container *ngrxLet="customTokenUrl$ as customTokenUrl">
      <div class="e11-w-full e11-px-4">
        <div class="e11-flex e11-items-center e11-justify-center e11-mb-8 e11-flex-col e11-gap-2">
          <div class="e11-w-full e11-min-h-[200px] e11-flex e11-items-center e11-justify-center">
            <div *ngIf="!customTokenUrl" class="!e11-w-[200px] e11-bg-gray-400/40 !e11-h-[200px] e11-skeleton-empty"></div>
            <e11-qrcode
              *ngIf="customTokenUrl"
              [value]="customTokenUrl"
              [width]="200"
              [mobileWidth]="180"
              [margin]="4"
              [label]="'Login on mobile' | translate"
            ></e11-qrcode>
          </div>
          <div
            *ngIf="customTokenUrl"
            (click)="refreshCustomToken()"
            class="e11-flex e11-items-center e11-justify-center e11-cursor-pointer e11-text-xs e11-text-skin-primary-accent e11-w-full"
          >
            <span class="material-icons-outlined md-18">refresh</span> {{ 'Refresh' | translate }}
          </div>
          <p class="e11-mb-0 e11-text-sm">
            {{ "You're signing in as" | translate }} <strong>{{ currentUser?.email }}</strong>
          </p>
        </div>

        <div>
          <div class="e11-mb-8">
            <p class="e11-font-primary-demibold e11-mb-1">{{ 'Cnect for employers is available in the palm of your hand!' | translate }}</p>
            <p class="e11-mb-2 e11-text-sm">{{ 'To access this workspace on your phone:' | translate }}</p>
            <ul class="e11-list-decimal e11-pl-8">
              <li class="e11-mb-2">
                <div class="lg:e11-mb-2">
                  {{ 'Download and open the' | translate }} <span class="e11-font-primary-medium">Cnected Employer</span>
                  {{ 'app on your mobile device.' | translate }}
                  <span class="e11-inline lg:e11-hidden"
                    >(<a
                      *ngIf="customTokenUrl"
                      class="e11-text-skin-primary-accent"
                      [href]="customTokenUrl + '&redirectToStore=true'"
                      target="_blank"
                    >
                      <span class="material-icons material-icons-outlined md-24">download</span> </a
                    >)</span
                  >
                </div>

                <div class="e11-gap-4 e11-hidden lg:e11-flex md:e11-w-2/3 2xl:e11-w-1/3 e11-mr-4 sm:e11-mr-0">
                  <a href="https://apps.apple.com/us/app/cnected-employer/id6502960629" target="_blank">
                    <img src="assets/images/ios_app_store_badge.svg" class="e11-mb-2 e11-mx-auto" />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.e11.prod.cnect_employer" target="_blank">
                    <img src="assets/images/google_play_badge.svg" class="e11-mb-2 e11-mx-auto" />
                  </a>
                </div>
              </li>
              <li class="e11-mb-2">
                {{ 'Tap' | translate }} <span class="e11-font-primary-medium">{{ 'Add a workspace' | translate }}</span>
                {{ 'when prompted.' | translate }}
              </li>
              <li class="e11-mb-2">
                {{ 'Tap' | translate }} <span class="e11-font-primary-medium">{{ 'Scan QR code' | translate }}</span
                >{{ ' at the bottom of the list.' | translate }}
              </li>
              <li class="e11-mb-2">{{ 'Scan this QR code and complete sign in.' | translate }}</li>
            </ul>
          </div>

          <div class="e11-block lg:e11-hidden">
            <p class="e11-mb-2">{{ 'If you are already on your mobile device, you can click the link below instead:' | translate }}</p>
            <ul class="e11-list-disc e11-pl-8">
              <li class="e11-mb-2">
                <a [href]="customTokenUrl" target="_blank">{{ 'Login On App' | translate }}</a>
              </li>
            </ul>
            <p>{{ 'Do not share this code, it is unique to you and your account login.' | translate }}</p>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-template>
</div>

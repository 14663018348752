import { Injectable } from '@angular/core'
import { CollectionRepository, FirestoreQueryBuilder } from '@engineering11/web-api-firebase'
import { COLLECTIONS } from 'shared-lib'
import { IInterviewQuestion } from '../modules/interview-question-library/interview-question.model'

@Injectable({ providedIn: 'root' })
export class InterviewQuestionRepository extends CollectionRepository<IInterviewQuestion> {
  protected COLLECTION = COLLECTIONS.INTERVIEW_QUESTION

  getAllForCustomer(customerKey: string) {
    const query = new FirestoreQueryBuilder().where('customerKey', '==', customerKey).build()
    return this.queryValueChanges(query)
  }
}

import { Injectable } from '@angular/core'
import { IJobPost, JOB_CLOSED_STATUSES } from '@employer/app/models/job-post.model'
import { JobPostApplicationService } from '@employer/app/modules/jobs/services/job-post-application.service'
import { CandidateListStore } from '@employer/app/modules/jobs/stores/candidate-list.store'
import { JobPostRepository } from '@employer/app/repositories/job-post.repository'
import { selectors } from '@employer/app/store/selectors'
import { IFileReceipt } from '@engineering11/files-web'
import { RequireKeys } from '@engineering11/types'
import { E11NotificationMessage, E11NotificationsService } from '@engineering11/ui-lib/e11-notifications'
import { E11ErrorHandlerService, E11Logger } from '@engineering11/web-api-error'
import { ComponentStore } from '@ngrx/component-store'
import { tapResponse } from '@ngrx/operators'
import { Store } from '@ngrx/store'
import { Observable, combineLatest, finalize, from, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs'
import { APPLICATION_STATE, ARCHIVED_STATES, IJobPostApplication, IRequestedUpdate } from 'shared-lib'
import {
  CandidateApplicationStatusUpdate,
  CandidateApplicationStatusUpdateResponse,
  CandidateApplicationSwimlaneService,
} from '../services/candidate-application-swimlane.service'

interface CandidateApplicationRequestUpdate {
  jobPostId: string
  applicationId: string
  requestedUpdates: IRequestedUpdate[]
}

interface CandidateApplicationRequest {
  jobPostId: string
  applicationId: string
  loadSilently?: boolean
}

interface ApplicationFileUploadRequest {
  jobPostId: string
  applicationId: string
  applicationFiles: IFileReceipt[]
}

interface ApplicationFileDeleteRequest {
  jobPostId: string
  applicationId: string
  applicationFile: IFileReceipt
}

export interface JobApplicationState {
  isProcessingStatusUpdate: boolean
  isProcessingUpdateRequest: boolean
  isAddingApplicationFile: boolean
  jobPostId: string | undefined
  selectedApplicationId: string | undefined
  candidateApplication: IJobPostApplication | null
  jobPost: IJobPost | undefined
  updatingApplicationStatusIds: string[]
  loaded: boolean
}

const defaultState: JobApplicationState = {
  isProcessingStatusUpdate: false,
  isProcessingUpdateRequest: false,
  isAddingApplicationFile: false,
  jobPostId: undefined,
  selectedApplicationId: undefined,
  candidateApplication: null,
  jobPost: undefined,
  updatingApplicationStatusIds: [],
  loaded: false,
}

@Injectable({
  providedIn: 'root',
})
export class JobApplicationStore extends ComponentStore<JobApplicationState> {
  constructor(
    private logger: E11Logger,
    private jobPostApplicationService: JobPostApplicationService,
    private candidateApplicationSwimlaneService: CandidateApplicationSwimlaneService,
    private notificationService: E11NotificationsService,
    private errorHandler: E11ErrorHandlerService,
    private jobPostRepository: JobPostRepository,
    private candidateListStore: CandidateListStore,
    private store: Store
  ) {
    super(defaultState)
  }

  // Selectors
  readonly getState = this.select(s => s)
  readonly isProcessingStatusUpdate$ = this.select(s => s.isProcessingStatusUpdate)
  readonly isProcessingUpdateRequest$ = this.select(s => s.isProcessingUpdateRequest)
  readonly isAddingApplicationFile$ = this.select(s => s.isAddingApplicationFile)

  readonly jobPostId$ = this.select(s => s.jobPostId)
  readonly selectedApplicationId$ = this.select(s => s.selectedApplicationId)
  readonly candidateApplication$ = this.select(s => s.candidateApplication)
  readonly candidateId$ = this.select(s => s.candidateApplication?.candidateId)
  readonly jobPost$ = this.select(s => s.jobPost as RequireKeys<IJobPost, 'id'>)
  readonly loaded$ = this.select(s => s.loaded)
  readonly isArchived$ = this.select(
    this.candidateApplication$,
    this.jobPost$,
    (application, jobPost) =>
      !!application && !!jobPost && (ARCHIVED_STATES.includes(application.applicationState) || JOB_CLOSED_STATUSES.includes(jobPost.status))
  )

  readonly hiringTeamMembers$ = combineLatest([this.jobPost$, this.store.select(selectors.getCustomerUserEntities)]).pipe(
    map(([currentJobItem, users]) => users.filter(user => (currentJobItem?.editorIds ?? []).includes(user.id)))
  )

  readonly isUpdatingApplicationStatus$ = (applicationId: string) => this.select(s => s.updatingApplicationStatusIds.includes(applicationId))

  // Effects

  readonly loadCandidateApplication = this.effect((payload$: Observable<CandidateApplicationRequest>) =>
    payload$.pipe(
      switchMap(payload => {
        this.setLoaded(!!payload.loadSilently)
        this.onUpdateJobPostId(payload.jobPostId)
        this.onUpdateSelectedApplicationId(payload.applicationId)
        this.onGetJobPost(payload.jobPostId)
        return this.jobPostApplicationService.repository.get(payload.jobPostId, payload.applicationId).pipe(
          tapResponse(
            candidateApplication => {
              this.onCandidateApplicationFetched(candidateApplication!)
            },
            (err: Error) => this.errorHandler.handleError(err)
          ),
          finalize(() => this.setLoaded(true))
        )
      })
    )
  )

  readonly onGetJobPost = this.effect((payload$: Observable<string>) =>
    payload$.pipe(
      switchMap(payload => {
        return this.jobPostRepository.get(payload).pipe(
          tapResponse(
            jobPost => this.onUpdateJobPost(jobPost!),
            (err: Error) => this.errorHandler.handleError(err)
          )
        )
      })
    )
  )

  readonly addRequestedUpdate = this.effect((payload$: Observable<CandidateApplicationRequestUpdate>) => {
    return payload$.pipe(
      mergeMap(payload => {
        this.setProcessingUpdateRequest(true)
        return from(this.jobPostApplicationService.addRequestedUpdate(payload.jobPostId, payload.applicationId, payload.requestedUpdates)).pipe(
          tapResponse(
            () => {
              this.loadCandidateApplication({ jobPostId: payload.jobPostId, applicationId: payload.applicationId })
              this.notificationService.popNotificationMessage(requestUpdateSuccessMessage)
            },
            (err: Error) => this.errorHandler.handleError(err)
          ),
          finalize(() => this.setProcessingUpdateRequest(false))
        )
      })
    )
  })

  readonly addApplicationFiles = this.effect((payload$: Observable<ApplicationFileUploadRequest>) => {
    return payload$.pipe(
      tap(() => this.setAddingApplicationFile(true)),
      switchMap(filesRequest => {
        return from(
          this.jobPostApplicationService.addApplicationFiles(filesRequest.jobPostId, filesRequest.applicationId, filesRequest.applicationFiles)
        ).pipe(
          tapResponse(
            () => this.loadCandidateApplication({ jobPostId: filesRequest.jobPostId, applicationId: filesRequest.applicationId, loadSilently: true }),
            (err: Error) => this.errorHandler.handleError(err, { alertUser: true })
          ),
          finalize(() => this.setAddingApplicationFile(false))
        )
      })
    )
  })

  readonly removeApplicationFile = this.effect((payload$: Observable<ApplicationFileDeleteRequest>) => {
    return payload$.pipe(
      switchMap(fileRequest => {
        return from(
          this.jobPostApplicationService.removeApplicationFiles(fileRequest.jobPostId, fileRequest.applicationId, [fileRequest.applicationFile])
        ).pipe(
          tapResponse(
            () => this.loadCandidateApplication({ jobPostId: fileRequest.jobPostId, applicationId: fileRequest.applicationId, loadSilently: true }),
            (err: Error) => this.errorHandler.handleError(err, { alertUser: true })
          )
        )
      })
    )
  })

  readonly updateApplicationStatus = this.effect((payload$: Observable<CandidateApplicationStatusUpdate>) => {
    return payload$.pipe(
      withLatestFrom(this.candidateApplication$),
      mergeMap(([payload, currentApplication]) => {
        this.setProcessingStatusUpdate(true)
        this.candidateListStore.onTransferSwimlaneHit({
          currentStatus: payload.previousStatus ?? payload.status,
          targetStatus: payload.status,
          targetId: payload.applicationId,
        })
        this.onUpdatingCandidateApplicationStatus(payload.applicationId)
        this.logger.log('updateApplicationStatus', { payload, currentApplication })
        const previousStatus = payload.previousStatus ?? currentApplication?.applicationState // For updates, must take current applicationState
        return from(
          this.candidateApplicationSwimlaneService.updateApplicationStatus({
            ...payload,
            previousStatus,
          })
        ).pipe(
          tapResponse(
            res => {
              this.logger.log('updateApplicationStatus Response', res)
              this.candidateListStore.onCandidateApplicationStatusUpdated(res)
              return this.onUpdateCandidateApplicationStatus(res)
            },
            (error: Error) => {
              this.setProcessingStatusUpdate(false)
              this.candidateListStore.onTransferSwimlaneHit({
                currentStatus: payload.status,
                targetStatus: payload.previousStatus ?? payload.status,
                targetId: payload.applicationId,
              })
              this.onUpdateCandidateApplicationStatus({
                id: payload.applicationId,
                jobPostId: payload.jobPostId,
                applicationState: payload.previousStatus ?? payload.status,
              })
              return this.errorHandler.handleError(error, { alertUser: true })
            }
          )
        )
      })
    )
  })

  readonly reactivateApplication = this.effect((request$: Observable<Omit<CandidateApplicationStatusUpdate, 'status'>>) => {
    return request$.pipe(
      withLatestFrom(this.candidateApplication$),
      mergeMap(([request, currentApplication]) => {
        this.setProcessingStatusUpdate(true)
        this.candidateListStore.onTransferSwimlaneHit({
          currentStatus: APPLICATION_STATE.NOT_INTERESTED,
          targetStatus: request.previousStatus as APPLICATION_STATE,
          targetId: request.applicationId,
        })
        this.onUpdatingCandidateApplicationStatus(request.applicationId)

        const previousStatus = request.previousStatus ?? currentApplication?.previousApplicationState // For reactivation, must use previousApplicationState
        return from(
          this.candidateApplicationSwimlaneService.reactivateApplication({
            ...request,
            previousStatus,
          })
        ).pipe(
          tapResponse(
            res => {
              this.candidateListStore.onCandidateApplicationStatusUpdated(res)
              return this.onUpdateCandidateApplicationStatus(res)
            },
            (error: Error) => this.errorHandler.handleError(error, { alertUser: true })
          ),
          finalize(() => this.setProcessingStatusUpdate(false))
        )
      })
    )
  })

  // Reducers
  readonly setProcessingStatusUpdate = this.updater((state, processing: boolean) => ({
    ...state,
    isProcessingStatusUpdate: processing,
  }))

  readonly setProcessingUpdateRequest = this.updater((state, processing: boolean) => ({
    ...state,
    isProcessingUpdateRequest: processing,
  }))

  readonly setAddingApplicationFile = this.updater((state, adding: boolean) => ({
    ...state,
    isAddingApplicationFile: adding,
  }))

  private readonly setLoaded = this.updater((state, loaded: boolean) => ({ ...state, loaded }))

  readonly onUpdateJobPostId = this.updater((state, jobPostId: string | undefined) => ({ ...state, jobPostId }))
  readonly onUpdateJobPost = this.updater((state, jobPost: IJobPost) => ({ ...state, jobPost }))

  readonly onCandidateApplicationFetched = this.updater((state, candidateApplication: IJobPostApplication) => ({
    ...state,
    candidateApplication,
  }))

  readonly onCandidateApplicationUpdated = this.updater((state, update: Partial<IJobPostApplication>) => ({
    ...state,
    candidateApplication: state.candidateApplication ? { ...state.candidateApplication, ...update } : null,
  }))

  readonly onUpdateCandidateApplicationStatus = this.updater((state, statusUpdate: CandidateApplicationStatusUpdateResponse) => {
    let candidateApplication = state.candidateApplication

    if (candidateApplication?.id === statusUpdate.id) {
      candidateApplication = { ...candidateApplication, ...statusUpdate }
    }

    return {
      ...state,
      candidateApplication,
      isProcessingStatusUpdate: false,
      updatingApplicationStatusIds: state.updatingApplicationStatusIds.filter(id => id !== statusUpdate.id),
    }
  })

  readonly onUpdatingCandidateApplicationStatus = this.updater((state, applicationId: string) => ({
    ...state,
    updatingApplicationStatusIds: [...state.updatingApplicationStatusIds, applicationId],
  }))
  readonly onUpdateSelectedApplicationId = this.updater((state, selectedApplicationId: string | undefined) => ({
    ...state,
    selectedApplicationId,
  }))

  readonly clearSelectedCandidateApplication = this.updater(state => ({ ...state, candidateApplication: null }))
}

const requestUpdateSuccessMessage: E11NotificationMessage = {
  title: 'Success',
  message: `Successfully requested information updates from candidate.`,
  type: 'success',
  autoClose: true,
  dismissOnRouteChange: true,
}

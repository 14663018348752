import { Component } from '@angular/core'
import { MessagingModule, openConversationFilter } from '@engineering11/messaging-web'
import { ConversationTypes } from 'shared-lib'

@Component({
  selector: 'nav-item-candidate-pool-messages',
  standalone: true,
  imports: [MessagingModule],
  template: `
    <sdk-conversation-nav
      [conversationFilter]="openConversationFilter"
      [darkMode]="true"
      [emptyStateSubtitle]="'There are no candidate pool conversations yet.'"
      [types]="[ConversationTypes.CandidatePoolDM]"
    ></sdk-conversation-nav>
  `,
  styles: ``,
})
export class CandidatePoolMessagesNavComponent {
  ConversationTypes = ConversationTypes
  openConversationFilter = openConversationFilter
}

<e11-panel>
  <div class="e11-w-full e11-max-w-5xl">
    <div class="e11-flex e11-w-full e11-items-center e11-mb-2">
      <div>
        <h4 class="e11-font-primary-bold">{{ 'Your Platforms' | translate }}</h4>
      </div>
      <div class="e11-flex e11-grow e11-justify-end">
        <e11-button
          id="submit_preview"
          value="{{ 'Careers Page' | translate }}"
          color="primary-accent"
          [ghost]="true"
          [size]="'sm'"
          [icon]="'open_in_new'"
          [iconPosition]="'left'"
          [iconSize]="'md-14'"
          (click)="preview()"
        >
        </e11-button>
      </div>
    </div>

    <p class="e11-mb-8">
      {{
        'Provide a URL to a social media platform and the icon shown below will appear on your careers page linking to the provided URL. If you do not want it displayed leave it blank or remove any existing URLs. Easy, right?'
          | translate
      }}
    </p>

    <form *ngIf="customer && formSocial" class="e11-mb-8" [formGroup]="formSocial" (ngSubmit)="submitFormSocial()" novalidate>
      <div class="e11-grid e11-grid-cols-1 e11-gap-0 md:e11-grid-cols-2 md:e11-gap-12">
        <div class="">
          <!--FACEBOOK-->
          <div class="e11-flex">
            <div class="e11-h-11 e11-w-11">
              <img src="assets/images/social/facebook.png" class="e11-relative e11-top-5 e11-h-[42px]" />
            </div>
            <div class="e11-grow e11-pl-4">
              <e11-input
                [type]="'url'"
                name="facebookUrl"
                id="id_facebookUrl"
                label="{{ 'Facebook URL/Link' | translate }}"
                [parentForm]="formSocial"
                formControlName="facebookUrl"
                placeholder="https://facebook..."
              >
                <e11-input-errors
                  [parentForm]="formSocial"
                  [formControl]="f.facebookUrl"
                  label="{{ 'Facebook URL/Link' | translate }}"
                ></e11-input-errors>
              </e11-input>
            </div>
          </div>

          <!--LINKEDIN-->
          <div class="e11-flex">
            <div class="e11-pt-5 e11-h-11 e11-w-11">
              <img src="assets/images/social/linkedin.png" class="e11-h-[42px]" />
            </div>
            <div class="e11-grow e11-pl-4">
              <e11-input
                [type]="'url'"
                name="linkedinUrl"
                id="id_linkedinUrl"
                label="{{ 'LinkedIn URL/Link' | translate }}"
                labelColor="primary"
                [parentForm]="formSocial"
                formControlName="linkedinUrl"
                autocomplete="off"
                placeholder="https://linkedin..."
              >
                <e11-input-errors
                  [parentForm]="formSocial"
                  [formControl]="f.linkedinUrl"
                  label="{{ 'LinkedIn URL/Link' | translate }}"
                ></e11-input-errors>
              </e11-input>
            </div>
          </div>
          <!--TIKTOK-->
          <div class="e11-flex">
            <div class="e11-pt-5 e11-h-11 e11-w-11">
              <img src="assets/images/social/tiktok.png" class="e11-h-[42px]" />
            </div>
            <div class="e11-grow e11-pl-4">
              <e11-input
                [type]="'url'"
                name="tiktokUrl"
                id="id_tiktokUrl"
                label="{{ 'TikTok URL/Link' | translate }}"
                labelColor="primary"
                [parentForm]="formSocial"
                formControlName="tiktokUrl"
                autocomplete="off"
                placeholder="https://tiktok..."
              >
                <e11-input-errors
                  [parentForm]="formSocial"
                  [formControl]="f.tiktokUrl"
                  label="{{ 'TikTok URL/Link' | translate }}"
                ></e11-input-errors>
              </e11-input>
            </div>
          </div>
        </div>

        <div class="">
          <!--INSTAGRAM-->
          <div class="e11-flex">
            <div class="e11-pt-5 e11-h-11 e11-w-11">
              <img src="assets/images/social/instagram.png" class="e11-h-[42px]" />
            </div>
            <div class="e11-grow e11-pl-4">
              <e11-input
                [type]="'url'"
                name="instagramUrl"
                id="id_instagramUrl"
                label="{{ 'Instagram URL/Link' | translate }}"
                labelColor="primary"
                [parentForm]="formSocial"
                formControlName="instagramUrl"
                autocomplete="off"
                placeholder="https://instagram..."
              >
                <e11-input-errors
                  [parentForm]="formSocial"
                  [formControl]="f.instagramUrl"
                  label="{{ 'Instagram URL/Link' | translate }}"
                ></e11-input-errors>
              </e11-input>
            </div>
          </div>
          <!--SNAPCHAT-->
          <div class="e11-flex">
            <div class="e11-pt-5 e11-h-11 e11-w-11" [ngClass]="[]">
              <img src="assets/images/social/snapchat.png" class="e11-h-[42px]" />
            </div>
            <div class="e11-grow e11-pl-4">
              <e11-input
                [type]="'url'"
                name="snapchatUrl"
                id="id_snapchatUrl"
                label="{{ 'SnapChat URL/Link' | translate }}"
                labelColor="primary"
                [parentForm]="formSocial"
                formControlName="snapchatUrl"
                autocomplete="off"
                placeholder="https://snapchat..."
              >
                <e11-input-errors
                  [parentForm]="formSocial"
                  [formControl]="f.snapchatUrl"
                  label="{{ 'SnapChat URL/Link' | translate }}"
                ></e11-input-errors>
              </e11-input>
            </div>
          </div>
          <!--X-->
          <div class="e11-flex e11-mb-4">
            <div class="e11-pt-5 e11-h-11 e11-w-11">
              <img src="assets/images/social/x.png" class="e11-h-[42px]" />
            </div>
            <div class="e11-grow e11-pl-4">
              <e11-input
                [type]="'url'"
                name="xUrl"
                id="id_xUrl"
                label="{{ 'X URL/Link' | translate }}"
                labelColor="primary"
                [parentForm]="formSocial"
                formControlName="xUrl"
                autocomplete="off"
                placeholder="https://x..."
              >
                <e11-input-errors [parentForm]="formSocial" [formControl]="f.xUrl" label="{{ 'X URL/Link' | translate }}"></e11-input-errors>
              </e11-input>
            </div>
          </div>
        </div>
      </div>
      <div class="e11-w-full e11-flex e11-justify-end">
        <e11-button id="submit_account" value="{{ 'Save' | translate }}" [color]="'primary-accent'" [type]="'submit'"> </e11-button>
      </div>
    </form>
  </div>
</e11-panel>

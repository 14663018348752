<ng-container *ngrxLet="listLoaded$ as listLoaded">
  <ng-container *ngrxLet="questionLoaded$ as questionLoaded">
    <div class="e11-w-full e11-flex e11-mb-4">
      <div class="e11-grow">
        <h2 class="e11-text-skin-primary-accent">{{ 'Question Library' | translate }}</h2>
        <p class="e11-mb-0">{{ 'Manage the questions you can send to candidates' | translate }}</p>
      </div>
      <div class="e11-grow e11-flex e11-items-center e11-justify-end">
        <e11-button [disabled]="formVisible" [color]="'primary-accent'" (click)="addQuestion()" value="{{ 'Add Question' | translate }}">
        </e11-button>
      </div>
    </div>

    <div class="e11-w-full">
      <e11-confirmation
        #confirmation
        confirmTitle="{{ 'Delete this question' | translate }}"
        confirmText="{{ 'confirm' | translate }}"
        [closeOnEscape]="false"
        cancelText="{{ 'cancel' | translate }}"
        [backdropStyle]="'dark'"
        (answer)="confirmationAnswer($event)"
      >
        {{ 'Are you sure you want to delete this question? This will not delete the question from wherever it is in use.' | translate }}
      </e11-confirmation>
      <e11-panel>
        <div [ngClass]="{ 'e11-z-1 e11-opacity-100': formVisible, 'e11-z-[-1] e11-opacity-0 e11-m-0 e11-relative e11-h-0': !formVisible }">
          <ui-interview-question-form
            [selectedQuestion]="selectedQuestion || undefined"
            (closeForm)="toggleCollapse()"
            (questionSubmitted)="questionSubmitted($event)"
            (questionDeleted)="questionDeleted($event)"
          >
          </ui-interview-question-form>
        </div>
        <div class="e11-flex e11-border-b e11-border-skin-app-borders">
          <div class="e11-grow e11-w-2/3">
            <h3 class="e11-py-2">{{ 'Questions' | translate }}</h3>
          </div>
          <div class="e11-grow e11-items-center e11-justify-end">
            <form class="e11-mb-4" [formGroup]="searchForm" novalidate>
              <e11-input
                [isRounded]="true"
                [parentForm]="searchForm"
                formControlName="input"
                [trailingIcon]="'search'"
                placeholder="{{ 'Search' | translate }}"
                (input)="testSearchInput($event)"
              ></e11-input>
            </form>
            <p class="e11-text-sm e11-text-right">{{ (questions$ | async)?.length + ' results' | translate }}</p>
          </div>
        </div>
        <div *ngIf="!listLoaded">
          <div *ngFor="let q of 8 | range">
            <ui-interview-question-skeleton></ui-interview-question-skeleton>
          </div>
        </div>
        <div *ngIf="listLoaded && (questions$ | async)?.length == 0" class="e11-pt-4 e11-pb-4">
          <e11-empty-state
            [icon]="'library_books'"
            [iconSize]="'md-36'"
            [clickable]="!formVisible"
            title="{{ 'No Interview Questions' | translate }}"
            subtitle="{{ 'It appears that there are no interview questions entered yet.' | translate }}"
            (click)="addQuestion()"
          >
          </e11-empty-state>
        </div>

        <div *ngIf="!questionLoaded">
          <ui-interview-question-skeleton></ui-interview-question-skeleton>
        </div>
        <div *ngFor="let question of questions$ | async">
          <ui-interview-question [question]="question" (editClicked)="editQuestion($event)"></ui-interview-question>
        </div>
      </e11-panel>
    </div>
  </ng-container>
</ng-container>

import { Component, EventEmitter, forwardRef, Inject, Input, OnInit, Optional, Output } from '@angular/core'
import { NgAisIndex, NgAisInstantSearch, TypedBaseWidget } from '@engineering11/angular-instantsearch'

import connectSortBy, { SortByConnectorParams, SortByWidgetDescription } from 'instantsearch.js/es/connectors/sort-by/connectSortBy'
import { SortByItem } from '@engineering11/angular-instantsearch/sort-by/sort-by'
import { AlgSortByItem } from '@employer/app/modules/jobs/shared/application-search'

@Component({
  selector: 'alg-sort-by',
  templateUrl: './sort-by.component.html',
  styleUrls: ['./sort-by.component.scss'],
})
export class SortByComponent extends TypedBaseWidget<SortByWidgetDescription, SortByConnectorParams> implements OnInit {
  public state!: SortByWidgetDescription['renderState']

  @Input() controls: 'buttons' | 'dropdown' | 'external' = 'buttons'
  @Input() public items: AlgSortByItem[] = []
  @Input() public transformItems: (<U extends SortByItem>(items: SortByItem[]) => U[]) | undefined
  @Output() refined = new EventEmitter<string>()

  isToggled = false
  showOptions: boolean = false
  currentSortLabel: string = ''
  currentSortItem!: AlgSortByItem

  constructor(
    @Inject(forwardRef(() => NgAisIndex))
    @Optional()
    public parentIndex: NgAisIndex,
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchInstance: NgAisInstantSearch
  ) {
    super('SortBy')
  }

  ngOnInit() {
    this.createWidget(connectSortBy, {
      items: this.items,
      transformItems: this.transformItems,
    })

    super.ngOnInit()
    this.currentSortLabel = this.items[0].label
    this.currentSortItem = this.items[0]
  }

  emitRefinement(item: string) {
    this.refined.emit(item)
  }

  toggleRefinement(item: AlgSortByItem) {
    const newIndex = this.click(item)
    this.emitRefinement(newIndex)
  }

  click(item: AlgSortByItem): string {
    this.currentSortLabel = item.label
    this.currentSortItem = item
    const value: string = item.value

    if (!item.toggleState) {
      this.state.refine(value)
      return value
    }

    if (this.state.currentRefinement != value) {
      this.isToggled = false

      this.state.refine(value)
      return value
    }

    if (item.toggleValue) {
      const refinementValue = item.toggleValue

      item.toggleValue = value

      this.state.refine(refinementValue)

      this.isToggled = true

      return refinementValue
    }

    const valueArr = value.split('_')

    const currentSortOrder = valueArr.pop() as string

    const newSortOrder = currentSortOrder == 'asc' ? 'desc' : 'asc'

    valueArr.push(newSortOrder)

    const reversedValue = valueArr.join('_')

    this.state.refine(reversedValue)

    this.isToggled = true

    return reversedValue
  }

  sortByName(name: string) {
    const item = this.state.options.find(item => (item as any).name === name)

    if (!item) {
      return
    }

    this.toggleRefinement(item as any)
  }
}

import { Pipe, PipeTransform } from '@angular/core'
import { APPLICATION_STATE, ARCHIVED_STATES } from 'shared-lib'
import { JOB_CLOSED_STATUSES, JobPostStatus } from '@employer/app/models/job-post.model'

@Pipe({
  name: 'isApplicationArchived',
})
export class IsApplicationArchivedPipe implements PipeTransform {
  transform(applicationStatus?: APPLICATION_STATE, jobPostStatus?: JobPostStatus): boolean {
    if (applicationStatus) {
      return ARCHIVED_STATES.includes(applicationStatus)
    }

    if (jobPostStatus) {
      return JOB_CLOSED_STATUSES.includes(jobPostStatus)
    }

    return false
  }
}

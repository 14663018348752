import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { IInterviewQuestion } from '@employer/app/modules/interview-question-library/interview-question.model'
import { E11EmptyStateModule } from '@engineering11/ui-lib/e11-empty-state'
import { TranslateModule } from '@ngx-translate/core'
import { QuestionType } from 'shared-lib'

@Component({
  selector: 'automated-question-selected-interview',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, E11EmptyStateModule, TranslateModule],
  template: `
    <ng-container>
      @for (question of questions; track question.id) {
      <div class="e11-border-b e11-border-skin-app-borders e11-mb-4 e11-py-4 e11-flex e11-flex-col e11-w-full md:e11-w-2/3">
        <div class="e11-w-full">
          <p class="e11-mb-0">{{ question.question | translate }}</p>
        </div>

        <div class="e11-flex e11-w-full">
          <div class="e11-flex e11-items-center e11-grow e11-text-skin-black">
            <span class="material-icons-outlined e11-mb-0 e11-mr-1">
              {{ question.inputType === QuestionType.Video ? 'videocam' : 'article' }}
            </span>
            <p class="e11-mb-0 e11-text-sm">{{ (question.authorName || 'Unknown') + ' - ' + (question.date | date : 'MMM d y') }}</p>
          </div>
          <div class="e11-flex e11-items-center e11-justify-end">
            <span
              class="material-icons-outlined e11-text-skin-primary-accent hover:e11-text-skin-primary e11-cursor-pointer"
              (click)="handleEditQuestion(question)"
            >
              edit
            </span>
            <span
              class="material-icons-outlined e11-text-skin-secondary hover:e11-text-skin-error e11-cursor-pointer"
              (click)="handleDeleteQuestion(question)"
            >
              delete
            </span>
          </div>
        </div>
      </div>
      } @empty {
      <div class="e11-w-full e11-p-4">
        <e11-empty-state
          [icon]="'search'"
          [title]="'No questions found' | translate"
          [subtitle]="'Try searching for a question or write a new one.' | translate"
          [containerClass]="isValidationErrors ? 'e11-text-skin-error' : ''"
        ></e11-empty-state>
      </div>
      }
    </ng-container>
  `,
})
export class AutomatedQuestionSelectedInterviewComponent {
  @Input() isValidationErrors = false
  @Input() questions: IInterviewQuestion[] = []
  @Output() onEditQuestion = new EventEmitter<string>()
  @Output() onDeleteQuestion = new EventEmitter<string>()

  trackBy(_index: number, item: IInterviewQuestion) {
    return item.id
  }

  public QuestionType = QuestionType

  handleEditQuestion(question: IInterviewQuestion) {
    this.onEditQuestion.emit(question.id)
  }

  handleDeleteQuestion(question: IInterviewQuestion) {
    this.onDeleteQuestion.emit(question.id)
  }
}

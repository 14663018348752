import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { ReactiveFormsModule, Validators } from '@angular/forms'
import { automationFormDefaults } from '@employer/app/components/jobs-automation/automation-form.model'
import { delayUnitSelectOptions } from '@employer/app/components/jobs-automation/job-automation.model'
import { AutomationDelayInfoComponent } from '@employer/app/components/jobs-automation/partials/automation-delay-info.component'
import {
  E11DynamicFormBuilderComponent,
  E11DynamicFormModule,
  e11FieldInputNumber,
  e11FieldInputSelect,
  e11FieldInputSwitch,
  e11FormFor,
  E11FormForType,
  e11FormLayoutFullWidth,
  e11FormLayoutInlineGrid,
  e11LayoutGroup,
} from '@engineering11/ui-lib/e11-form-builder'
import { E11InputLabelModule } from '@engineering11/ui-lib/e11-input-label'
import { E11SwitchModule } from '@engineering11/ui-lib/e11-switch'
import { TranslateModule } from '@ngx-translate/core'
import { AutomationPopupHelperModalComponent } from '../partials/automation-popup-helper-modal.component'
import { IUnresponsiveCandidateFormData, unresponsiveCandidateDefaults } from './unresponsive-candidate.model'

export interface IUnresponsiveCandidateForm {
  unresponsiveCandidate: E11FormForType<IUnresponsiveCandidateFormData>
}
@Component({
  selector: 'job-automation-candidate-unresponsive-form',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    E11DynamicFormModule,
    E11InputLabelModule,
    E11SwitchModule,
    AutomationDelayInfoComponent,
    AutomationPopupHelperModalComponent,
  ],
  template: `
    <div class="e11-flex e11-gap-2 e11-items-center e11-mb-2">
      <h3 class="e11-text-skin-primary-accent ">{{ 'Candidate Unresponsive' | translate }}</h3>

      <e11-switch
        [checked]="unresponsiveCandidateForm.form.get('enabled')?.value ?? false"
        (changes)="unresponsiveCandidateForm.form?.get('enabled')?.setValue($event)"
        [size]="'xs'"
        [color]="'success'"
      ></e11-switch>
    </div>
    <div class="md:e11-flex e11-gap-2">
      <div class="e11-flex-1">
        <p>
          {{
            'This will automatically change a candidates status to “Unresponsive” if they do not respond to requests or messages. A notification will inform them that they are no longer considered an active candidate for the job they applied for.'
              | translate
          }}
          <automation-popup-helper-modal />
        </p>
      </div>
      <div class="e11-flex-1">
        <e11-dynamic-form-builder
          #unresponsiveCandidateForm
          [loading]="loading"
          [fieldGroups]="form.unresponsiveCandidate"
          [initialValue]="formData"
          [hideSubmitMenu]="true"
          (valueChanges)="onValueChanges($event)"
        >
          <ng-template e11Template="delayTimeHeaderTemplate">
            <automation-delay-info
              label="When are they unresponsive?"
              toolTipText="This is delayed from the time a request for more information or interview questions were sent to a candidate."
            >
            </automation-delay-info>
          </ng-template>
        </e11-dynamic-form-builder>
      </div>
    </div>
  `,
})
export class AutomationCandidateUnresponsiveFormComponent implements OnInit {
  @ViewChild('unresponsiveCandidateForm') unresponsiveCandidateForm!: E11DynamicFormBuilderComponent<IUnresponsiveCandidateFormData>
  @Input() loading: boolean = false
  @Input() formData?: IUnresponsiveCandidateFormData = unresponsiveCandidateDefaults
  @Output() valueChanges = new EventEmitter<IUnresponsiveCandidateFormData>()
  form!: IUnresponsiveCandidateForm
  ngOnInit(): void {
    this.form = this.buildForm()
  }
  onValueChanges(value: IUnresponsiveCandidateFormData) {
    this.valueChanges.emit(value)
  }
  private buildForm() {
    return {
      unresponsiveCandidate: this.buildUnresponsiveCandidateForm(),
    }
  }

  private buildUnresponsiveCandidateForm(): E11FormForType<IUnresponsiveCandidateFormData> {
    const { enabled, delayDuration, delayUnits } = automationFormDefaults.unresponsiveCandidate ?? unresponsiveCandidateDefaults

    return e11FormFor<IUnresponsiveCandidateFormData>()([
      e11FormLayoutFullWidth(
        [
          e11FieldInputSwitch('enabled', 'Enabled', enabled, {
            validatorOrOpts: [],
          }),
        ],
        { containerClass: 'e11-hidden' }
      ),
      e11LayoutGroup([
        e11LayoutGroup([
          e11FormLayoutInlineGrid(
            [
              e11FieldInputNumber('delayDuration', 'Time frame:', delayDuration, {
                validatorOrOpts: [Validators.required, Validators.min(0)],
                hideInputError: true,
              }),
              e11FieldInputSelect('delayUnits', 'Unit', delayUnitSelectOptions, delayUnits, {
                validatorOrOpts: [Validators.required],
                hideInputError: true,
                isDisabled: true,
              }),
            ],
            {
              fieldsClass: 'e11-w-1/2',
              containerClass: 'xl:e11-w-1/2 e11-mb-4',
              templateSpec: [
                {
                  templateKey: 'delayTimeHeaderTemplate',
                  templatePosition: 'top',
                },
              ],
            }
          ),
        ]),
      ]),
    ])
  }
}

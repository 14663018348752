import { Injectable, inject } from '@angular/core'
import { environment } from '@employer/environments/environment'
import { E11ErrorHandlerService } from '@engineering11/web-api-error'
import { RestApiClient } from '@engineering11/web-api-rest'
import { Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { getCurrentToken, TokenStorage } from 'shared-lib'
import { IAlgoliaKeyPackage } from '@engineering11/types'
import { CandidatePoolRepository } from './candidate-pool.repository'
import { ICandidatePool } from '@employer/app/models/candidate-pool.model'
import { Store } from '@ngrx/store'
import { isNotNil } from '@engineering11/utility'

@Injectable({ providedIn: 'root' })
export class CandidatePoolService {
  private errorHandler = inject(E11ErrorHandlerService)

  private restApiClient: RestApiClient = new RestApiClient({
    baseUrl: environment.services.candidatePool,
    token: this.store.select(getCurrentToken).pipe(filter(isNotNil)),
  })

  constructor(private store: Store, private repository: CandidatePoolRepository) {}

  getByCandidateId(id: string): Observable<ICandidatePool | undefined> {
    return this.repository.get(id)
  }

  getPoolSearchKey(): Observable<IAlgoliaKeyPackage> {
    return this.restApiClient.get<IAlgoliaKeyPackage>(`search-key/customer`).pipe(map(item => item.data))
  }
}

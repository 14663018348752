import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { IAutomationFormConfig, MultiMediaArgs, sendMessageForm } from '@employer/app/components/jobs-automation/automation-form-message'
import { validateFile } from '@employer/app/components/jobs-automation/automation.utils'
import { IMarkdownReceiptFile, ISendMessageFormData } from '@employer/app/components/jobs-automation/job-automation.model'
import { AutomationAttachmentPreviewComponent } from '@employer/app/components/jobs-automation/partials/automation-attachment-preview'
import { AutomationDelayInfoComponent } from '@employer/app/components/jobs-automation/partials/automation-delay-info.component'
import { AutomationLoaderComponent } from '@employer/app/components/jobs-automation/partials/automation-loader'
import { JobPostAutomationService } from '@employer/app/modules/jobs/views/job-automation/job-post-automation.service'
import { E11DynamicFormBuilderComponent, E11DynamicFormModule, E11FormForType } from '@engineering11/ui-lib/e11-form-builder'
import { E11InputLabelModule } from '@engineering11/ui-lib/e11-input-label'
import { IMarkdownFile } from '@engineering11/ui-lib/e11-markdown-editor'
import { E11NotificationsService } from '@engineering11/ui-lib/e11-notifications'
import { E11ShowHideControllerComponent, E11ShowHideWrapperComponent } from '@engineering11/ui-lib/e11-show-hide'
import { E11SwitchModule } from '@engineering11/ui-lib/e11-switch'
import { isNotNil } from '@engineering11/utility'
import { E11ErrorHandlerService, toE11Error } from '@engineering11/web-api-error'
import { TranslateModule } from '@ngx-translate/core'
import { AutomationResetComponent } from '../../../../components/jobs-automation/partials/automation-reset.component'
import { AutomationUpgradePromptComponent } from '../../../../components/jobs-automation/partials/automation-upgrade-prompt.component'
import { IAutomatedQuestionMessageFormData, automatedQuestionMessageFormDefaults } from './auto-question-message.model'
import { ICompanyAutomationFormData } from './job-post-automation-company.repository'
export interface IAutoQuestionForms {
  autoQuestionInitial: E11FormForType<ISendMessageFormData>
  autoQuestionFollowUp: E11FormForType<ISendMessageFormData>
  autoQuestionFinal: E11FormForType<ISendMessageFormData>
}

@Component({
  selector: 'automation-auto-question-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    E11DynamicFormModule,
    E11InputLabelModule,
    E11SwitchModule,
    E11ShowHideControllerComponent,
    E11ShowHideWrapperComponent,
    AutomationDelayInfoComponent,
    AutomationResetComponent,
    AutomationUpgradePromptComponent,
    AutomationAttachmentPreviewComponent,
    AutomationLoaderComponent,
  ],
  template: `
    <automation-loader [isLoading]="isUploadingAttachment"></automation-loader>
    <ng-container>
      <div class="e11-flex e11-flex-col e11-gap-2 e11-mb-2">
        <div class="e11-flex e11-gap-2 e11-items-center">
          <h3 class="e11-text-skin-primary-accent">{{ 'Automated Questions' | translate }}</h3>
          <e11-show-hide-controller
            [containerClass]="'e11-text-skin-primary-accent'"
            [showText]="'Show Settings'"
            [hideText]="'Hide Settings'"
            [isCollapsed]="isAutomatedQuestionCollapsed"
            (toggle)="isAutomatedQuestionCollapsed = !isAutomatedQuestionCollapsed"
          >
          </e11-show-hide-controller>
        </div>
      </div>
      <e11-show-hide-wrapper [isCollapsed]="isAutomatedQuestionCollapsed">
        <ng-container>
          <div class="e11-flex e11-gap-2 e11-items-center">
            <h4 class="e11-mb-2 e11-font-bold">{{ 'Initial Message' | translate }}</h4>
          </div>
          <e11-dynamic-form-builder
            #autoQuestionInitialMessageForm
            [loading]="loading"
            [fieldGroups]="form!.autoQuestionInitial"
            [initialValue]="_formData?.initialMessage"
            [hideSubmitMenu]="true"
            (valueChanges)="onValueChanges('initialMessage', $event)"
          >
            <ng-template e11Template="messageFootNoteTemplate">
              <div class="e11-my-4 ">
                <span>{{ 'Sent to the candidates when they have not answered the questions in a chosen time frame.' | translate }}</span>
              </div>
            </ng-template>
            <ng-template e11Template="delayTimeHeaderTemplate">
              <automation-delay-info></automation-delay-info>
            </ng-template>
            <ng-template e11Template="upgradeProductTemplate">
              <automation-upgrade-prompt [automationType]="'Automated Questions'"></automation-upgrade-prompt>
            </ng-template>
            <ng-template e11Template="videoUrlPreviewTemplate">
              <automation-attachment-preview
                [fileReceipts]="_formData?.initialMessage?.attachments"
                (removeFileReceipt)="removeAttachment($event, 'initialMessage')"
              ></automation-attachment-preview>
            </ng-template>
          </e11-dynamic-form-builder>
        </ng-container>
        <ng-container>
          <div class="e11-flex e11-gap-2 e11-items-center">
            <h4 class="e11-mb-2 e11-font-bold">{{ 'Follow up Message' | translate }}</h4>
          </div>
          <e11-dynamic-form-builder
            #autoQuestionFollowUpMessageForm
            [loading]="loading"
            [fieldGroups]="form!.autoQuestionFollowUp"
            [initialValue]="_formData?.followUp"
            [hideSubmitMenu]="true"
            (valueChanges)="onValueChanges('followUp', $event)"
          >
            <ng-template e11Template="messageFootNoteTemplate">
              <div class="e11-my-4 ">
                <span>{{ 'Sent to the candidates when they have not answered the questions in a chosen time frame.' | translate }}</span>
              </div>
            </ng-template>
            <ng-template e11Template="delayTimeHeaderTemplate">
              <automation-delay-info></automation-delay-info>
            </ng-template>
            <ng-template e11Template="upgradeProductTemplate">
              <automation-upgrade-prompt [automationType]="'Automated Questions'"></automation-upgrade-prompt>
            </ng-template>
            <ng-template e11Template="videoUrlPreviewTemplate">
              <automation-attachment-preview
                [fileReceipts]="_formData?.followUp?.attachments"
                (removeFileReceipt)="removeAttachment($event, 'followUp')"
              ></automation-attachment-preview>
            </ng-template>
          </e11-dynamic-form-builder>
        </ng-container>
        <ng-container>
          <div class="e11-flex e11-gap-2 e11-items-center">
            <h4 class="e11-mb-2 e11-font-bold">{{ 'Final Message' | translate }}</h4>
          </div>
          <e11-dynamic-form-builder
            #autoQuestionFinalMessageForm
            [loading]="loading"
            [fieldGroups]="form!.autoQuestionFinal"
            [initialValue]="_formData?.warning"
            [hideSubmitMenu]="true"
            (valueChanges)="onValueChanges('warning', $event)"
          >
            <ng-template e11Template="messageFootNoteTemplate">
              <div class="e11-my-4 ">
                <span>{{ 'Sent to the candidates when they have not responded to previous messages, questions or requests.' | translate }}</span>
              </div>
            </ng-template>
            <ng-template e11Template="delayTimeHeaderTemplate">
              <automation-delay-info></automation-delay-info>
            </ng-template>
            <ng-template e11Template="upgradeProductTemplate">
              <automation-upgrade-prompt [automationType]="'Automated Questions'"></automation-upgrade-prompt>
            </ng-template>
            <ng-template e11Template="videoUrlPreviewTemplate">
              <automation-attachment-preview
                [fileReceipts]="_formData?.warning?.attachments"
                (removeFileReceipt)="removeAttachment($event, 'warning')"
              ></automation-attachment-preview>
            </ng-template>
          </e11-dynamic-form-builder>
        </ng-container>
      </e11-show-hide-wrapper>
    </ng-container>
  `,
})
export class AutoQuestionFormComponent implements OnInit {
  @ViewChild('autoQuestionInitialMessageForm') autoQuestionInitialMessageForm!: E11DynamicFormBuilderComponent<ISendMessageFormData>
  @ViewChild('autoQuestionFollowUpMessageForm') autoQuestionFollowUpMessageForm!: E11DynamicFormBuilderComponent<ISendMessageFormData>
  @ViewChild('autoQuestionFinalMessageForm') autoQuestionFinalMessageForm!: E11DynamicFormBuilderComponent<ISendMessageFormData>
  @Input() loading: boolean = false
  _formData?: IAutomatedQuestionMessageFormData
  @Input() set formData(data: ICompanyAutomationFormData) {
    if (!data) return
    this._formData = data.autoQuestions.defaultMessages
    this.setAutoQuestionMessage({ ...this._formData! })
  }
  @Output() valueChanges = new EventEmitter<IAutomatedQuestionMessageFormData>()
  form!: IAutoQuestionForms
  _formConfig?: IAutomationFormConfig
  @Input() set formConfig(config: IAutomationFormConfig) {
    this._formConfig = config

    this.form = this.buildForm(config)
  }

  constructor(
    private jobPostAutomationService: JobPostAutomationService,
    private notification: E11NotificationsService,
    private errorHandler: E11ErrorHandlerService
  ) {}

  isAutomatedQuestionCollapsed = false
  isUploadingAttachment = false

  autoQuestionMessage: IAutomatedQuestionMessageFormData | undefined = undefined
  autoQuestionTempFiles: Partial<Record<keyof IAutomatedQuestionMessageFormData, IMarkdownReceiptFile[]>> = {}

  ngOnInit() {
    if (!this._formData) return
    this.setAutoQuestionMessage({ ...this._formData })
  }
  setAutoQuestionMessage(value: IAutomatedQuestionMessageFormData) {
    this.autoQuestionMessage = value || { ...automatedQuestionMessageFormDefaults }
  }
  onValueChanges(key: keyof IAutomatedQuestionMessageFormData, value: ISendMessageFormData) {
    if (!this.autoQuestionMessage) {
      return
    }
    this.autoQuestionMessage[key] = {
      ...this.autoQuestionMessage[key],
      ...value,
    }
    this.valueChanges.emit({ ...this.autoQuestionMessage })
  }

  private buildForm(config: IAutomationFormConfig) {
    return {
      autoQuestionInitial: sendMessageForm(
        automatedQuestionMessageFormDefaults.initialMessage,
        config,
        this.generateMultiMediaArgs('initialMessage')
      ),
      autoQuestionFollowUp: sendMessageForm(automatedQuestionMessageFormDefaults.followUp, config, this.generateMultiMediaArgs('followUp')),
      autoQuestionFinal: sendMessageForm(automatedQuestionMessageFormDefaults.warning, config, this.generateMultiMediaArgs('warning')),
    }
  }

  generateMultiMediaArgs(section: keyof IAutomatedQuestionMessageFormData): Partial<MultiMediaArgs> {
    return {
      onFileUpload: this.generateOnFileUploadFn(section),
      onFileRemove: this.generateOnFileRemoveFn(section),
    }
  }

  generateOnFileUploadFn(section: keyof IAutomatedQuestionMessageFormData) {
    return (file: IMarkdownFile[]) => {
      this.onFileUpload(this.findNewFiles(file, section), section)
    }
  }

  generateOnFileRemoveFn(section: keyof IAutomatedQuestionMessageFormData) {
    return (file: IMarkdownFile) => {
      this.removeTempFile(file.id, section)
    }
  }

  async onFileUpload(markdownFiles: IMarkdownFile[], section: keyof IAutomatedQuestionMessageFormData) {
    const validation = validateFile(markdownFiles, this.autoQuestionMessage?.[section]?.attachments ?? [])
    if (!validation.status) {
      this.notification.popNotificationMessage({
        title: validation.title,
        message: validation.message,
        type: 'error',
      })
      return
    }
    this.isUploadingAttachment = true
    try {
      const tempFiles = await this.uploadAttachments(markdownFiles)
      this.addAttachment(tempFiles, section)
    } catch (error) {
      this.errorHandler.handleE11Error(
        toE11Error(error, { title: 'Failed to upload attachment', type: 'upload-attachment-failed', additionalData: { markdownFiles } }),
        { alertUser: true }
      )
    } finally {
      this.isUploadingAttachment = false
    }
  }

  addAttachment(markdownReceipts: IMarkdownReceiptFile[], section: keyof IAutomatedQuestionMessageFormData) {
    this.autoQuestionTempFiles[section] = markdownReceipts.concat(this.autoQuestionTempFiles[section] ?? [])

    if (!this.autoQuestionMessage) return

    const files = markdownReceipts.map(r => r.receiptFile)

    this.autoQuestionMessage[section] = {
      ...this.autoQuestionMessage[section],
      attachments: [...(this.autoQuestionMessage[section]?.attachments ?? []), ...files],
    }
    this.valueChanges.emit(this.autoQuestionMessage)
  }

  findNewFiles(files: IMarkdownFile[], section: keyof IAutomatedQuestionMessageFormData): IMarkdownFile[] {
    const tempFileIds = this.autoQuestionTempFiles[section]?.map(f => f.id) ?? []
    return files.filter(f => !tempFileIds.includes(f.id))
  }

  async uploadAttachments(files: IMarkdownFile[]): Promise<IMarkdownReceiptFile[]> {
    return (await Promise.all(files.map(async file => this.uploadAttachment(file)))).filter(isNotNil)
  }

  async uploadAttachment(file: IMarkdownFile): Promise<IMarkdownReceiptFile | undefined> {
    try {
      const attachment = await this.jobPostAutomationService.uploadAttachment(file.file)
      return { id: file.id, receiptFile: attachment }
    } catch (error) {
      this.errorHandler.handleE11Error(
        toE11Error(error, { title: 'Failed to upload attachment', type: 'upload-attachment-failed', additionalData: { file } }),
        { alertUser: true }
      )
      return undefined
    }
  }

  removeTempFile(fileId: string, section: keyof IAutomatedQuestionMessageFormData) {
    const markdownFile = this.autoQuestionTempFiles[section]?.find(f => f.id == fileId)
    if (!markdownFile) return
    this.autoQuestionTempFiles[section] = this.autoQuestionTempFiles[section]?.filter(f => f.id !== fileId) ?? []
    this.removeAttachment(markdownFile.receiptFile.id, section)
  }

  removeAttachment(fileId: string, section: keyof IAutomatedQuestionMessageFormData) {
    if (!this.autoQuestionMessage) return
    this.autoQuestionMessage[section] = {
      ...this.autoQuestionMessage[section],
      attachments: this.autoQuestionMessage[section].attachments?.filter(f => f.id !== fileId),
    }
    this.valueChanges.emit(this.autoQuestionMessage)
  }
}

import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core'
import { IInterviewQuestion } from '@employer/app/modules/interview-question-library/interview-question.model'
import { E11ButtonModule } from '@engineering11/ui-lib/e11-button'
import { TranslateModule } from '@ngx-translate/core'
import { SearchJobInterviewComponent } from '../../../../../modules/jobs/components/job-interview/search-job-interview.component'

@Component({
  selector: 'automated-question-search-interview',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [E11ButtonModule, TranslateModule, SearchJobInterviewComponent, CommonModule],
  template: `
    <ng-container>
      <div class="e11-w-full md:e11-w-2/3">
        <search-job-interview (onQuestionSelect)="addQuestion($event)" [selectedQuestions]="selectedQuestions"> </search-job-interview>
      </div>
      <e11-button
        *ngIf="showAddButton"
        [color]="'primary-accent'"
        [type]="'submit'"
        [buttonGroup]="true"
        value="{{ 'Add question' | translate }}"
        (click)="onSaveQuestions.emit(selectedQuestions)"
      ></e11-button>
    </ng-container>
  `,
})
export class AutomatedQuestionSearchInterviewComponent {
  @Output() onSaveQuestions = new EventEmitter<IInterviewQuestion[]>()

  showAddButton = false
  selectedQuestions: IInterviewQuestion[] = []

  async addQuestion(question: IInterviewQuestion) {
    const selectedQuestionsId = this.selectedQuestions.map(q => q.id)
    if (!selectedQuestionsId.includes(question.id)) {
      this.selectedQuestions = [...this.selectedQuestions, question]
      this.showAddButton = true
    } else {
      this.selectedQuestions = this.selectedQuestions.filter(q => q.id !== question.id)
      this.showAddButton = this.selectedQuestions.length > 0
    }
  }
}

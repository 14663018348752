import { Component, Input } from '@angular/core'
import { IMaskedJobPostApplication } from '@employer/app/modules/jobs/components/candidate-share-profile/job-post-application-share.model'
import { ListMode, SharedLibModule, UserMode } from 'shared-lib'
import { NgIf } from '@angular/common'
import { E11UtilModule } from '@engineering11/ui-lib/e11-ui-utils'
import { TranslateModule } from '@ngx-translate/core'

@Component({
  selector: 'shared-application-left-sections',
  standalone: true,
  imports: [NgIf, SharedLibModule, E11UtilModule, TranslateModule],
  template: `
    <ng-container>
      <div *ngIf="candidateApplication.firstImpression" class="section-wrapper e11-relative e11-mb-8">
        <div class="e11-flex e11-w-full e11-items-center e11-border-b e11-border-skin-grey/50 e11-pb-2 e11-mb-4">
          <div class="e11-grow">
            <h4 class="e11-font-skin-secondary e11-font-bold">
              {{ 'First Impression' | translate }}
            </h4>
          </div>
        </div>
        <h4 *ngIf="!candidateApplication" class="e11-h3-skeleton e11-mb-2"></h4>
        <div *ngIf="!candidateApplication" class="e11-panel-skeleton"></div>
        <conditional-link-wrapper [link]="cleanURL" [shouldWrap]="pdfMode">
          <ng-template [e11Template]="'Body'">
            <single-first-impression
              [firstImpression]="candidateApplication.firstImpression"
              [userMode]="userMode"
              [showRequestButton]="showRequestButton"
            ></single-first-impression>
          </ng-template>
        </conditional-link-wrapper>
      </div>
      <!-- Work History-->
      <div *ngIf="candidateApplication && candidateApplication.workHistory?.length" class="section-wrapper e11-mb-8">
        <div class="e11-flex e11-w-full e11-items-center e11-border-b e11-border-skin-grey/50 e11-pb-2 e11-mb-4">
          <div class="e11-grow">
            <h4 class="e11-font-skin-secondary e11-font-bold">
              {{ 'Work History' | translate }}
            </h4>
          </div>
        </div>
        <div *ngIf="!candidateApplication" class="e11-panel-skeleton"></div>
        <ui-work-history
          *ngIf="candidateApplication"
          [listMode]="listMode"
          [workHistories]="candidateApplication.workHistory"
          [userMode]="1"
          [pdfMode]="pdfMode"
        ></ui-work-history>
      </div>
      <!-- Education -->
      <div *ngIf="candidateApplication && candidateApplication.education?.length" class="section-wrapper e11-mb-8">
        <div class="e11-flex e11-w-full e11-items-center e11-border-b e11-border-skin-grey/50 e11-pb-2 e11-mb-4">
          <div class="e11-grow">
            <h4 class="e11-font-skin-secondary e11-font-bold">
              {{ 'Education' | translate }}
            </h4>
          </div>
        </div>
        <div *ngIf="!candidateApplication" class="e11-panel-skeleton"></div>
        <ui-education
          *ngIf="candidateApplication"
          [educations]="candidateApplication.education || null"
          [listMode]="listMode"
          [userMode]="userMode"
        ></ui-education>
      </div>
    </ng-container>
  `,
})
export class SharedApplicationLeftSectionsComponent {
  @Input() cleanURL!: string
  @Input() candidateApplication!: IMaskedJobPostApplication
  @Input() pdfMode: boolean = false

  listMode: ListMode = ListMode.Vertical
  userMode: UserMode = UserMode.Employer
  showRequestButton = false

  constructor() {}
}

import { Component, Input, TemplateRef, ViewChild } from '@angular/core'
import { E11ButtonModule } from '@engineering11/ui-lib/e11-button'
import { E11ModalGlobalService, E11ModalModule } from '@engineering11/ui-lib/e11-modal'
import { TranslateModule } from '@ngx-translate/core'
import {
  ALL_YOU_NEED,
  HEADER_INFO,
  IAutomationHelperModalData,
  WHAT_HAPPENS,
} from '../automation-unresponsive-candidate/automated-unresponsive.constants'

@Component({
  selector: 'automation-popup-helper-modal',
  standalone: true,
  imports: [E11ModalModule, TranslateModule, E11ButtonModule],
  template: `
    <span (click)="openModal()" class="e11-text-skin-primary-accent e11-cursor-pointer hover:e11-text-skin-primary">{{ 'How this works' }}</span>
    <ng-template #modal>
      <div class="e11-mb-6">
        <div class="e11-flex e11-gap-2 e11-text-skin-primary-accent e11-mb-4">
          <span class="material-icons"> {{ headerInfo.icon }} </span>
          <h3>{{ headerInfo.title | translate }}</h3>
        </div>
        <p>
          {{ headerInfo.description | translate }}
        </p>
      </div>
      <h3 class=" e11-mb-4">{{ 'All you need to do' | translate }}</h3>

      <div class="e11-flex-1 e11-border-2 e11-border-skin-cola e11-p-4 e11-mb-6">
        <div class="e11-flex e11-gap-2  e11-mb-4">
          <span class="material-icons e11-text-skin-primary-accent"> {{ allYouNeed.icon }} </span>
          <h4 class="e11-capitalize e11-font-semibold">{{ allYouNeed.title | translate }}</h4>
        </div>
        <p>
          {{ allYouNeed.description | translate }}
        </p>
      </div>

      <h3 class=" e11-mb-4">{{ 'What happens' | translate }}</h3>
      <div class="e11-border-2 e11-border-skin-cola e11-p-4 e11-gap-2">
        <div class="e11-flex e11-gap-2 e11-mb-4">
          <span class="material-icons  e11-text-skin-primary-accent"> {{ whatHappens.icon }} </span>
          <h4 class="e11-capitalize e11-font-semibold">{{ whatHappens.title | translate }}</h4>
        </div>
        <p class="e11-bg-skin-grey/20 e11-p-4">
          {{ whatHappens.description | translate }}
        </p>
      </div>
    </ng-template>
  `,
})
export class AutomationPopupHelperModalComponent {
  @ViewChild('modal') modal!: TemplateRef<any>
  @Input() title: string = 'Automation Configuration - Candidate Unresponsive'
  @Input() allYouNeed: IAutomationHelperModalData = ALL_YOU_NEED
  @Input() whatHappens: IAutomationHelperModalData = WHAT_HAPPENS
  @Input() headerInfo: IAutomationHelperModalData = HEADER_INFO

  constructor(private modalService: E11ModalGlobalService) {}

  openModal() {
    this.modalService.openModal({
      body: this.modal,
      title: this.title,
      width: 'lg',
    })
  }
}

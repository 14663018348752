<div class="header-container e11-w-full">
  <header class="header e11-container-full e11-mx-auto">
    <div class="e11-w-full e11-flex e11-items-center">
      <div class="e11-w-1/2" [routerLink]="['/home']">
        <img [src]="logoUrl" class="e11-h-16 e11-max-h-16 e11-object-contain e11-object-left" />
      </div>
      <div class="e11-w-1/2 e11-flex e11-justify-end"></div>
    </div>
  </header>
</div>

import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { INotificationHandler, INotificationView, UserAppNotificationService } from '@engineering11/notifications-web'
import { IEmployerApplicationResubmittedNotification } from 'shared-lib'

@Injectable({ providedIn: 'root' })
export class EmployerApplicationResubmittedHandler implements INotificationHandler<IEmployerApplicationResubmittedNotification, INotificationView> {
  notificationType: string = 'employer_application_resubmitted'
  constructor(private router: Router, private userAppNotificationService: UserAppNotificationService) {}

  onClick(notification: IEmployerApplicationResubmittedNotification): boolean | Promise<boolean> {
    if (!notification.viewed) {
      this.userAppNotificationService.markNotificationAsRead(notification.id)
    }
    return this.router.navigate(['/jobs/candidates/', notification.jobId], { queryParams: { candidateId: notification.candidateApplicationId } })
  }
}

import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { INotificationHandler, INotificationView, UserAppNotificationService } from '@engineering11/notifications-web'
import { ICandidateAppliedNotification } from 'projects/shared-lib/src/lib/model/app-notification.model'
import { CandidateDetailTabs, EmployerNavigationService } from '../navigation.service'

@Injectable({ providedIn: 'root' })
export class CandidateAppliedNotificationHandler implements INotificationHandler<ICandidateAppliedNotification, INotificationView> {
  notificationType = 'employer_new_application_received'
  constructor(
    private router: Router,
    private userAppNotificationService: UserAppNotificationService,
    private navigationService: EmployerNavigationService
  ) {}

  onClick(notification: ICandidateAppliedNotification): boolean | Promise<boolean> {
    if (!notification.viewed) {
      this.userAppNotificationService.markNotificationAsRead(notification.id)
    }
    return this.navigationService.toCandidateDetailPage(notification.jobPostId, notification.candidateApplicationId, CandidateDetailTabs.Profile)
  }
}

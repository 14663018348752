import { CollectionRepository, FirestoreQueryBuilder } from '@engineering11/web-api-firebase'
import { IJobPostUserConfig } from '@employer/app/models/job-post-user-config.model'
import { Injectable } from '@angular/core'
import { COLLECTIONS } from 'shared-lib'

@Injectable({ providedIn: 'root' })
export class JobPostUserConfigRepository extends CollectionRepository<IJobPostUserConfig> {
  protected COLLECTION: string = COLLECTIONS.JOB_POST_USER_CONFIG

  getAllForCustomer(customerKey: string) {
    const query = new FirestoreQueryBuilder<IJobPostUserConfig>()
    query.where('customerKey', '==', customerKey)
    return this.query(query.build())
  }

  getForCustomerUser(userId: string) {
    return this.get(userId)
  }
}

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'ui-interview-question-skeleton',
  templateUrl: './interview-question-skeleton.component.html',
  styleUrls: ['./interview-question-skeleton.component.scss'],
})
export class InterviewQuestionSkeletonComponent implements OnInit {
  ngOnInit(): void {}
}

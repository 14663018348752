import {
  IAutoQuestionAutomationFormData,
  autoQuestionAutomationDefaults,
} from '@employer/app/components/jobs-automation/automation-auto-question/auto-questions.model'

import { IRequestMoreFormData, requestMoreFormDefaults } from './auto-request-updates.model'
import { IApplicationAutoResponseFormData, autoResponseDefaults } from './auto-response.model'
import { IRejectCandidateFormData, rejectCandidateDefaults } from './automation-candidate-rejection/candidate-rejection.model'
import { IUnresponsiveCandidateFormData, unresponsiveCandidateDefaults } from './automation-unresponsive-candidate/unresponsive-candidate.model'

export interface IAutomationFormData {
  autoResponse: IApplicationAutoResponseFormData
  requestMore: IRequestMoreFormData
  autoQuestions: IAutoQuestionAutomationFormData
  unresponsiveCandidate?: IUnresponsiveCandidateFormData
  rejectCandidate?: IRejectCandidateFormData
}

export const automationFormDefaults: IAutomationFormData = {
  autoResponse: autoResponseDefaults,
  requestMore: requestMoreFormDefaults,
  autoQuestions: autoQuestionAutomationDefaults,
  unresponsiveCandidate: unresponsiveCandidateDefaults,
  rejectCandidate: rejectCandidateDefaults,
}

<!-- Buttons -->
<span *ngIf="state && controls === 'buttons'">
  <ng-container *ngFor="let item of $any(state.options)">
    <e11-button
      *ngIf="item.toggleState"
      (click)="toggleRefinement(item)"
      value="{{ item.label | uppercase | translate }}"
      color="primary"
      iconClassOverrides="{{ state.currentRefinement.includes(item.name) && isToggled ? 'e11-rotate-180' : '' }}"
      icon="sort"
      iconPosition="left"
      [iconSize]="'md-18'"
      [style]="state.currentRefinement.includes(item.name) ? 'pill' : 'pill outlined'"
      [size]="'sm'"
      [buttonGroup]="true"
    >
    </e11-button>

    <e11-button
      *ngIf="!item.toggleState"
      (click)="toggleRefinement(item)"
      color="primary"
      value="{{ item.label | uppercase | translate }}"
      [style]="state.currentRefinement === item.value ? 'pill' : 'pill outlined'"
      [size]="'sm'"
      [buttonGroup]="true"
    >
    </e11-button>
  </ng-container>
</span>

<!-- Drop downs -->
<div
  (click)="showOptions = !showOptions"
  (mouseleave)="showOptions = false"
  class="sort-by-dropdown e11-relative e11-flex e11-text-sm e11-items-center e11-cursor-pointer e11-rounded e11-border e11-border-skin-primary-accent hover:e11-border-skin-primary e11-text-skin-primary-accent hover:e11-text-skin-primary e11-p-2 e11-bg-skin-white"
  *ngIf="state && controls === 'dropdown'"
>
  <span class="material-icons-outlined"> swap_vert </span>
  <span class="e11-font-primary-demibold">{{ 'Sort by:' | translate }} </span>
  <span class="material-icons-outlined md-14 e11-ml-2 e11-mr-1" [ngClass]="isToggled ? 'e11-rotate-180' : ''"> arrow_downward </span>
  {{ currentSortLabel }}
  <div
    *ngIf="showOptions"
    class="sort-by-dropdown-options e11-absolute e11-w-full e11-top-10 e11-left-0 e11-z-10 e11-shadow-lg e11-bg-white e11-rounded e11-border e11-border-skin-app-borders"
  >
    <ng-container *ngFor="let item of $any(state.options)">
      <div
        class="e11-w-full e11-flex e11-items-center e11-cursor-pointer e11-p-2"
        (click)="toggleRefinement(item)"
        [ngClass]="state.currentRefinement.includes(item.name) ? 'e11-bg-skin-primary-accent e11-text-white' : 'hover:e11-bg-skin-grey/20'"
      >
        <span
          class="material-icons-outlined md-14 e11-mr-1"
          [ngClass]="state.currentRefinement.includes(item.name) && isToggled ? 'e11-rotate-180' : ''"
        >
          arrow_downward
        </span>
        {{ item.label }}
      </div>
    </ng-container>
  </div>
</div>

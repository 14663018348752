import { Injectable } from '@angular/core'
import { E11Error, E11ErrorHandlerService } from '@engineering11/web-api-error'
import { ComponentStore } from '@ngrx/component-store'
import { Observable, switchMap } from 'rxjs'
import { AuthService } from '@employer/app/services/auth.service'
import { tapResponse } from '@ngrx/operators'

type CustomToken = { token: string; tenantId: string }

export interface AuthStoreState {
  customToken: CustomToken | null
}

const defaultState: AuthStoreState = {
  customToken: null,
}

@Injectable({
  providedIn: 'root',
})
export class AuthStore extends ComponentStore<AuthStoreState> {
  constructor(private authService: AuthService, private errorHandler: E11ErrorHandlerService) {
    super(defaultState)
  }

  // SELECTORS
  readonly customToken$ = this.select(s => s.customToken)

  // EFFECTS
  readonly onGetCustomToken = this.effect((_$: Observable<void>) =>
    _$.pipe(
      switchMap(() => {
        this.setCustomToken(null)
        return this.authService.customLoginToken().pipe(
          tapResponse(
            token => this.setCustomToken(token),
            (err: E11Error) => this.errorHandler.handleE11Error(err)
          )
        )
      })
    )
  )

  // UPDATERS
  readonly setCustomToken = this.updater((state, customToken: CustomToken | null) => ({
    ...state,
    customToken,
  }))
}

import { Component, Input } from '@angular/core'
import { CustomerStore, ICustomer } from '@engineering11/customer-web'
import { combineLatest, map } from 'rxjs'
import { IEmployer } from 'shared-lib'
import { EmployerStore } from '../../employer.store'
import { EmployerSettingData, SettingMode } from './employer-setting.component'
import { aboutDisplay, companyHeaderNameDisplay, companyWebsiteDisplay, cultureAndValueDisplay } from './setting-display'
@Component({
  selector: 'manage-employer-settings',
  template: `
    <div class="e11-mb-16">
      <ng-container *ngrxLet="loading$ as loading">
        <e11-panel *ngrxLet="employer$ as employer">
          <careers-page-link [employerId]="employer?.id ?? undefined"></careers-page-link>

          <div class="e11-flex e11-w-full e11-mb-5">
            <div class="e11-grow">
              <h2 class="e11-text-skin-primary-accent">{{ 'General Site Content' | translate }}</h2>
              <p>
                {{
                  'This content may be displayed in multiple areas of your site. It will be displayed on Job Posts as well as your Careers Page if you have it toggled on.'
                    | translate
                }}
              </p>
            </div>
            <div class="e11-flex e11-justify-end"></div>
          </div>

          <div class="e11-flex e11-flex-col e11-gap-4">
            <employer-setting
              [loading]="loading"
              [settingData]="{ setting: employer?.about, switchOn: employer?.showAbout }"
              [settingDisplay]="aboutDisplay"
              (settingSubmitted)="onSubmitAbout($event)"
              [settingMode]="SettingMode.TextEditor"
            ></employer-setting>

            <employer-setting
              [loading]="loading"
              [settingData]="{ setting: employer?.cultureStatement, switchOn: employer?.showCultureStatement }"
              [settingDisplay]="cultureAndValueDisplay"
              (settingSubmitted)="onSubmitCultureStatement($event)"
              [settingMode]="SettingMode.TextEditor"
            ></employer-setting>

            <employer-setting
              [loading]="loading"
              [settingData]="{ setting: employer?.companyWebsite, switchOn: employer?.showCompanyWebsite }"
              [settingDisplay]="companyWebsiteDisplay"
              (settingSubmitted)="onSubmitCompanyWebsite($event)"
              [settingMode]="SettingMode.UrlInput"
            ></employer-setting>

            <employer-setting
              *ngrxLet="employerAndCustomerData$ as employerAndCustomerData"
              [loading]="loading"
              [settingData]="{ setting: employerAndCustomerData?.customerName, switchOn: employerAndCustomerData?.hideName }"
              [settingDisplay]="companyHeaderNameDisplay"
              (settingSubmitted)="onSubmitHeaderNameDisplay($event)"
              [settingMode]="SettingMode.SwitchOnly"
            ></employer-setting>
          </div>
        </e11-panel>
      </ng-container>
    </div>
  `,
  styleUrls: ['./manage-employer.component.scss'],
})
export class ManageEmployerSettingsComponent {
  employer?: IEmployer
  customer?: ICustomer
  SettingMode = SettingMode

  employer$ = this.employerStore.employer$
  loading$ = this.employerStore.employerLoaded$.pipe(map(loaded => !loaded))
  customer$ = this.customerStore.currentCustomer$

  employerAndCustomerData$ = combineLatest([this.employer$, this.customer$]).pipe(
    map(([employer, customer]) => ({
      hideName: employer?.hideName,
      customerName: customer?.name,
    }))
  )

  aboutDisplay = aboutDisplay
  cultureAndValueDisplay = cultureAndValueDisplay
  companyHeaderNameDisplay = companyHeaderNameDisplay
  companyWebsiteDisplay = companyWebsiteDisplay

  constructor(private employerStore: EmployerStore, private customerStore: CustomerStore) {}

  async onSubmitAbout(data: EmployerSettingData) {
    this.employerStore.onUpdateEmployer({ about: data.setting, showAbout: data.switchOn })
  }

  async onSubmitCultureStatement(data: EmployerSettingData) {
    this.employerStore.onUpdateEmployer({ cultureStatement: data.setting, showCultureStatement: data.switchOn })
  }

  async onSubmitCompanyWebsite(data: EmployerSettingData) {
    this.employerStore.onUpdateEmployer({ companyWebsite: data.setting, showCompanyWebsite: data.switchOn })
  }

  async onSubmitHeaderNameDisplay(data: EmployerSettingData) {
    this.employerStore.onUpdateEmployer({ hideName: data.switchOn })
  }
}

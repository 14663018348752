import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { AddInterviewQuestionRequest, IInterviewQuestion } from '@employer/app/modules/interview-question-library/interview-question.model'
import { IQuestionUpdate } from 'shared-lib'
import { WriteJobInterviewComponent } from '../../../../../modules/jobs/components/job-interview/write-job-interview.component'

@Component({
  selector: 'automated-question-write-interview',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [WriteJobInterviewComponent],
  template: `
    <div class="e11-w-full md:e11-w-2/3">
      <write-job-interview
        addButtonLabel="Add Question"
        saveButtonLabel="Save Question"
        [questionToEdit]="questionToEdit"
        (onSaveToLibrary)="onSaveToLibrary.emit($event)"
        (onAddQuestion)="onAddQuestion.emit($event)"
        (onUpdateQuestion)="onUpdateQuestion.emit($event)"
        (onEditCancelled)="onEditCancelled.emit()"
      >
      </write-job-interview>
    </div>
  `,
})
export class AutomatedQuestionWriteInterviewComponent {
  @Input() questionToEdit?: IInterviewQuestion // editing this question
  @Output() onSaveToLibrary = new EventEmitter<AddInterviewQuestionRequest>()
  @Output() onAddQuestion = new EventEmitter<AddInterviewQuestionRequest>()
  @Output() onUpdateQuestion = new EventEmitter<IQuestionUpdate>()
  @Output() onEditCancelled = new EventEmitter()
}

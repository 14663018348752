import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormControl, FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import {
  EMPLOYER_HERO_DESCRIPTION_MAX_LENGTH,
  EMPLOYER_HERO_TITLE_MAX_LENGTH,
  MIN_HEADER_IMAGE_HEIGHT,
  MIN_HEADER_IMAGE_WIDTH,
} from '@employer/app/app.constants'
import { environment } from '@employer/environments/environment'
import { Validation } from '@engineering11/ui-lib/e11-input-errors'
import { E11NotificationMessage, E11NotificationsService } from '@engineering11/ui-lib/e11-notifications'
import { validateImageDimensions, ViewportService } from '@engineering11/web-utilities'
import { TranslateService } from '@ngx-translate/core'
import { AvailableThemes, EmployerDefaultTheme, EmployerService, IEmployer, IEmployerTheme, IHeroSection, ITheme, TokenStorage } from 'shared-lib'
import { FilesService } from '@engineering11/files-web'
import { ReplaceValues } from '@engineering11/types'
import { Subject, takeUntil } from 'rxjs'
import { E11ErrorHandlerService } from '@engineering11/web-api-error'

type CustomThemeForm = ReplaceValues<IEmployerTheme, FormControl<string | null>>

@Component({
  selector: 'manage-careers-page',
  templateUrl: './careers-page-hero.component.html',
  styleUrls: ['./careers-page-hero.component.scss'],
})
export class CareersPageHeroComponent implements OnInit, OnDestroy {
  employer?: IEmployer
  formLink = new UntypedFormGroup({})
  linkToCareersPageBaseURL = environment.careersPageBaseLink
  linkToCareersPage: string = ''
  showCopiedMessage = false

  iTheme = ITheme
  themesAvailable = AvailableThemes
  themeCurrent: IEmployerTheme = EmployerDefaultTheme
  themePreview: IEmployerTheme = EmployerDefaultTheme // Used to preview in UI

  activeTheme: string = 'Default'

  formHeroSection = new UntypedFormGroup({})
  formHeroSectionSubmitted: boolean = false
  formReady: boolean = false

  maxCharTitle = EMPLOYER_HERO_TITLE_MAX_LENGTH
  maxCharDescription = EMPLOYER_HERO_DESCRIPTION_MAX_LENGTH

  sectionCurrent: number = 0

  isMobile$ = this.viewportService.belowBreakpoint('lg')

  heroSectionImages = [
    'https://content.cnected.com/assets/career-page/3d-render-low-poly-plexus-design-network-communications.jpeg',
    'https://content.cnected.com/assets/career-page/business-people-casual-meeting.jpeg',
    'https://content.cnected.com/assets/career-page/close-up-businesswoman-pointing-digital-tablet.jpeg',
    'https://content.cnected.com/assets/career-page/close-up-portrait-freelance-it-specialists-looking-laptop-screen-with-smile.jpeg',
    'https://content.cnected.com/assets/career-page/community-young-people-socializing.jpeg',
    'https://content.cnected.com/assets/career-page/front-view-people-having-meeting-office.jpeg',
    'https://content.cnected.com/assets/career-page/light-blue-3d-abstract-wave-pattern.jpeg',
    'https://content.cnected.com/assets/career-page/modern-buildings-toronto-city.jpeg',
    'https://content.cnected.com/assets/career-page/woman-having-video-call.jpeg',
  ]
  processingCustomImage: boolean = false

  minHeaderImageWidth = MIN_HEADER_IMAGE_WIDTH
  minHeaderImageHeight = MIN_HEADER_IMAGE_HEIGHT

  destroy$: Subject<boolean> = new Subject<boolean>()

  customThemeForm = new FormGroup<CustomThemeForm>({
    name: new FormControl(ITheme.Custom, [Validators.required]),
    primary: new FormControl(EmployerDefaultTheme.primary, [Validators.required]),
    secondary: new FormControl(EmployerDefaultTheme.secondary, [Validators.required]),
    text: new FormControl(EmployerDefaultTheme.text, [Validators.required]),
    buttonText: new FormControl(EmployerDefaultTheme.buttonText, [Validators.required]),
  })
  ITheme = ITheme

  constructor(
    private tokenStorage: TokenStorage,
    private formBuilder: UntypedFormBuilder,
    private employerService: EmployerService,
    private translate: TranslateService,
    private notificationsService: E11NotificationsService,
    private viewportService: ViewportService,
    private filesService: FilesService,
    private errorHandler: E11ErrorHandlerService
  ) {}

  ngOnInit(): void {
    this.formHeroSection = this.formBuilder.group({
      title: new UntypedFormControl(''),
      description: new UntypedFormControl(''),
      headerImage: new UntypedFormControl(''),
    })

    const user = this.tokenStorage.getItem('user')
    this.listenToCustomThemeChanges()
    if (user) {
      this.employerService.get(user.customerKey).subscribe(employer => {
        this.employer = employer
        if (!this.employer) {
          return
        }

        this.formHeroSection?.patchValue({
          title: this.employer?.heroSection?.title || '',
          description: this.employer?.heroSection?.description || '',
          headerImage: this.employer?.heroSection?.headerImage || '',
        })
        this.linkToCareersPage = `${this.linkToCareersPageBaseURL}?client=${user.customerKey}`
        this.formReady = true
        this.themeCurrent = this.getCurrentTheme(this.employer.theme)
        this.themePreview = this.themeCurrent
        this.activeTheme = this.themePreview.name

        if (this.employer.customHeaderImageURL) {
          this.heroSectionImages.push(this.employer.customHeaderImageURL)
        }
        if (this.employer.customTheme) {
          this.customThemeForm.patchValue(this.employer.customTheme)
        }
      })
    }
  }

  private listenToCustomThemeChanges() {
    this.customThemeForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      if (this.activeTheme !== ITheme.Custom) {
        return
      }

      this.themePreview = value as IEmployerTheme
    })
  }

  ngOnDestroy() {
    this.destroy$.next(true)
    this.destroy$.unsubscribe()
  }

  get formHero() {
    return this.formHeroSection.controls as {
      [key: string]: UntypedFormControl
    }
  }

  protected getCurrentTheme(selectedTheme: ITheme | IEmployerTheme | undefined): IEmployerTheme {
    if (!selectedTheme) {
      return EmployerDefaultTheme
    }

    if (typeof selectedTheme === 'string') {
      return AvailableThemes.find(t => t.name === selectedTheme) || EmployerDefaultTheme
    }

    return selectedTheme
  }

  preview() {
    window.open(this.linkToCareersPage, '_blank')
  }

  setHeaderImage(img: string) {
    this.formHeroSection.get('headerImage')?.setValue(img)
  }

  async onSubmitHero() {
    this.formHeroSectionSubmitted = true
    if (this.formHeroSection.invalid) {
      this.formHeroSection.updateValueAndValidity()
      Validation.validateAll(this.formHeroSection)
      return
    }

    this.formHeroSectionSubmitted = false
    const heroSection: IHeroSection = {
      title: this.formHero.title.value,
      description: this.formHero.description.value,
      headerImage: this.formHero.headerImage.value,
    }
    const employer = this.createOrGetEmployer()
    employer.heroSection = heroSection
    employer.theme = this.themePreview
    employer.customTheme = this.customThemeForm.value as IEmployerTheme
    // Notification (Success)
    let message = ''
    this.translate.get("Your 'Hero Section' information has been updated.").subscribe((res: string) => {
      message = res
    })
    await this.saveAndNotify(message)
  }

  async saveAndNotify(message: string) {
    if (this.employer) {
      await this.employerService.set(this.employer)
      let title = ''
      this.translate.get('Saved').subscribe((res: string) => {
        title = res
      })
      this.notificationsService.popNotificationMessage(new E11NotificationMessage(title, message, 'success', true, ''))
    }
  }

  createOrGetEmployer(): IEmployer {
    if (this.employer) {
      return this.employer
    }
    const user = this.tokenStorage.getItem('user')
    this.employer = {
      id: user.customerKey,
      showLegalDisclaimer: false,
      showAbout: false,
    }
    return this.employer
  }

  sectionSelect(value: number) {
    this.sectionCurrent = value
  }

  selectPreviewTheme(theme: ITheme) {
    this.activeTheme = theme
    if (theme === ITheme.Custom) {
      this.themePreview = this.customThemeForm.value as IEmployerTheme
      return
    }

    this.themePreview = AvailableThemes.find(t => t.name === theme) || EmployerDefaultTheme
  }

  async uploadCustomHeaderImage(event: any) {
    if (!this.employer) {
      return
    }
    this.processingCustomImage = true
    const validDimensions = await validateImageDimensions(
      event.target.files[0],
      {
        width: this.minHeaderImageWidth,
        height: this.minHeaderImageHeight,
      },
      false
    )

    if (!validDimensions) {
      this.processingCustomImage = false
      this.notificationsService.popNotificationMessage({
        title: 'Invalid Image',
        message: `Image must be at least ${this.minHeaderImageWidth}x${this.minHeaderImageHeight} pixels`,
        type: 'error',
        autoClose: true,
        dismissOnRouteChange: true,
      })
      return
    }

    const file = event.target.files[0]
    const result = await this.filesService.uploadFile({
      file,
      customerKey: this.employer.id,
      groupingId: this.employer.id,
      makePublic: true,
      validation: {
        types: ['image/jpeg', 'image/png'],
      },
    })

    if (!result || !result.url) {
      this.processingCustomImage = false
      return
    }

    this.employer.customHeaderImageURL = result.url
    await this.employerService.set(this.employer)

    this.heroSectionImages.push(result.url)
    this.processingCustomImage = false
  }

  async removeCustomHeaderImage() {
    if (!this.employer || !this.employer.customHeaderImageURL) {
      return
    }

    this.processingCustomImage = true
    await this.filesService.deleteFromUrl(this.employer.customHeaderImageURL, { throwOnError: false })
    this.heroSectionImages = this.heroSectionImages.filter(i => i !== this.employer?.customHeaderImageURL)
    if (this.employer.heroSection && this.employer.heroSection.headerImage === this.employer.customHeaderImageURL) {
      this.employer.heroSection.headerImage = ''
      this.setHeaderImage('')
    }
    this.employer.customHeaderImageURL = undefined
    await this.employerService.set(this.employer)
    this.processingCustomImage = false
  }
}

import { Injectable } from '@angular/core'
import { USStateCode } from '@engineering11/utility'
import { US_STATE_CODE_TO_NAME } from '@engineering11/utility'

@Injectable({
  providedIn: 'root',
})
export class JobPostLocationService {
  constructor() {}

  protected getSalaryTransparencyStates(): USStateCode[] {
    return ['CA', 'CO', 'CT', 'HI', 'IL', 'MD', 'NV', 'NJ', 'NY', 'OH', 'RI', 'WA']
  }

  stateRequiresSalaryTransparency(state: string): boolean {
    const states = this.getSalaryTransparencyStates()
    state = state.toLowerCase()

    return states.some(stateCode => stateCode.toLowerCase() === state || US_STATE_CODE_TO_NAME[stateCode].toLowerCase() === state)
  }
}

<ng-container *ngrxLet="currentUserReview$ as currentRating">
  <div class="e11-ratings e11-float-left">
    <div
      class="container-review e11-cursor-pointer e11-flex e11-items-center e11-h-6"
      title="{{ 'Click to set a rating' | translate }}"
      (click)="showRatingControls(true)"
    >
      <ui-candidate-stars [starRating]="currentRating?.starRating ?? 0" [size]="'sm'"></ui-candidate-stars>
    </div>

    <div
      class="e11-rating-controls e11-shadow-md e11-border e11-rounded e11-border-skin-app-borders e11-bg-white e11-p-4"
      [ngClass]="{ 'e11-rating-controls-active': showControls }"
    >
      <div
        class="e11-rating-controls-closer e11-cursor-pointer hover:e11-text-skin-dark"
        (click)="showRatingControls(false); clearRating(currentRating?.starRating ?? 0)"
      >
        <span class="material-icons-outlined">close</span>
      </div>
      <p class="e11-font-primary-demibold e11-mb-2 e11-text-skin-primary-accent">{{ 'Choose your star rating' | translate }}</p>
      <div class="e11-w-full e11-flex e11-items-center">
        <!-- @Todo: Future work - Clear rating -->
        <!-- <div class="e11-shrink e11-flex e11-items-center">
          <span class="e11-mr-2 e11-text-sm" (click)="clearRating()">({{'Clear' | translate}})</span>
        </div> -->
        <star-rating-form
          *ngIf="showControls"
          [currentRating]="currentRating?.starRating ?? 0"
          [readOnly]="readOnly"
          (ratingChanged)="rate($event)"
        ></star-rating-form>
      </div>

      <div class="e11-w-full e11-flex e11-items-center e11-border-b e11-border-skin-app-borders e11-pb-2">
        <div class="e11-grow">
          <span
            class="e11-mr-2 e11-text-sm e11-text-skin-light e11-cursor-pointer hover:e11-text-skin-dark"
            (click)="showRatingControls(false); clearRating(currentRating?.starRating ?? 0)"
            >{{ 'Cancel' | translate }}</span
          >
        </div>
        <div class="e11-grow e11-flex e11-justify-end">
          <e11-button [size]="'sm'" color="primary-accent" value="{{ 'Save Rating' | translate }}" [type]="'button'" (click)="onSubmitRating()">
          </e11-button>
        </div>
      </div>
      <ng-container *ngrxLet="reviews$ as ratings">
        <div class="ratings" *ngIf="ratings && ratings.length > 0; else Empty" #ratingContainer>
          <div class="e11-my-2" *ngFor="let rating of ratings" #ratingList>
            <div class="e11-w-full e11-flex">
              <div class="e11-mr-2">
                <e11-profile-pic
                  [imageUrl]="rating.profilePhoto"
                  [size]="'sm'"
                  type="circular"
                  [textValue]="rating.displayName || '' | initials"
                ></e11-profile-pic>
              </div>
              <div class="e11-grow">
                <div class="e11-flex">
                  <div class="e11-text-sm e11-grow e11-line-clamp-1">{{ rating.displayName }}</div>
                  <div class="e11-flex e11-justify-end e11-text-xs e11-text-skin-light">
                    {{ rating.ratingUpdatedDate ?? rating.createdDate | date : 'shortDate' }}
                  </div>
                </div>
                <div class="rating">
                  <ui-candidate-stars [starRating]="rating.starRating" [size]="'sm'"></ui-candidate-stars>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Empty -->
      <ng-template #Empty>
        <p class="e11-my-4 e11-text-skin-light e11-text-sm">{{ 'No ratings for this candidate' | translate }}</p>
      </ng-template>
    </div>
  </div>
</ng-container>

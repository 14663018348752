import { Component, OnInit, Renderer2 } from '@angular/core'
import { environment } from '@employer/environments/environment'
import { PermissionStore } from '@engineering11/access-web'
import { ConversationMessageStore, ConversationStore } from '@engineering11/messaging-web'
import { NotificationStore } from '@engineering11/notifications-web'
import { E11NotificationsService } from '@engineering11/ui-lib/e11-notifications'
import { E11Logger } from '@engineering11/web-api-error'
import { UiScrollLockingService } from '@engineering11/web-utilities'
import { Store } from '@ngrx/store'
import { TranslateService } from '@ngx-translate/core'
import { isElectron } from 'recordrtc'
import { ConfigStore, hookToGlobalState, WebpackTranslateLoader } from 'shared-lib'
import { EmployerStore } from './modules/customer/employer.store'
import { CandidateListStore } from './modules/jobs/stores/candidate-list.store'
import { JobApplicationStore } from './modules/jobs/stores/job-application.store'
import { JobProfileStore } from './modules/jobs/stores/job-profile.store'
import { ConfigService } from './services/config.service'
import { FaviconSwitchingService } from './services/favicon-switching.service'
import { JobPostAutomationStore } from './modules/jobs/views/job-automation/job-post-automation.store'
import { JobsPostAutomationCompanySettingsStore } from '@employer/app/modules/customer/views/jobs-post-automation-company-settings/jobs-post-automation-company-settings.store'
import { AutoQuestionStore } from '@employer/app/components/jobs-automation/automation-auto-question/auto-questions.store'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  preserveWhitespaces: true,
})
export class AppComponent implements OnInit {
  theme: string = 'default'
  features: any[] = []
  settings = false

  _environment = environment
  constructor(
    private faviconService: FaviconSwitchingService, //  ! This is needed to ensure we switch the favicon when we need to
    private notificationsService: E11NotificationsService,
    private translateService: TranslateService,
    private configService: ConfigService,
    private conversationStore: ConversationStore,
    private messageStore: ConversationMessageStore,
    private jobItemStore: JobProfileStore,
    private notificationStore: NotificationStore,
    private permissionStore: PermissionStore,
    private jobApplicationStore: JobApplicationStore,
    private candidateListStore: CandidateListStore,
    private configStore: ConfigStore,
    private employerStore: EmployerStore,
    private jobPostAutomationStore: JobPostAutomationStore,
    private autoQuestionStore: AutoQuestionStore,
    private store: Store,
    private scrollLockService: UiScrollLockingService,
    private renderer: Renderer2,
    private logger: E11Logger,
    private jobsPostAutomationCompanyStore: JobsPostAutomationCompanySettingsStore
  ) {
    translateService.setDefaultLang('en')
    translateService.currentLoader = new WebpackTranslateLoader()
    this.theme = configService.config.theme
    this.features = configService.config.featureFlags
  }

  ngOnInit(): void {
    hookToGlobalState(this.conversationStore.getState, 'messaging-conversation', this.store)
    hookToGlobalState(this.messageStore.getState, 'messaging-message', this.store)

    hookToGlobalState(this.notificationStore.getState, 'notification', this.store)
    hookToGlobalState(this.permissionStore.getState, 'permissions', this.store)
    hookToGlobalState(this.jobApplicationStore.getState, 'job-application', this.store)
    hookToGlobalState(this.candidateListStore.getState, 'candidate-list', this.store)
    hookToGlobalState(this.configStore.getState, 'config', this.store)
    hookToGlobalState(this.employerStore.getState, 'employer', this.store)
    hookToGlobalState(this.jobPostAutomationStore.getState, 'job-automation', this.store)
    hookToGlobalState(this.jobsPostAutomationCompanyStore.getState, 'job-automation-company-settings', this.store)
    hookToGlobalState(this.autoQuestionStore.getState, 'job-automation-auto-questions', this.store)

    // Note: use firebase feature flags
    this.settings = this.isFeatureFlagEnabled('settings')

    if (!isElectron()) {
      this.logger.log(isElectron(), 'isElectron')
      // this.addZendDeskDOM()
    }
  }

  addZendDeskDOM() {
    const script = this.renderer.createElement('script')
    script.id = 'ze-snippet'
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=2f291b48-0dc6-46e9-8a6b-113c45846593'
    script.onload = () => {}
    this.renderer.appendChild(document.head, script)
  }

  cleanupZendDeskDOM() {
    const zendDeskElement = document.getElementById('launcher')
    if (zendDeskElement && zendDeskElement.parentElement) {
      zendDeskElement.parentElement.removeChild(zendDeskElement)
    }
  }
  isFeatureFlagEnabled(flag: any) {
    return this.features && this.features[flag]
  }

  sideOverClose() {
    this.scrollLockService.scrollLock(false)
  }
}

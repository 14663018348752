<footer class="e11-border-t e11-border-skin-app-borders e11-pt-4" aria-labelledby="footer-heading" id="appFooter">
  <div class="e11-container-full e11-mx-auto e11-flex e11-justify-end e11-mb-4">
    <div class="e11-flex e11-grow e11-justify-end e11-items-center">
      <a class="e11-text-sm e11-cursor-pointer" [routerLink]="['privacy']">
        {{ 'Privacy Policy' | translate }}
      </a>
      <a class="e11-text-sm e11-cursor-pointer e11-ml-4" [routerLink]="['terms']">
        {{ 'Terms of Use' | translate }}
      </a>
    </div>
  </div>
  <div class="e11-container-full e11-mx-auto e11-flex e11-justify-end">
    <app-copyright></app-copyright>
  </div>
</footer>

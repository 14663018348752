export const JOBS_AUTOMATION_MULTIMEDIA_CONFIG_OPTIONS = {
  maxFileSize: 300, // 300 MB
  maxNumberOfFiles: 8,
  maxCharacterLimit: 2000,
}

export const JOBS_AUTOMATION_MARKDOWN_CONFIG_OPTIONS = {
  footer: {
    action: [{ visible: false }],
    social: [{ visible: false }],
    audio: [
      { type: 'video', icon: 'videocam', visible: true },
      { visible: false, type: 'voice' },
      { visible: false, type: 'audio' },
    ],
  },
}

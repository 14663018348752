<e11-panel>
  <form class="" [formGroup]="formHeroSection" (ngSubmit)="onSubmitHero()" novalidate>
    <div class="">
      <div class="e11-flex e11-w-full e11-items-center e11-mb-2">
        <div>
          <h4 class="e11-font-primary-bold">{{ "Careers Page 'Hero Section'" | translate }}</h4>
        </div>
        <div class="e11-flex e11-grow e11-justify-end">
          <e11-button
            id="submit_preview"
            value="{{ 'Careers Page' | translate }}"
            color="primary-accent"
            [ghost]="true"
            [size]="'sm'"
            [icon]="'open_in_new'"
            [iconPosition]="'left'"
            [iconSize]="'md-14'"
            (click)="preview()"
          >
          </e11-button>
        </div>
      </div>

      <p class="e11-mb-2">
        {{
          "Make your Careers Page more attractive and engaging with a big bold background image, some clever text and a splash of color. Aside from the general content of your Careers Page you have the 'Hero Section' at the top of the page that you can customize here. Preview your changes here then save them when you are done."
            | translate
        }}
      </p>
    </div>
    <div class="e11-flex e11-flex-col e11-gap-0 e11-mb-4">
      <p class="e11-flex e11-text-sm e11-gap-1 e11-mb-0">
        <span class="material-icons-outlined e11-text-sm e11-text-skin-success">lightbulb</span>
        {{
          'Tip: Keep the Headline Text short and concise. It is a very large font. We limit it to just 30 characters so it cannot get too long and cause issues. Also we suggest trying to keep the Supporting Copy around 200 characters. Too much text will not be read potentially look bad.'
            | translate
        }}
      </p>
    </div>

    <!-- Preview Area -->
    <fieldset class="e11-border e11-border-solid e11-border-gray-300 e11-p-3 e11-mb-6">
      <legend>Preview</legend>

      <div class="e11-relative e11-justify-center md:e11-justify-start e11-flex e11-flex-row e11-flex-wrap e11-gap-2">
        <div
          class="e11-relative e11-overflow-hidden e11-flex e11-flex-col e11-justify-center e11-pl-6 e11-w-full e11-h-48 e11-bg-gray-50 e11-border-2"
          [ngClass]="{ 'e11-border-skin-primary-accent': formHero?.headerImage?.value === '' }"
        >
          <span class="e11-relative e11-z-10 e11-flex e11-w-fit">
            <span class="e11-absolute e11-inset-0 e11-z-1 e11-opacity-80 e11-rounded-md" style="background: {{ this.themePreview.primary }};"></span>
            <span
              class="e11-relative e11-z-10 e11-text-4xl e11-font-extrabold sm:e11-text-5xl lg:e11-text-6xl e11-px-4 e11-py-1"
              style="color: {{ themePreview?.text }}"
              [ngClass]="{ 'skeleton-off': !formHero.title.value }"
              >{{ formHero.title.value }}</span
            >
          </span>

          <span class="e11-relative e11-z-10 e11-flex e11-w-fit e11-mt-2">
            <span class="e11-absolute e11-inset-0 e11-z-1 e11-opacity-80 e11-rounded-md" style="background: {{ this.themePreview.primary }};"></span>
            <span
              class="e11-relative e11-z-10 e11-px-4 e11-py-1 e11-text-xl"
              style="color: {{ themePreview?.text }}"
              [ngClass]="{ 'skeleton-off': !formHero.description.value }"
              >{{ formHero.description.value }}</span
            >
          </span>

          <img
            *ngIf="formHero?.headerImage?.value !== ''"
            [src]="formHero?.headerImage?.value"
            [alt]="'hero images'"
            [title]="'hero images'"
            class="e11-absolute e11-z-0 e11-left-0 e11-object-cover e11-w-full"
          />
        </div>
      </div>

      <div class="e11-flex e11-items-center e11-justify-end e11-mb-6">
        <a
          class="e11-rounded-md e11-opacity-80 hover:e11-opacity-100 md:e11-max-w-[200px] e11-flex e11-px-4 e11-py-4 e11-border e11-border-transparent e11-text-base e11-font-medium md:e11-py-4 md:e11-px-10 hover:e11-shadow-md"
          style="color: {{ this.themePreview?.buttonText }}; background: {{ this.themePreview?.secondary }};"
        >
          Sample Button
        </a>
      </div>
    </fieldset>

    <!-- Tabs -->
    <div class="e11-grid e11-grid-cols-3">
      <div class="">
        <span
          class="e11-flex e11-items-center e11-justify-center e11-pb-2 e11-border-b-2 e11-cursor-pointer"
          [ngClass]="{ 'e11-text-skin-primary-accent e11-border-skin-secondary': sectionCurrent === 0 }"
          (click)="sectionCurrent = 0"
        >
          <span class="material-icons-outlined e11-mr-2"> image </span>
          <h4 class="e11-mb-0 e11-font-primary-bold">{{ (isMobile$ | async) ? 'Image' : ('Background Image' | translate) }}</h4>
        </span>
      </div>
      <div class="">
        <span
          class="e11-flex e11-items-center e11-justify-center e11-pb-2 e11-border-b-2 e11-cursor-pointer"
          [ngClass]="{ 'e11-text-skin-primary-accent e11-border-skin-secondary': sectionCurrent === 1 }"
          (click)="sectionCurrent = 1"
        >
          <span class="material-icons-outlined e11-mr-2"> notes </span>
          <h4 class="e11-mb-0 e11-font-primary-bold">{{ 'Text' | translate }}</h4>
        </span>
      </div>
      <div class="">
        <span
          class="e11-flex e11-items-center e11-justify-center e11-pb-2 e11-border-b-2 e11-cursor-pointer"
          [ngClass]="{ 'e11-text-skin-primary-accent e11-border-skin-secondary': sectionCurrent === 2 }"
          (click)="sectionCurrent = 2"
        >
          <span class="material-icons-outlined e11-mr-2"> palette </span>
          <h4 class="e11-mb-0 e11-font-primary-bold">{{ 'Colors' | translate }}</h4>
        </span>
      </div>
    </div>
    <div class="e11-p-4 e11-mb-4 e11-bg-skin-primary-accent/20">
      <p class="e11-mb-0" *ngIf="sectionCurrent === 0">
        {{ "Choose from one of the images below to set your career page's background image." | translate }}
      </p>
      <p class="e11-mb-0" *ngIf="sectionCurrent === 1">{{ 'Preview your Headline and Supporting Copy in the area above.' | translate }}</p>
      <p class="e11-mb-0" *ngIf="sectionCurrent === 2">{{ 'Pick a color palette from the options below.' | translate }}</p>
    </div>

    <!-- Image Selected -->
    <div *ngIf="sectionCurrent === 0" class="e11-mb-6">
      <div
        (click)="setHeaderImage('')"
        class="e11-relative e11-w-full e11-flex e11-items-center e11-justify-center e11-h-16 e11-cursor-pointer e11-bg-skin-grey/20 e11-border e11-border-skin-grey/50 e11-mb-4"
        title="{{ 'Click to choose to have no background image.' | translate }}"
        [ngClass]="{
          'e11-border-skin-primary-accent': formHero?.headerImage?.value === ''
        }"
      >
        <span
          *ngIf="formHero?.headerImage?.value === ''"
          class="e11-flex e11-items-center e11-absolute e11--top-2 e11--right-2 e11-rounded-full e11-bg-skin-primary-accent e11-text-xs e11-text-skin-white e11-py-1 e11-pl-2 e11-pr-4"
        >
          <span class="material-icons-outlined md-14 e11-text-skin-white e11-p-1">check</span>
          {{ 'Selected' | translate }}
        </span>
        {{ 'No Background Image' | translate }}
      </div>
      <div class="e11-grid e11-grid-cols-3 e11-gap-4">
        <div
          *ngFor="let image of heroSectionImages"
          class="e11-relative e11-h-24 e11-bg-grey/50 e11-border-2 e11-cursor-pointer"
          [ngClass]="{
            'e11-border-skin-primary-accent': formHero?.headerImage?.value === image,
            'hover:e11-border-skin-secondary': formHero?.headerImage?.value !== image
          }"
          (click)="setHeaderImage(image)"
          title="{{ 'Click to select this background image.' | translate }}"
        >
          <img
            [src]="image"
            [alt]="'hero images'"
            [title]="'hero images'"
            class="e11-h-[92px] e11-object-cover e11-object-center e11-w-full e11-z-0"
          />
          <span
            *ngIf="formHero?.headerImage?.value === image"
            class="e11-flex e11-items-center e11-absolute e11--top-2 e11--right-2 e11-rounded-full e11-bg-skin-primary-accent e11-text-xs e11-text-skin-white e11-py-1 e11-pl-2 e11-pr-4"
          >
            <span class="material-icons-outlined md-14 e11-text-skin-white e11-p-1">check</span>
            {{ 'Selected' | translate }}
          </span>
          <e11-button
            *ngIf="image === employer?.customHeaderImageURL"
            size="sm"
            value="Delete"
            icon="delete"
            iconSize="md-18"
            iconPosition="left"
            color="danger"
            [loading]="processingCustomImage"
            (click)="removeCustomHeaderImage(); $event.stopPropagation()"
            title="Delete custom image"
            containerClassOverrides="e11-flex e11-items-center e11-absolute e11--bottom-2 md:e11-bottom-auto md:e11--top-2 e11--left-2 e11-rounded-full e11-bg-skin-danger/80 e11-text-xs e11-text-skin-white e11-py-1 e11-pl-2 e11-pr-4 hover:e11-bg-skin-danger"
          >
            <span class="material-icons-outlined md-14 e11-text-skin-white e11-p-1">delete</span>
            {{ 'Delete' | translate }}
          </e11-button>
        </div>
        <div
          *ngIf="!employer?.customHeaderImageURL"
          (click)="!processingCustomImage && photoInput.click()"
          class="e11-col-span-full md:e11-col-span-1 e11-flex e11-flex-col e11-items-center e11-justify-center e11-text-center e11-overflow-hidden e11-h-24 e11-bg-skin-grey/20 e11-border-2 e11-border-dashed e11-border-skin-grey/50 hover:e11-bg-skin-grey/30 hover:e11-border-skin-secondary"
          [ngClass]="{ 'e11-cursor-wait': processingCustomImage, 'e11-cursor-pointer': !processingCustomImage }"
          title="{{ (processingCustomImage ? 'Uploading image...please wait.' : 'Click to upload custom background image.') | translate }}"
        >
          <input #photoInput class="e11-hidden" type="file" multiple accept=".png, .jpg, .jpeg" (change)="uploadCustomHeaderImage($event)" />

          <ng-container *ngIf="!processingCustomImage">
            <p class="e11-mb-1">{{ 'Click here to upload a custom background image' | translate }}</p>
            <p class="e11-text-sm e11-text-gray-600 e11-mb-0">
              {{ 'Uploaded image must be at least ' + minHeaderImageWidth + 'x' + minHeaderImageHeight + 'px' | translate }}
            </p>
          </ng-container>
          <e11-loader-dots
            *ngIf="processingCustomImage"
            [size]="'sm'"
            [color]="'e11-bg-skin-primary-accent'"
            label="{{ '' | translate }}"
          ></e11-loader-dots>
        </div>
      </div>
    </div>

    <!-- Copy -->
    <div *ngIf="sectionCurrent === 1" class="e11-mb-6">
      <div class="e11-mb-2">
        <e11-input
          [type]="'text'"
          [label]="'Headline Text'"
          name="title"
          id="id_url"
          [size]="'lg'"
          autocomplete="off"
          formControlName="title"
          [maxLength]="maxCharTitle"
          [enforceCharacterLimit]="true"
          [parentForm]="formHeroSection!"
          [required]="false"
        >
          <div class="e11-w-full e11-flex e11-items-center">
            <div class="e11-grow">
              <e11-input-errors [parentForm]="formHeroSection" [formControl]="formHero.title" label="{{ 'Headline Text' | translate }}">
              </e11-input-errors>
            </div>
            <div class="e11-flex e11-items-center e11-justify-end">
              <span class="e11-text-right e11-text-xs">{{ formHero.title.value.length }} / {{ maxCharTitle }}</span>
            </div>
          </div>
        </e11-input>
      </div>

      <div>
        <e11-textarea
          label="{{ 'Supporting Copy (maximum 500 characters)' | translate }}"
          labelColor="primary"
          id="textarea_description"
          [parentForm]="formHeroSection!"
          formControlName="description"
          [maxLength]="maxCharDescription"
          [enforceCharacterLimit]="true"
        >
          <div class="e11-w-full e11-flex e11-items-center">
            <div class="e11-grow">
              <e11-input-errors
                [parentForm]="formHeroSection"
                [formControl]="formHero.description"
                label="{{ 'Description' | translate }}"
              ></e11-input-errors>
            </div>
            <div class="e11-flex e11-items-center e11-justify-end">
              <span class="e11-text-right e11-text-xs">{{ formHero.description.value.length }} / {{ maxCharDescription }}</span>
            </div>
          </div>
        </e11-textarea>
      </div>
    </div>

    <!-- Colors -->
    <div *ngIf="sectionCurrent === 2" class="e11-mb-6">
      <div class="e11-w-full e11-grid e11-grid-cols-1 md:e11-grid-cols-2 lg:e11-grid-cols-3 xl:e11-grid-cols-4 e11-gap-4 e11-mb-8">
        <fieldset
          class="e11-border-2 e11-rounded-md e11-border-solid e11-px-4 e11-pb-4 e11-cursor-pointer hover:e11-bg-skin-primary-accent/10"
          [ngClass]="{
            'e11-border-skin-primary-accent e11-bg-skin-primary-accent/10': ITheme.Custom === activeTheme,
            'e11-border-skin-app-borders': ITheme.Custom !== activeTheme
          }"
          (click)="selectPreviewTheme(ITheme.Custom)"
        >
          <legend
            class="e11-p-2"
            [ngClass]="{
              'e11-text-skin-primary-accent': ITheme.Custom === activeTheme
            }"
          >
            {{ 'Configure your own colors' | translate }}
          </legend>
          <form [formGroup]="customThemeForm">
            <div class="e11-flex e11-items-center e11-mb-2 e11-justify-between">
              <span class="e11-text-sm e11-w-1/2 e11-truncate"> {{ 'Primary color' | translate }}</span>
              <div class="e11-w-1/2">
                <e11-input
                  inputClass="!e11-min-h-0 e11-h-8 e11-cursor-pointer"
                  type="color"
                  [parentForm]="customThemeForm"
                  formControlName="primary"
                ></e11-input>
              </div>
            </div>
            <div class="e11-flex e11-items-center e11-mb-2 e11-justify-between">
              <span class="e11-text-sm e11-w-1/2 e11-truncate"> {{ 'Text color' | translate }} </span>
              <div class="e11-w-1/2">
                <e11-input
                  inputClass="!e11-min-h-0 e11-h-8 e11-cursor-pointer"
                  type="color"
                  [parentForm]="customThemeForm"
                  formControlName="text"
                ></e11-input>
              </div>
            </div>
            <div class="e11-flex e11-items-center e11-mb-2 e11-justify-between">
              <span class="e11-text-sm e11-w-1/2 e11-truncate"> {{ 'Button color' | translate }} </span>
              <div class="e11-w-1/2">
                <e11-input
                  inputClass="!e11-min-h-0 e11-h-8 e11-cursor-pointer"
                  type="color"
                  [parentForm]="customThemeForm"
                  formControlName="secondary"
                ></e11-input>
              </div>
            </div>
            <div class="e11-flex e11-items-center e11-mb-2 e11-justify-between">
              <span class="e11-text-sm e11-w-1/2 e11-truncate"> {{ 'Button text color' | translate }} </span>
              <div class="e11-w-1/2">
                <e11-input
                  inputClass="!e11-min-h-0 e11-h-8 e11-cursor-pointer"
                  type="color"
                  [parentForm]="customThemeForm"
                  formControlName="buttonText"
                ></e11-input>
              </div>
            </div>
          </form>
        </fieldset>
        <fieldset
          *ngFor="let item of themesAvailable; let i = index"
          class="e11-border-2 e11-rounded-md e11-border-solid e11-px-4 e11-pb-4 e11-cursor-pointer hover:e11-bg-skin-primary-accent/10"
          [ngClass]="{
            'e11-border-skin-primary-accent e11-bg-skin-primary-accent/10': item.name === activeTheme,
            'e11-border-skin-app-borders': item.name !== activeTheme
          }"
          (click)="selectPreviewTheme(item.name)"
        >
          <legend
            class="e11-p-2"
            [ngClass]="{
              'e11-text-skin-primary-accent': item.name === activeTheme
            }"
          >
            {{ item.name }}
          </legend>
          <div class="e11-flex e11-items-center e11-mb-2 e11-justify-between">
            <span class="e11-text-sm e11-w-1/2 e11-truncate"> {{ 'Primary color' | translate }}</span>
            <div
              class="e11-h-8 e11-w-1/2 e11-border e11-border-solid e11-border-black"
              [ngStyle]="{
                'background-color': item.primary
              }"
            ></div>
          </div>
          <div class="e11-flex e11-items-center e11-mb-2 e11-justify-between">
            <span class="e11-text-sm e11-w-1/2 e11-truncate"> {{ 'Text color' | translate }} </span>
            <div
              class="e11-h-8 e11-w-1/2 e11-border e11-border-solid e11-border-black"
              [ngStyle]="{
                'background-color': item.text
              }"
            ></div>
          </div>
          <div class="e11-flex e11-items-center e11-mb-2 e11-justify-between">
            <span class="e11-text-sm e11-w-1/2 e11-truncate"> {{ 'Button color' | translate }} </span>
            <div
              class="e11-h-8 e11-w-1/2 e11-border e11-border-solid e11-border-black"
              [ngStyle]="{
                'background-color': item.secondary
              }"
            ></div>
          </div>
          <div class="e11-flex e11-items-center e11-mb-2 e11-justify-between">
            <span class="e11-text-sm e11-w-1/2 e11-truncate"> {{ 'Button text color' | translate }} </span>
            <div
              class="e11-h-8 e11-w-1/2 e11-border e11-border-solid e11-border-black"
              [ngStyle]="{
                'background-color': item.buttonText
              }"
            ></div>
          </div>
        </fieldset>
      </div>
    </div>

    <div class="e11-flex e11-justify-end">
      <e11-button id="submit_about" value="{{ 'Save' | translate }}" color="primary-accent" [type]="'submit'"></e11-button>
    </div>
  </form>
</e11-panel>

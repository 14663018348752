import { IAutomationHelperGroupItem } from '@employer/app/components/jobs-automation/partials/automation-helper-group.component'

export const WHAT_TO_DO: IAutomationHelperGroupItem[] = [
  {
    icon: 'schedule',
    title: 'Decide When to Ask',
    color: 'primary-accent',
  },
  {
    icon: 'edit',
    title: 'Select or Write Questions',
    color: 'secondary',
  },
  {
    icon: 'settings_suggest',
    title: 'Configure Your Messaging',
    color: 'primary-accent',
  },
]

export const WHAT_HAPPENS: IAutomationHelperGroupItem[] = [
  {
    icon: 'insert_drive_file',
    title: 'Application is processed',
    description: 'The application is flagged to send out questions associated with it.',
    color: 'primary-accent',
  },
  {
    icon: 'message',
    title: 'Questions Sent to Applicant',
    description: 'The candidate is contacted with the questions configured to be sent to them.',
    color: 'secondary',
  },
  {
    icon: 'add_task',
    title: 'Application Updated',
    description: 'Your questions are ready to be reviewed helping you quickly and efficiently vet your candidates.',
    color: 'success',
  },
]

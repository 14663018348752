import { ISelectOption } from '@engineering11/ui-lib/e11-select'
import { DispositionCodes, dispositionCodeToLabel, RejectionReasons } from 'shared-lib'
import { IBaseAutomationFormData, ISendMessageFormData } from '../job-automation.model'

export interface IRejectCandidateFormData extends Pick<ISendMessageFormData, 'delayDuration' | 'delayUnits'>, IBaseAutomationFormData {
  rejectionReason: RejectionReasons
}

export const rejectCandidateDefaults: IRejectCandidateFormData = {
  enabled: false,
  delayDuration: 10,
  delayUnits: 'days',
  rejectionReason: RejectionReasons.RejectedForUnresponsiveness,
}

export const rejectionReasonSelectOptions: ISelectOption<RejectionReasons>[] = [
  { name: dispositionCodeToLabel.RejectedForUnresponsiveness, value: RejectionReasons.RejectedForUnresponsiveness },
]

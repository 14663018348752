import { Component } from '@angular/core'

@Component({
  template: `
    <e11-panel>
      <div class="e11-w-full e11-flex e11-mb-6 e11-items-start">
        <div class="e11-grow">
          <p class="e11-font-bold e11-mb-0">{{ 'Manage the locations within your company' | translate }}</p>
          <p class="e11-mb-0 e11-text-sm">{{ 'These are used to assign specific locations to job posts.' | translate }}</p>
        </div>
        <div class="e11-flex e11-justify-end">
          <e11-button
            id="submit_account"
            value="{{ 'New Location' | translate }}"
            [icon]="'add'"
            [iconPosition]="'left'"
            [iconSize]="'md-14'"
            [size]="'sm'"
            [color]="'primary-accent'"
            [type]="'button'"
            (click)="locations.addClicked()"
          >
          </e11-button>
        </div>
      </div>

      <div class="e11-mb-6">
        <sdk-customer-locations
          #locations
          [locationActiveInfo]="locationActiveInfo"
          [suppressHeader]="true"
          [showSearch]="true"
        ></sdk-customer-locations>
      </div>
    </e11-panel>
  `,
})
export class CustomerLocationsComponent {
  locationActiveInfo =
    "The status here allows this 'Location' to be available for Job and Job Template Listings. (as well as in other places). If you turn it off it will not show as a selection in screens that let you choose a location"

  constructor() {}
}

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'

import { IAutomationFormConfig, systemSenderOption } from '@employer/app/components/jobs-automation/automation-form-message'
import { IAutomationFormData, automationFormDefaults } from '@employer/app/components/jobs-automation/automation-form.model'
import { JobAutomationPermissions } from 'shared-lib'
import { EmployerStore } from '@employer/app/modules/customer/employer.store'
import { PermissionStore } from '@engineering11/access-web'
import { E11Logger } from '@engineering11/web-api-error'
import { Observable, map } from 'rxjs'
import { IAutomatedQuestionMessageFormData, automatedQuestionMessageFormDefaults } from './auto-question-message.model'
import { JobsPostAutomationCompanySettingsStore } from './jobs-post-automation-company-settings.store'

@Component({
  selector: 'app-automation-settings',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <e11-panel *ngrxLet="employer$ as employer">
      <job-automation-form
        *ngrxLet="formConfig$ as formConfig"
        [formData]="(formData$ | async) ?? automationFormDefaults | deepCopy"
        [formConfig]="formConfig"
        [saving]="(saving$ | async) ?? false"
        [loading]="(loading$ | async) ?? false"
        (newFormData)="saveAutomation($event)"
      ></job-automation-form>

      <automation-auto-question-form
        *ngrxLet="formConfig$ as formConfig"
        [loading]="(loading$ | async) ?? false"
        [formConfig]="formConfig"
        [formData]="(formData$ | async) ?? automationFormDefaults | deepCopy"
        (valueChanges)="automatedQuestionMessages = $event"
      ></automation-auto-question-form>
    </e11-panel>
  `,
  styles: [],
})
export class JobsPostAutomationCompanySettingsComponent implements OnInit {
  employer$ = this.employerStore.employer$

  automationFormDefaults = { ...automationFormDefaults, automatedQuestionMessage: automatedQuestionMessageFormDefaults }

  saving$ = this.jobsPostAutomationCompanySettingsStore.saving$
  loading$ = this.jobsPostAutomationCompanySettingsStore.loaded$.pipe(map(l => !l))

  formData$ = this.jobsPostAutomationCompanySettingsStore.formData$

  canEditMessages$ = this.permissionStore.hasPermission(JobAutomationPermissions.CustomizeMessage)

  formConfig$: Observable<IAutomationFormConfig> = this.canEditMessages$.pipe(
    map(canEditMessages => ({
      senderOptions: [systemSenderOption],
      canEditMessages,
    }))
  )
  automatedQuestionMessages: IAutomatedQuestionMessageFormData = automatedQuestionMessageFormDefaults

  constructor(
    private permissionStore: PermissionStore,
    private employerStore: EmployerStore,
    private jobsPostAutomationCompanySettingsStore: JobsPostAutomationCompanySettingsStore,
    private logger: E11Logger
  ) {}

  ngOnInit() {
    this.jobsPostAutomationCompanySettingsStore.onGetAutomation()
  }

  saveAutomation(formData: IAutomationFormData) {
    this.logger.log('JobAutomationComponent.saveAutomation', formData)
    formData = { ...formData, autoQuestions: { ...formData.autoQuestions, defaultMessages: this.automatedQuestionMessages } }
    this.jobsPostAutomationCompanySettingsStore.onSaveAutomation(formData)
  }
}

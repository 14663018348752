import { inject } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router'
import { Observable, firstValueFrom } from 'rxjs'
import { EmployerStore } from './employer.store'
import { E11Logger } from '@engineering11/web-api-error'

export async function employerGuard(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
  const employerStore = inject(EmployerStore)
  const logger = inject(E11Logger)

  const loaded = await firstValueFrom(employerStore.employerLoaded$)
  if (loaded) return true
  logger.log('employerGuard, employer not loaded, onGetEmployer')

  employerStore.onGetEmployer()
  return true
}

import { Injectable } from '@angular/core'
import { JobPostRepository } from '@employer/app/repositories/job-post.repository'

@Injectable({ providedIn: 'root' })
export class JobPostService {
  constructor(private repository: JobPostRepository) {}
  get(id: string) {
    return this.repository.get(id)
  }

  getOrError(id: string) {
    return this.repository.getOrError(id)
  }

  getValueChanges(id: string) {
    return this.repository.getValueChanges(id)
  }
}

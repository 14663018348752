import { NgForOf } from '@angular/common'
import { Component, TemplateRef, ViewChild } from '@angular/core'
import {
  AutomationHelperGroupComponent,
  IAutomationHelperGroupItem,
} from '@employer/app/components/jobs-automation/partials/automation-helper-group.component'
import { AutomationHelperIconComponent } from '@employer/app/components/jobs-automation/partials/automation-helper-icon.component'
import { E11CardComponent } from '@engineering11/ui-lib/e11-card'
import { E11ModalGlobalService } from '@engineering11/ui-lib/e11-modal'
import { TranslateModule } from '@ngx-translate/core'

@Component({
  selector: 'job-automation-auto-response-helper',
  standalone: true,
  imports: [TranslateModule, E11CardComponent, NgForOf, AutomationHelperIconComponent, AutomationHelperGroupComponent],
  template: `
    <span (click)="openModal()" class="e11-text-skin-primary-accent e11-cursor-pointer hover:e11-text-skin-primary">{{ 'How this works' }}</span>
    <ng-template #content>
      <div class="e11-text-skin-primary-accent e11-flex e11-gap-2 e11-items-center e11-mb-4">
        <span class="material-icons md-24">auto_mode</span>
        <h3>{{ 'Automatically greet your applicants and get them engaged' | translate }}</h3>
      </div>
      <p>
        {{
          'The Application Auto Response part of our “Automation” feature lets you configure each Job Post to automatically respond to every application. It’s very simple to use and extremely useful in gauging candidate engagement as well as encouraging more communication. '
            | translate
        }}
      </p>
      <div class="e11-mb-4">
        <automation-helper-group [groupItems]="needToKnow" />
      </div>
    </ng-template>
  `,
  styles: ``,
})
export class AutomationAutoResponseHelperComponent {
  @ViewChild('content') content!: TemplateRef<any>
  needToKnow: IAutomationHelperGroupItem[] = [
    {
      icon: 'edit',
      title: 'Write Your Message',
      color: 'primary-accent',
      description:
        'Use our default messaging or write your own custom welcome message. This message will be sent to everyone that applies to a job post.',
    },
    {
      icon: 'schedule',
      title: 'Pick A Time To Send',
      color: 'secondary',
      description: 'Pick a time for your message to be sent to the candidate after the candidate submits their application.',
    },
    {
      icon: 'chat',
      title: 'Applicants Are Engaged',
      color: 'primary-accent',
      description: 'Use this feature to welcome candidates and gauge their interest in the job as well as your company.',
    },
  ]

  constructor(private modalService: E11ModalGlobalService) {}

  openModal() {
    this.modalService.openModal({
      body: this.content,
      title: 'Automation Configuration - Application Auto Response',
      width: 'xl',
    })
  }
}

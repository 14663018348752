import { Component, EventEmitter, Input, Output } from '@angular/core'
import { E11NotificationMessage, E11NotificationsService } from '@engineering11/ui-lib/e11-notifications'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { tap } from 'rxjs/operators'
import { JobApplicationReviewStore } from '@employer/app/modules/jobs/stores/job-application-review.store'
import { E11ProfilePicModule } from '@engineering11/ui-lib/e11-profile-pic'
import { LetDirective } from '@ngrx/component'
import { E11ButtonComponent } from '@engineering11/ui-lib/e11-button'
import { DatePipe, NgClass, NgForOf, NgIf } from '@angular/common'
import { UiCandidateStarsComponent } from '@employer/app/components/algolia/ui-alg-card/ui-candidate-stars.component'
import { WebUtilityModule } from '@engineering11/web-utilities'
import { StarRatingFormComponent } from '@employer/app/modules/jobs/components/candidate-rating-mini/star-rating-form.component'

@Component({
  selector: 'candidate-rating-mini',
  standalone: true,
  templateUrl: './candidate-rating-mini.component.html',
  styleUrls: ['./candidate-rating-mini.component.scss'],
  imports: [
    TranslateModule,
    E11ProfilePicModule,
    LetDirective,
    E11ButtonComponent,
    NgClass,
    UiCandidateStarsComponent,
    NgIf,
    NgForOf,
    WebUtilityModule,
    DatePipe,
    StarRatingFormComponent,
  ],
})
export class CandidateRatingMiniComponent {
  @Input() readOnly = false
  @Output() userRated: EventEmitter<boolean> = new EventEmitter<boolean>()
  @Output() emitClose = new EventEmitter()

  showControls: boolean = false

  currentUserReview$ = this.jobApplicationReviewStore.currentUserReview$.pipe(tap(review => (this.selectedRating = review?.starRating ?? 0)))
  reviews$ = this.jobApplicationReviewStore.reviews$
  selectedRating: number = 0

  constructor(
    private notificationsService: E11NotificationsService,
    private translate: TranslateService,
    private jobApplicationReviewStore: JobApplicationReviewStore
  ) {}

  rate(value: number) {
    if (this.readOnly) {
      return
    }
    this.selectedRating = value
  }

  onSubmitRating() {
    this.jobApplicationReviewStore.onAddApplicationReview({ starRating: this.selectedRating })

    let title = '',
      message = ''
    this.translate.get('Nicely done!').subscribe((res: string) => {
      title = res
    })
    this.translate.get('Your review of this candidate has been saved. It may take a minute to update.').subscribe((res: string) => {
      message = res
    })
    this.notificationsService.popNotificationMessage(new E11NotificationMessage(title, message, 'success', true, ''))
    this.showRatingControls(false)
  }

  showRatingControls(value: boolean) {
    this.showControls = value
  }

  clearRating(userRating: number) {
    this.selectedRating = userRating
  }
}

import { Component, EventEmitter, Input, Output, inject } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { IEmployerUser } from '@cnect/user-shared'
import { ROUTES } from '@employer/app/services/navigation.service'
import { selectors } from '@employer/app/store/selectors'
import { AppConfigService } from '@engineering11/tenant-bootstrap-web'
import { isNotNil } from '@engineering11/utility'
import { ViewportService } from '@engineering11/web-utilities'
import { Store } from '@ngrx/store'
import { combineLatest, of } from 'rxjs'
import { Observable } from 'rxjs/internal/Observable'
import { filter, map, startWith, switchMap, tap } from 'rxjs/operators'

@Component({
  selector: 'app-header',
  template: `
    <header class="e11-bg-skin-white e11-border-b e11-px-6 e11-py-4 e11-sticky e11-z-[12]">
      <div class="app-header-content e11-flex e11-justify-between ">
        <div class="e11-flex e11-w-full">
          <ng-container *ngrxLet="backUrl$ as backUrl">
            <ng-container *ngrxLet="backText$ as backText">
              <ng-container *ngrxLet="isMobile$ as isMobile">
                <div class="e11-grow" [ngClass]="{ 'e11-flex e11-items-center': backUrl === '' || isMobile }">
                  <mobile-drawer-icon
                    [mobileDrawerState]="mobileDrawerState"
                    (mobileDrawerStateChange)="toggleMobileSideDrawer()"
                  ></mobile-drawer-icon>
                  <ng-container *ngrxLet="viewTitle$ as viewTitle">
                    <div>
                      <ng-container *ngrxLet="headerLogo$ as headerLogo">
                        <img
                          *ngIf="!isMobile && headerLogo"
                          alt="Cnect Logo"
                          src="/assets/images/maintenance-page/cnect-logo.png"
                          class=""
                          width="150"
                        />
                        <h2 *ngIf="!isMobile && !headerLogo" class="e11-text-skin-primary-accent e11-line-clamp-1">{{ viewTitle | translate }}</h2>
                        <h4 *ngIf="isMobile" class="e11-text-skin-primary-accent e11-line-clamp-1 e11-break-all e11-font-semibold">
                          {{ viewTitle | translate }}
                        </h4>

                        <a
                          class="e11-flex e11-items-center e11-text-skin-primary-accent hover:e11-text-skin-primary e11-cursor-pointer"
                          *ngIf="backUrl !== ''"
                          [routerLink]="backUrl"
                          alt="Back to previous page."
                        >
                          <span class="material-icons-round e11-mr-1 md-18">west</span>
                          {{ isMobile ? 'Back' : (backText | translate) }}
                        </a>
                      </ng-container>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
          <div class="e11-flex e11-items-center e11-justify-end">
            <div class="e11-flex e11-gap-6 e11-items-center e11-z-10">
              <ui-notification *ngIf="currentUser$ | async as currentUser" [darkTheme]="true"></ui-notification>
              <profile-dropdown *ngIf="currentUser$ | async as currentUser"></profile-dropdown>
            </div>
          </div>
        </div>
      </div>
    </header>
  `,
})
export class AppHeaderComponent {
  activatedRoute = inject(ActivatedRoute)
  currentUser$: Observable<IEmployerUser | null> = this.store.select(selectors.getCurrentUser)

  @Input() mobileDrawerState = false
  @Output() mobileDrawerStateChange: EventEmitter<boolean> = new EventEmitter<boolean>()
  isMobile$ = this.viewportService.viewportSizeChanged$.pipe(
    map(data => data == 'sm' || data == 'xs' || data == 'md'),
    filter(isNotNil),
    tap(isMobile => {
      if (!isMobile && this.mobileDrawerState) {
        this.toggleMobileSideDrawer()
      }
    })
  )
  logoUrl = this.appConfig.config.brand.logoImageDarkUrl
  routes = ROUTES

  combinedActivatedRoute$ = combineLatest([
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      startWith(this.router) // Handle the initial page load
    ),
    this.activatedRoute.root.firstChild!.params,
  ]).pipe(
    map(([_, params]) => {
      let route = this.activatedRoute.root
      while (route.firstChild) {
        route = route.firstChild
      }
      return route
    })
  )

  backUrl$ = this.combinedActivatedRoute$.pipe(
    switchMap(route => {
      const showBackOnHeader = route.snapshot.data['showBackOnHeader']
      if (!showBackOnHeader) return of('')
      if (route.snapshot.data['routeParams']) {
        return of(`${route.snapshot.data['backUrl']}/${route.snapshot.params['jobPostId']}`)
      } else {
        return of(`${route.snapshot.data['backUrl']}`)
      }
    })
  )

  backText$ = this.combinedActivatedRoute$.pipe(
    switchMap(route => {
      const showBackOnHeader = route.snapshot.data['showBackOnHeader']
      if (!showBackOnHeader) return of('')
      return of(`${route.snapshot.data['backText']}`)
    })
  )

  viewTitle$ = this.combinedActivatedRoute$.pipe(map(route => route.snapshot.data['title'] || ''))
  headerLogo$ = this.combinedActivatedRoute$.pipe(map(route => route.snapshot.data['headerLogo'] || ''))

  constructor(private viewportService: ViewportService, private appConfig: AppConfigService, private store: Store, private router: Router) {}

  toggleMobileSideDrawer() {
    this.mobileDrawerStateChange.emit(!this.mobileDrawerState)
  }
}

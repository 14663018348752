import { IEmployerUser } from '@cnect/user-shared'
import { IJobPostContentAssociation } from '@employer/app/models/job-post.model'
import { CONTENT_TYPE_JOB_PROFILE, IJobProfile, IJobProfileContentItem } from '@employer/app/models/job-profile.model'
import { environment } from '@employer/environments/environment'
import { CONTENT_ASSOCIATION, DEFAULT_LANGUAGE_CODE, IContentAssociation } from '@engineering11/content-web'
import { Optional } from '@engineering11/types'
import { APPLICATION_STATE, EmployeeLocation, JobProfileStatus } from 'shared-lib'

/**
 * @param user
 * @param swimlanes included to create a job post content associations. swimlane config should not be included for job profiles
 */
export const defaultAssociation = (user: IEmployerUser, swimlanes?: APPLICATION_STATE[]) => {
  const owner: Optional<IJobPostContentAssociation, 'swimlanes'> = {
    userId: user.id!,
    firstName: user.firstName,
    lastName: user.lastName,
    associationTypes: [CONTENT_ASSOCIATION.OWNER],
    swimlanes,
  }

  const associations = new Map<string, IContentAssociation>()
  associations.set(user.id!, owner)
  return associations
}
export const createNewJobProfile = (user: IEmployerUser, title: string): Optional<IJobProfileContentItem, 'contentId'> => {
  const associations = defaultAssociation(user)
  const document: IJobProfile = {
    employeeLocation: EmployeeLocation.Unspecified,
    status: JobProfileStatus.Draft,
    customerKey: user.customerKey,
    contentType: CONTENT_TYPE_JOB_PROFILE,
    languageCode: DEFAULT_LANGUAGE_CODE,
    title,
  }
  return { document, associations, customerKey: user.customerKey }
}

export function getExternalJobLink(jobLinkData: { jobPostId: string; customerKey: string }) {
  const { jobPostId, customerKey } = jobLinkData
  return `${environment.careersPageBaseLink}job/${jobPostId}?client=${customerKey}`
}

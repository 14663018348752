export interface IAutomationHelperModalData {
  icon: string
  title: string
  description: string
}

export const HEADER_INFO: IAutomationHelperModalData = {
  icon: 'auto_mode',
  title: 'Automatically mark your candidates as unresponsive',
  description: 'Automate the task of marking candidates who do not respond to requests as unresponsive.',
}
export const ALL_YOU_NEED: IAutomationHelperModalData = {
  icon: 'schedule',
  title: 'Select A Time-frame',
  description:
    'Select a reasonable time-frame in-which you would like your applicants to respond to your requests.\nFor example: If you would like your applicants to respond to a “Request For More” or “Interview Questions” within 5 days simply choose that time-frame and the system will flag them as unresponsive automatically.',
}

export const WHAT_HAPPENS: IAutomationHelperModalData = {
  icon: 'task_alt',
  title: 'Application Information Updated',
  description:
    'This really is that easy. If the candidate does not respond to your requests then the candidate will be marked as “Unresponsive”. You can manually override this by putting the candidate’s application back into a desired state but know that they could be marked as “Unresponsive” again if they do not respond in the set time-frame.',
}

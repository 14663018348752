<div class="e11-w-full">
  <e11-panel>
    <div class="e11-p-4">
      <h1 class="e11-w-full e11-text-center e11-text-skin-primary-accent e11-text-5xl e11-mb-8">404 - {{ 'Page not found' | translate }}</h1>
      <h3 class="e11-w-full e11-text-center">{{ "Well, ...there isn't any pretending that didn't just happen." | translate }}</h3>

      <div class="e11-p-4 e11-text-center">
        <h3 class="e11-mb-4">{{ 'The page you are looking for no longer exists, or you may not have permission to view it.' }}</h3>
        <p>{{ 'Click on the logo in the upper left to go Home.' | translate }}</p>
        <p>{{ 'Click on your name in the upper right for options...' | translate }}</p>
        <p>
          <a href="/" class="e11-text-skin-primary-accent">{{ 'Click here if all else fails.' | translate }}</a>
        </p>
      </div>
    </div>
  </e11-panel>
</div>

import { IAutomationHelperModalData } from '../automation-unresponsive-candidate/automated-unresponsive.constants'

export const HEADER_INFO: IAutomationHelperModalData = {
  icon: 'auto_mode',
  title: 'Automatically reject a candidate',
  description: 'Automate the task of rejecting a candidate who does not respond to important requests.',
}
export const ALL_YOU_NEED: IAutomationHelperModalData = {
  icon: 'schedule',
  title: 'Select A Time-frame',
  description:
    'Select a reasonable time-frame in-which you would like your applicants to respond to your requests.\nFor example: If you would like your applicants to respond to a “Request For More” or “Interview Questions” within 5 days simply choose that time-frame and the system will reject automatically.',
}

export const WHAT_HAPPENS: IAutomationHelperModalData = {
  icon: 'task_alt',
  title: 'Application Information Updated - Rejected',
  description:
    'If the candidate does not respond to your requests then the candidate will be rejected. This will send the candidate a notification that they have been rejected and no longer considered for the position in-which they applied. This cannot be undone.',
}

import { CommonModule } from '@angular/common'
import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { ReactiveFormsModule, Validators } from '@angular/forms'
import {
  IAutoQuestionAutomationFormData,
  IAutoQuestionFormData,
} from '@employer/app/components/jobs-automation/automation-auto-question/auto-questions.model'
import { AutomationAutoQuestionFormComponent } from '@employer/app/components/jobs-automation/automation-auto-question/automation-auto-question-form.component'
import { AutomationDelayInfoComponent } from '@employer/app/components/jobs-automation/partials/automation-delay-info.component'
import { AutomationReminderInfoComponent } from '@employer/app/components/jobs-automation/partials/automation-reminder-info.component'
import { AutomationFormData, AutomationResetComponent } from '@employer/app/components/jobs-automation/partials/automation-reset.component'
import { AutomationUpgradePromptComponent } from '@employer/app/components/jobs-automation/partials/automation-upgrade-prompt.component'

import { AutomationFormHeaderComponent } from '@employer/app/components/jobs-automation/partials/automation-form-header.component'
import { JobPostAutomationService } from '@employer/app/modules/jobs/views/job-automation/job-post-automation.service'
import { IFileReceipt } from '@engineering11/files-web'
import { E11ButtonModule } from '@engineering11/ui-lib/e11-button'
import { E11DividerModule } from '@engineering11/ui-lib/e11-divider'
import {
  E11DynamicFormBuilderComponent,
  E11DynamicFormModule,
  e11FieldInputMultiSelector,
  e11FieldInputSelect,
  e11FieldInputSwitch,
  e11FormFor,
  E11FormForType,
  e11FormLayoutFullWidth,
  e11LayoutGroup,
  IFormTemplateSpec,
} from '@engineering11/ui-lib/e11-form-builder'
import { E11InputLabelModule } from '@engineering11/ui-lib/e11-input-label'
import { IMarkdownFile } from '@engineering11/ui-lib/e11-markdown-editor'
import { E11NotificationsService } from '@engineering11/ui-lib/e11-notifications'
import { E11ShowHideControllerComponent, E11ShowHideWrapperComponent } from '@engineering11/ui-lib/e11-show-hide'
import { E11SwitchModule } from '@engineering11/ui-lib/e11-switch'
import { isNotNil } from '@engineering11/utility'
import { E11ErrorHandlerService, E11Logger, toE11Error } from '@engineering11/web-api-error'
import { TranslateModule } from '@ngx-translate/core'
import { uniqBy } from 'lodash'
import { APPLICATION_STATE, FormSelectService } from 'shared-lib'
import { IRequestMoreFormData, IRequestUpdatesFormData, requestedFieldOptions } from './auto-request-updates.model'
import { IApplicationAutoResponseFormData } from './auto-response.model'
import { AutomationCandidateRejectionFormComponent } from './automation-candidate-rejection/automation-candidate-rejection-form.component'
import { IRejectCandidateFormData, rejectCandidateDefaults } from './automation-candidate-rejection/candidate-rejection.model'
import { IAutomationFormConfig, MultiMediaArgs, sendMessageForm } from './automation-form-message'
import { automationFormDefaults, IAutomationFormData } from './automation-form.model'
import { AutomationCandidateUnresponsiveFormComponent } from './automation-unresponsive-candidate/automation-candidate-unresponsive-form.component'
import { IUnresponsiveCandidateFormData, unresponsiveCandidateDefaults } from './automation-unresponsive-candidate/unresponsive-candidate.model'
import { validateFile } from './automation.utils'
import { IMarkdownReceiptFile, ISendMessageFormData } from './job-automation.model'
import { AutomationAttachmentPreviewComponent } from './partials/automation-attachment-preview'
import { AutomationAutoResponseHelperComponent } from './partials/automation-auto-response-helper.component'
import { AutomationLoaderComponent } from './partials/automation-loader'
import { AutomationRequestedUpdateHelperComponent } from './partials/automation-requested-update-helper.component'

export interface IAutomationForms {
  autoResponse: E11FormForType<ISendMessageFormData>
  requestedUpdate: E11FormForType<IRequestUpdatesFormData>
  requestMoreInitial: E11FormForType<ISendMessageFormData>
  requestMoreFollowUp: E11FormForType<ISendMessageFormData>
  requestMoreWarning: E11FormForType<ISendMessageFormData>
}

type AutomationProperties = 'autoResponse' | 'initialMessage' | 'followUpMessage' | 'warningMessage'

@Component({
  selector: 'job-automation-form',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    E11DynamicFormModule,
    E11InputLabelModule,
    E11SwitchModule,
    TranslateModule,
    E11ButtonModule,
    E11DividerModule,
    E11ShowHideControllerComponent,
    E11ShowHideWrapperComponent,
    AutomationDelayInfoComponent,
    AutomationResetComponent,
    AutomationAttachmentPreviewComponent,
    AutomationReminderInfoComponent,
    AutomationUpgradePromptComponent,
    AutomationAutoQuestionFormComponent,
    AutomationCandidateUnresponsiveFormComponent,
    AutomationCandidateRejectionFormComponent,
    AutomationRequestedUpdateHelperComponent,
    AutomationAutoResponseHelperComponent,
    AutomationLoaderComponent,
    AutomationFormHeaderComponent,
  ],
  template: `
    <automation-loader [isLoading]="isUploadingAttachment"></automation-loader>
    <ng-container *ngIf="forms">
      <div class="e11-flex e11-justify-between e11-items-center ">
        <h3>{{ formHeader }}</h3>
        <e11-button
          value="{{ 'Save changes' | translate }}"
          [size]="'sm'"
          [color]="'primary-accent'"
          [type]="'button'"
          [loading]="saving"
          [disabled]="saving || loading"
          [loadingText]="'Saving...'"
          (click)="!saving && saveClicked()"
        >
        </e11-button>
      </div>
      <e11-divider [margin]="1"></e11-divider>
      <div class="e11-flex e11-flex-col e11-gap-16">
        <div>
          <automation-form-header
            [sectionTitle]="'Application Auto Response'"
            [showEnableSwitch]="showEnableSwitch"
            [isCollapsed]="isAutoResponseCollapsed"
            [isEnabled]="autoResponseForm?.form?.get('enabled')?.value ?? false"
            (enabledChanged)="autoResponseForm?.form?.get('enabled')?.setValue($event)"
            (isCollapsedChanged)="isAutoResponseCollapsed = !isAutoResponseCollapsed"
          ></automation-form-header>

          <div>
            <span class="e11-mr-1"> {{ 'Automatically send messages to candidates as they complete their application.' }}</span>
            <job-automation-auto-response-helper></job-automation-auto-response-helper>
          </div>
          <e11-show-hide-wrapper [isCollapsed]="isAutoResponseCollapsed">
            <e11-dynamic-form-builder
              #autoResponseForm
              [loading]="loading"
              [fieldGroups]="forms.autoResponse"
              [initialValue]="formData.autoResponse"
              [hideSubmitMenu]="true"
              (valueChanges)="activeSection = 'autoResponse'; handleChange($event, 'autoResponse')"
            >
              <ng-template e11Template="messageFootNoteTemplate">
                <div class="e11-my-2">
                  <span>{{ 'Sent to the candidates as their applications are submitted' | translate }}</span>
                </div>
              </ng-template>
              <ng-template e11Template="delayTimeHeaderTemplate">
                <automation-delay-info></automation-delay-info>
              </ng-template>
              <ng-template e11Template="upgradeProductTemplate">
                <automation-upgrade-prompt automationType="Application Auto Response"></automation-upgrade-prompt>
              </ng-template>
              <ng-template e11Template="resetFormTemplate">
                <automation-reset
                  *ngIf="showResetButton"
                  [formField]="'autoResponse'"
                  [loading]="loading"
                  (onFormFieldReset)="onFormFieldReset(autoResponseForm, $event); onResetAttachment('autoResponse', $event)"
                ></automation-reset>
              </ng-template>
              <ng-template e11Template="videoUrlPreviewTemplate">
                <automation-attachment-preview
                  [fileReceipts]="autoResponse.attachments"
                  (removeFileReceipt)="activeSection = 'autoResponse'; removeFile($event)"
                ></automation-attachment-preview>
              </ng-template>
            </e11-dynamic-form-builder>
          </e11-show-hide-wrapper>
        </div>
        <div>
          <automation-form-header
            [sectionTitle]="'Request Updates'"
            [showEnableSwitch]="showEnableSwitch"
            [isCollapsed]="isRequestUpdatesCollapsed"
            [isEnabled]="requestUpdatesForm?.form?.get('enabled')?.value ?? false"
            (enabledChanged)="requestUpdatesForm?.form?.get('enabled')?.setValue($event)"
            (isCollapsedChanged)="isRequestUpdatesCollapsed = !isRequestUpdatesCollapsed"
          ></automation-form-header>

          <p>
            {{
              'Choose the parts of your applications you find important and let the system automatically ask the candidate to provide more information if these areas are left blank.'
                | translate
            }}
            <job-automation-requested-update-helper></job-automation-requested-update-helper>
          </p>
          <e11-show-hide-wrapper [isCollapsed]="isRequestUpdatesCollapsed">
            <div class="e11-flex e11-flex-col e11-gap-16">
              <e11-dynamic-form-builder
                #requestUpdatesForm
                [loading]="loading"
                [fieldGroups]="forms.requestedUpdate"
                [initialValue]="formData.requestMore.requestedUpdate"
                [hideSubmitMenu]="true"
                (valueChanges)="requestUpdates = $event"
              >
                <ng-template e11Template="requestMoreInfo">
                  <div class="">
                    <div class="e11-font-bold">{{ 'What to check' | translate }}</div>
                    <p>{{ 'If these areas of the application are left blank we will ask the candidate to supply information.' | translate }}</p>
                  </div>
                </ng-template>
                <ng-template e11Template="swimlaneInfo">
                  <div class="">
                    <div class="e11-font-bold">{{ 'Where to go next' | translate }}</div>
                    <p>{{ 'After the candidate completes these requests where do you want to place the candidate in your pipeline?' | translate }}</p>
                  </div>
                </ng-template>
                <ng-template e11Template="resetFormTemplate">
                  <automation-reset
                    *ngIf="showResetButton"
                    [formField]="'requestedUpdate'"
                    [loading]="loading"
                    (onFormFieldReset)="onFormFieldReset(requestUpdatesForm, $event)"
                  ></automation-reset>
                </ng-template>
              </e11-dynamic-form-builder>

              <div>
                <div class="e11-flex e11-gap-2 e11-items-center">
                  <h4 class="e11-font-bold">{{ 'Initial Message' | translate }}</h4>
                  <e11-switch
                    *ngIf="showEnableSwitch"
                    [checked]="initialMessageForm.form.get('enabled')?.value ?? false"
                    (changes)="initialMessageForm.form.get('enabled')?.setValue($event)"
                    [size]="'xs'"
                    [color]="'success'"
                  ></e11-switch>
                </div>
                <e11-dynamic-form-builder
                  #initialMessageForm
                  [loading]="loading"
                  [fieldGroups]="forms.requestMoreInitial"
                  [initialValue]="formData.requestMore.initialMessage"
                  [hideSubmitMenu]="true"
                  (valueChanges)="activeSection = 'initialMessage'; handleChange($event, 'initialMessage')"
                >
                  <ng-template e11Template="messageFootNoteTemplate">
                    <div class="e11-my-2 ">
                      <span>{{
                        'Sent to the candidates after the application is submitted, reviewed and the sections listed have been determined to be lacking any data.'
                          | translate
                      }}</span>
                    </div>
                  </ng-template>
                  <ng-template e11Template="delayTimeHeaderTemplate">
                    <automation-delay-info></automation-delay-info>
                  </ng-template>
                  <ng-template e11Template="upgradeProductTemplate">
                    <automation-upgrade-prompt automationType="Requested Update"></automation-upgrade-prompt>
                  </ng-template>
                  <ng-template e11Template="resetFormTemplate">
                    <automation-reset
                      *ngIf="showResetButton"
                      [formField]="'requestMoreInitial'"
                      [loading]="loading"
                      (onFormFieldReset)="onFormFieldReset(initialMessageForm, $event); onResetAttachment('initialMessage', $event)"
                    ></automation-reset>
                  </ng-template>
                  <ng-template e11Template="videoUrlPreviewTemplate">
                    <automation-attachment-preview
                      [fileReceipts]="initialMessage.attachments"
                      (removeFileReceipt)="activeSection = 'initialMessage'; removeFile($event)"
                    ></automation-attachment-preview>
                  </ng-template>
                </e11-dynamic-form-builder>
              </div>

              <div>
                <div class="e11-flex e11-gap-2 e11-items-center">
                  <h4 class="e11-font-bold">{{ 'Follow up' | translate }}</h4>
                  <e11-switch
                    *ngIf="showEnableSwitch"
                    [checked]="followUpMessageForm.form.get('enabled')?.value ?? false"
                    (changes)="followUpMessageForm.form.get('enabled')?.setValue($event)"
                    [size]="'xs'"
                    [color]="'success'"
                  ></e11-switch>
                </div>
                <e11-dynamic-form-builder
                  #followUpMessageForm
                  [loading]="loading"
                  [fieldGroups]="forms.requestMoreFollowUp"
                  [initialValue]="formData.requestMore.followUp"
                  [hideSubmitMenu]="true"
                  (valueChanges)="activeSection = 'followUpMessage'; handleChange($event, 'followUpMessage')"
                >
                  <ng-template e11Template="messageFootNoteTemplate">
                    <automation-reminder-info></automation-reminder-info>
                  </ng-template>
                  <ng-template e11Template="delayTimeHeaderTemplate">
                    <automation-delay-info></automation-delay-info>
                  </ng-template>
                  <ng-template e11Template="upgradeProductTemplate">
                    <automation-upgrade-prompt automationType="Requested Update"></automation-upgrade-prompt>
                  </ng-template>
                  <ng-template e11Template="resetFormTemplate">
                    <automation-reset
                      *ngIf="showResetButton"
                      [formField]="'requestMoreFollowUp'"
                      [loading]="loading"
                      (onFormFieldReset)="onFormFieldReset(followUpMessageForm, $event); onResetAttachment('followUpMessage', $event)"
                    ></automation-reset>
                  </ng-template>
                  <ng-template e11Template="videoUrlPreviewTemplate">
                    <automation-attachment-preview
                      [fileReceipts]="followUpMessage.attachments"
                      (removeFileReceipt)="activeSection = 'followUpMessage'; removeFile($event)"
                    ></automation-attachment-preview>
                  </ng-template>
                </e11-dynamic-form-builder>
              </div>

              <div>
                <div class="e11-flex e11-gap-2 e11-items-center">
                  <h4 class="e11-font-bold">{{ 'Warning' | translate }}</h4>
                  <e11-switch
                    *ngIf="showEnableSwitch"
                    [checked]="warningMessageForm.form.get('enabled')?.value ?? false"
                    (changes)="warningMessageForm.form.get('enabled')?.setValue($event)"
                    [size]="'xs'"
                    [color]="'success'"
                  ></e11-switch>
                </div>
                <e11-dynamic-form-builder
                  #warningMessageForm
                  [loading]="loading"
                  [fieldGroups]="forms.requestMoreWarning"
                  [initialValue]="formData.requestMore.warning"
                  [hideSubmitMenu]="true"
                  (valueChanges)="activeSection = 'warningMessage'; handleChange($event, 'warningMessage')"
                >
                  <ng-template e11Template="messageFootNoteTemplate">
                    <automation-reminder-info></automation-reminder-info>
                  </ng-template>
                  <ng-template e11Template="delayTimeHeaderTemplate">
                    <automation-delay-info></automation-delay-info>
                  </ng-template>
                  <ng-template e11Template="upgradeProductTemplate">
                    <automation-upgrade-prompt automationType="Requested Update"></automation-upgrade-prompt>
                  </ng-template>
                  <ng-template e11Template="resetFormTemplate">
                    <automation-reset
                      *ngIf="showResetButton"
                      [formField]="'requestMoreWarning'"
                      [loading]="loading"
                      (onFormFieldReset)="onFormFieldReset(warningMessageForm, $event); onResetAttachment('warningMessage', $event)"
                    ></automation-reset>
                  </ng-template>
                  <ng-template e11Template="videoUrlPreviewTemplate">
                    <automation-attachment-preview
                      [fileReceipts]="warningMessage.attachments"
                      (removeFileReceipt)="activeSection = 'warningMessage'; removeFile($event)"
                    ></automation-attachment-preview>
                  </ng-template>
                </e11-dynamic-form-builder>
              </div>
            </div>
          </e11-show-hide-wrapper>
        </div>
        <div>
          <job-automation-auto-question-form
            *ngIf="showAutoQuestionForm"
            [loading]="loading"
            [formData]="formData.autoQuestions"
            [formConfig]="_formConfig"
            (valueChanges)="autoQuestions = $event"
            (saveQuestionsChanges)="saveAutoQuestions(formData, $event)"
          ></job-automation-auto-question-form>
        </div>
        <div>
          <job-automation-candidate-unresponsive-form
            *ngIf="showUnresponsiveCandidateForm"
            [loading]="loading"
            [formData]="formData.unresponsiveCandidate"
            (valueChanges)="unresponsiveCandidate = $event"
          ></job-automation-candidate-unresponsive-form>
        </div>
        <div>
          <job-automation-candidate-rejection-form
            *ngIf="showRejectionCandidateForm"
            [loading]="loading"
            [formData]="formData.rejectCandidate"
            (valueChanges)="rejectCandidate = $event"
          ></job-automation-candidate-rejection-form>
        </div>
      </div>
    </ng-container>
  `,
})
export class JobAutomationFormComponent implements AfterViewChecked, OnChanges {
  @ViewChild('autoResponseForm') autoResponseForm!: E11DynamicFormBuilderComponent<IApplicationAutoResponseFormData>
  @ViewChild('requestUpdatesForm') requestUpdatesForm!: E11DynamicFormBuilderComponent<IRequestUpdatesFormData>
  @ViewChild('initialMessageForm') initialMessageForm!: E11DynamicFormBuilderComponent<ISendMessageFormData>
  @ViewChild('followUpMessageForm') followUpMessageForm!: E11DynamicFormBuilderComponent<ISendMessageFormData>
  @ViewChild('warningMessageForm') warningMessageForm!: E11DynamicFormBuilderComponent<ISendMessageFormData>

  @Input() saving = false
  @Input() loading = false
  @Input() showResetButton = false
  @Input() showEnableSwitch = false
  @Input() showAutoQuestionForm = false
  @Input() showUnresponsiveCandidateForm = false
  @Input() showRejectionCandidateForm = false
  @Input() formHeader = 'Manage Automation'
  @Input() formData: IAutomationFormData = automationFormDefaults
  _formConfig?: IAutomationFormConfig
  @Input() set formConfig(config: IAutomationFormConfig) {
    this._formConfig = { ...config }
    // Do changes to hiring team reconstruct the form? e.g. Do they clear existing inputs?
    this.forms = this.buildForms(this._formConfig)
  }

  isUploadingAttachment = false

  isAutoResponseCollapsed = false
  isRequestUpdatesCollapsed = false
  forms?: IAutomationForms
  @Output() newFormData = new EventEmitter<IAutomationFormData>()

  autoQuestions?: IAutoQuestionAutomationFormData
  unresponsiveCandidate?: IUnresponsiveCandidateFormData
  rejectCandidate?: IRejectCandidateFormData

  #logger = inject(E11Logger)

  constructor(
    private formSelectService: FormSelectService,
    private cdr: ChangeDetectorRef,
    private errorHandler: E11ErrorHandlerService,
    private notification: E11NotificationsService,
    private jobPostAutomationService: JobPostAutomationService
  ) {}

  ngAfterViewChecked() {
    if (this.cdr) this.cdr.detectChanges() // Suppresses NG0100 on
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formData && this._formConfig) {
      this.forms = this.buildForms(this._formConfig, { ...this.formData })
      this.setAutoResponse(this.formData)
      this.setRequestMore(this.formData)
    }
  }

  setAutoResponse(value: IAutomationFormData) {
    this.autoResponse = structuredClone(value.autoResponse)
  }

  setRequestMore(value: IAutomationFormData) {
    this.requestUpdates = value.requestMore.requestedUpdate
    this.initialMessage = value.requestMore.initialMessage
    this.followUpMessage = value.requestMore.followUp
    this.warningMessage = value.requestMore.warning
  }

  activeSection?: AutomationProperties

  requestUpdates: IRequestUpdatesFormData = this.formData.requestMore.requestedUpdate
  autoResponse: ISendMessageFormData = this.formData.autoResponse
  initialMessage: ISendMessageFormData = this.formData.requestMore.initialMessage
  followUpMessage: ISendMessageFormData = this.formData.requestMore.followUp
  warningMessage: ISendMessageFormData = this.formData.requestMore.warning

  autoResponseTempFiles: IMarkdownReceiptFile[] = []
  initialMessageTempFiles: IMarkdownReceiptFile[] = []
  followUpMessageTempFiles: IMarkdownReceiptFile[] = []
  warningMessageTempFiles: IMarkdownReceiptFile[] = []

  async onFileUpload(markdownFiles?: IMarkdownFile[]) {
    if (!this.activeSection || !markdownFiles) return
    const allAttachments = this.getAllAttachments(this.activeSection)
    const validation = validateFile(markdownFiles, allAttachments)
    if (!validation.status) {
      this.notification.popNotificationMessage({
        title: validation.title,
        message: validation.message,
        type: 'error',
      })
      return
    }
    this.isUploadingAttachment = true
    if (this.cdr) this.cdr.detectChanges()
    try {
      const tempFiles = await this.uploadAttachments(markdownFiles)
      this.addTempFiles(tempFiles)
    } catch (error) {
      this.errorHandler.handleE11Error(
        toE11Error(error, { title: 'Failed to upload attachment', type: 'upload-attachment-failed', additionalData: { markdownFiles } }),
        { alertUser: true }
      )
    } finally {
      this.isUploadingAttachment = false
    }
    if (this.cdr) this.cdr.detectChanges()
  }

  async uploadAttachments(files: IMarkdownFile[]): Promise<IMarkdownReceiptFile[]> {
    return (await Promise.all(files.map(async file => this.uploadAttachment(file)))).filter(isNotNil)
  }

  async uploadAttachment(file: IMarkdownFile): Promise<IMarkdownReceiptFile | undefined> {
    try {
      const attachment = await this.jobPostAutomationService.uploadAttachment(file.file)
      return { id: file.id, receiptFile: attachment }
    } catch (error) {
      this.errorHandler.handleE11Error(
        toE11Error(error, {
          title: 'Failed to upload attachment',
          type: 'upload-attachment-failed',
          additionalData: { file },
        }),
        { alertUser: true }
      )
      return undefined
    }
  }

  generateOnFileUploadFn(section: AutomationProperties) {
    return (file: IMarkdownFile[]) => {
      this.activeSection = section
      this.onFileUpload(this.findNewFiles(file))
    }
  }

  generateOnFileRemoveFn(section: AutomationProperties) {
    return (file: IMarkdownFile) => {
      this.activeSection = section
      this.removeTempFile(file.id)
    }
  }

  generateMultiMediaArgs(section: AutomationProperties): Partial<MultiMediaArgs> {
    return {
      onFileUpload: this.generateOnFileUploadFn(section),
      onFileRemove: this.generateOnFileRemoveFn(section),
    }
  }

  autoResponseFileFn = this.generateMultiMediaArgs('autoResponse')
  initialMessageFileFn = this.generateMultiMediaArgs('initialMessage')
  followUpMessageFileFn = this.generateMultiMediaArgs('followUpMessage')
  warningMessageFileFn = this.generateMultiMediaArgs('warningMessage')

  private combineChanges<T>(value1: T, value2: Partial<T> | undefined): T {
    return {
      ...value1,
      ...value2,
    }
  }

  private filterFile<T>(attachments: T[], fileId: string, key: keyof T): T[] {
    if (!attachments) return []
    return attachments.filter(f => f[key] !== fileId)
  }

  private concatFile<T>(attachments: T[], file: T): T[] {
    return [file].concat(attachments ?? [])
  }

  private concatFiles<T>(attachments: T[], files: T[]): T[] {
    return files.concat(attachments ?? [])
  }

  findNewFiles(files: IMarkdownFile[]): IMarkdownFile[] {
    if (!this.activeSection) return []
    const tempFileIds = this.getTempFiles(this.activeSection).map(f => f.id)
    return files.filter(f => !tempFileIds.includes(f.id))
  }

  setAttachment(attachment: IFileReceipt, section: AutomationProperties) {
    this.changeData(section, data => ({
      ...data,
      attachments: this.concatFile(data.attachments ?? [], attachment),
    }))
  }

  removeAttachment(fileId: string, section: AutomationProperties) {
    this.changeData(section, data => ({
      ...data,
      attachments: this.filterFile(data.attachments ?? [], fileId, 'id'),
    }))
  }

  addTempFiles(incomingFiles: IMarkdownReceiptFile[]) {
    if (!this.activeSection) return
    this.changeTempFiles(this.activeSection, files => this.concatFiles(files, incomingFiles))
  }

  removeTempFile(fileId: string) {
    if (!this.activeSection) return
    this.changeTempFiles(this.activeSection, files => this.filterFile(files, fileId, 'id'))
    // TODO: fix the rerendering of the attachments
    setTimeout(() => {
      this.cdr.detectChanges()
    }, 100)
  }

  getTempFiles(section: AutomationProperties): IMarkdownReceiptFile[] {
    switch (section) {
      case 'autoResponse':
        return this.autoResponseTempFiles
      case 'initialMessage':
        return this.initialMessageTempFiles
      case 'followUpMessage':
        return this.followUpMessageTempFiles
      case 'warningMessage':
        return this.warningMessageTempFiles
    }
  }

  getAttachments(section: AutomationProperties): IFileReceipt[] {
    switch (section) {
      case 'autoResponse':
        return this.autoResponse.attachments ?? []
      case 'initialMessage':
        return this.initialMessage.attachments ?? []
      case 'followUpMessage':
        return this.followUpMessage.attachments ?? []
      case 'warningMessage':
        return this.warningMessage.attachments ?? []
    }
  }

  getTempAttachments(section: AutomationProperties): IFileReceipt[] {
    return this.getTempFiles(section).map(f => f.receiptFile)
  }

  getAllAttachments(section: AutomationProperties): IFileReceipt[] {
    const allFiles = this.concatFiles(this.getAttachments(section), this.getTempAttachments(section))
    return uniqBy(allFiles, 'id')
  }

  combineAttachments(data: ISendMessageFormData, attachments: IFileReceipt[]) {
    return {
      ...data,
      attachments: attachments,
    }
  }

  handleChange(event: ISendMessageFormData, section: AutomationProperties) {
    this.changeData(section, data => this.combineChanges(data, event))
  }

  changeData(section: AutomationProperties, fn: (data: ISendMessageFormData) => ISendMessageFormData) {
    switch (section) {
      case 'autoResponse':
        this.autoResponse = fn(this.autoResponse)
        break
      case 'initialMessage':
        this.initialMessage = fn(this.initialMessage)
        break
      case 'followUpMessage':
        this.followUpMessage = fn(this.followUpMessage)
        break
      case 'warningMessage':
        this.warningMessage = fn(this.warningMessage)
        break
    }
  }

  changeTempFiles(section: AutomationProperties, fn: (data: IMarkdownReceiptFile[]) => IMarkdownReceiptFile[]) {
    switch (section) {
      case 'autoResponse':
        this.autoResponseTempFiles = fn(this.autoResponseTempFiles)
        break
      case 'initialMessage':
        this.initialMessageTempFiles = fn(this.initialMessageTempFiles)
        break
      case 'followUpMessage':
        this.followUpMessageTempFiles = fn(this.followUpMessageTempFiles)
        break
      case 'warningMessage':
        this.warningMessageTempFiles = fn(this.warningMessageTempFiles)
        break
    }
  }

  removeFile(fileId: string) {
    if (!this.activeSection) return
    this.removeAttachment(fileId, this.activeSection)
  }

  // TODO: remove file todo for auto questions
  validateForms() {
    const forms = [this.autoResponseForm, this.requestUpdatesForm, this.initialMessageForm, this.followUpMessageForm, this.warningMessageForm]
    // run validation on all forms
    forms.forEach(form => form.onSubmitClick())

    return forms.every(form => form.form.valid)
  }

  async saveClicked() {
    if (!this.validateForms()) {
      this.notification.popNotificationMessage({
        message: 'Please ensure all fields are filled out correctly',
        type: 'error',
        autoClose: true,
        title: 'Could not save automation',
        dismissOnRouteChange: true,
      })
      return
    }

    // Instead of validation, we force defaults on the user if nothing is included
    const autoResponse: IApplicationAutoResponseFormData = this.combineAttachments(this.autoResponse, this.getAllAttachments('autoResponse'))

    const requestMore: IRequestMoreFormData = {
      requestedUpdate: this.requestUpdates,
      initialMessage: this.combineAttachments(this.initialMessage, this.getAllAttachments('initialMessage')),
      followUp: this.combineAttachments(this.followUpMessage, this.getAllAttachments('followUpMessage')),
      warning: this.combineAttachments(this.warningMessage, this.getAllAttachments('warningMessage')),
    }

    const autoQuestions: IAutoQuestionAutomationFormData = this.combineChanges(this.formData.autoQuestions, this.autoQuestions)
    const unresponsiveCandidate: IUnresponsiveCandidateFormData = this.combineChanges(unresponsiveCandidateDefaults, this.unresponsiveCandidate)
    const rejectCandidate: IRejectCandidateFormData = this.combineChanges(rejectCandidateDefaults, this.rejectCandidate)

    const formData: IAutomationFormData = {
      requestMore,
      autoResponse,
      autoQuestions,
      unresponsiveCandidate,
      rejectCandidate,
    }

    this.#logger.log('JobAutomationFormComponent.saveClicked', formData)
    this.newFormData.emit(formData)
  }

  onFormFieldReset(
    form: E11DynamicFormBuilderComponent<IRequestUpdatesFormData> | E11DynamicFormBuilderComponent<ISendMessageFormData>,
    companyData: AutomationFormData
  ) {
    form.reset(companyData as any)
  }

  onResetAttachment(section: AutomationProperties, companyData: AutomationFormData) {
    this.changeData(section, _ => companyData as any)
    this.changeTempFiles(section, _ => [])
  }

  private buildForms(config: IAutomationFormConfig, defaults: IAutomationFormData = this.formData): IAutomationForms {
    return {
      autoResponse: sendMessageForm(defaults.autoResponse, config, this.autoResponseFileFn),
      requestedUpdate: this.buildRequestedUpdateForm(config),
      requestMoreInitial: sendMessageForm(defaults.requestMore.initialMessage, config, this.initialMessageFileFn),
      requestMoreFollowUp: sendMessageForm(defaults.requestMore.followUp, config, this.followUpMessageFileFn),
      requestMoreWarning: sendMessageForm(defaults.requestMore.warning, config, this.warningMessageFileFn),
    }
  }

  private buildRequestedUpdateForm(config: IAutomationFormConfig): E11FormForType<IRequestUpdatesFormData> {
    const { enabled, fields, newSwimlane } = automationFormDefaults.requestMore.requestedUpdate
    const { canEditMessages } = config
    const premiumTemplates: IFormTemplateSpec[] = canEditMessages
      ? [
          {
            templateKey: 'resetFormTemplate',
            templatePosition: 'bottom',
          },
        ]
      : []
    const swimlaneOptions = this.formSelectService
      .getApplicationStateOptions()
      .filter(s => [APPLICATION_STATE.SCREENED, APPLICATION_STATE.INTERVIEWING, APPLICATION_STATE.FINALIST].includes(s.value))
    return e11FormFor<IRequestUpdatesFormData>()([
      e11FormLayoutFullWidth(
        [
          e11FieldInputSwitch('enabled', 'Enabled', enabled, {
            validatorOrOpts: [],
          }),
        ],
        { containerClass: 'e11-hidden' }
      ),
      e11LayoutGroup(
        [
          e11LayoutGroup([
            e11FormLayoutFullWidth(
              [
                e11FieldInputMultiSelector('fields', '', requestedFieldOptions, fields, {
                  validatorOrOpts: [Validators.required],
                }),
              ],
              { templateSpec: { templateKey: 'requestMoreInfo', templatePosition: 'top' } }
            ),
          ]),
          e11LayoutGroup([
            e11FormLayoutFullWidth(
              [
                e11FieldInputSelect('newSwimlane', 'New Swimlane', swimlaneOptions, newSwimlane, {
                  validatorOrOpts: [Validators.required],
                }),
              ],
              { templateSpec: { templateKey: 'swimlaneInfo', templatePosition: 'top' } }
            ),
          ]),
        ],
        {
          layoutContainerClass: 'e11-grid e11-grid-cols-1 md:e11-grid-cols-2 e11-gap-4',
          templateSpec: premiumTemplates,
        }
      ),
    ])
  }

  /**
   * Saves ONLY the questions changes made from the autoQuestion modal
   */
  saveAutoQuestions(existingFormData: IAutomationFormData, autoQuestions: IAutoQuestionFormData[]) {
    this.newFormData.emit({
      ...existingFormData,
      autoQuestions: { enabled: existingFormData.autoQuestions.enabled, autoQuestions },
    })
  }
}

/**
 * Options:
 * 1: Save will only save if all forms pass
 * 2: Save saves the passing forms and not the failing forms?
 * 3: Save saves all forms?
 */

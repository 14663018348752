import { NgClass, NgIf } from '@angular/common'
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output, ViewChild, inject } from '@angular/core'
import {
  IAutoQuestionAutomationFormData,
  IAutoQuestionFormData,
} from '@employer/app/components/jobs-automation/automation-auto-question/auto-questions.model'
import { AutoQuestionStore } from '@employer/app/components/jobs-automation/automation-auto-question/auto-questions.store'
import { AutoQuestionMessageIndicatorComponent } from '@employer/app/components/jobs-automation/automation-auto-question/partials/auto-question-message-indicator.component'
import { AutomatedQuestionModalComponent } from '@employer/app/components/jobs-automation/automation-auto-question/partials/automated-question-modal.component'
import { IAutomationFormConfig } from '@employer/app/components/jobs-automation/automation-form-message'
import { AutomationDelayInfoComponent } from '@employer/app/components/jobs-automation/partials/automation-delay-info.component'
import { AutomationFormHeaderComponent } from '@employer/app/components/jobs-automation/partials/automation-form-header.component'
import { AutomationResetComponent } from '@employer/app/components/jobs-automation/partials/automation-reset.component'
import { AutomationUpgradePromptComponent } from '@employer/app/components/jobs-automation/partials/automation-upgrade-prompt.component'
import { E11ButtonModule } from '@engineering11/ui-lib/e11-button'
import { E11ConfirmationModule } from '@engineering11/ui-lib/e11-confirmation'
import { E11DataTableModule } from '@engineering11/ui-lib/e11-datatable'
import { E11DynamicFormModule } from '@engineering11/ui-lib/e11-form-builder'
import { E11ShowHideControllerComponent, E11ShowHideWrapperComponent } from '@engineering11/ui-lib/e11-show-hide'
import { E11SwitchModule } from '@engineering11/ui-lib/e11-switch'
import { E11TooltipModule } from '@engineering11/ui-lib/e11-tooltip'
import { E11UtilModule } from '@engineering11/ui-lib/e11-ui-utils'
import { E11Logger } from '@engineering11/web-api-error'
import { LetDirective } from '@ngrx/component'
import { TranslateModule } from '@ngx-translate/core'
import { Observable } from 'rxjs'
import { combineLatestWith, map, tap } from 'rxjs/operators'
import { SharedLibModule } from 'shared-lib'
import { autoQuestionsTableOptions } from './auto-questions-table-config'
import { AutomatedQuestionPopupHelperModalComponent } from './popup-helpers/automated-question-modal.component'

@Component({
  selector: 'job-automation-auto-question-form',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    E11ShowHideControllerComponent,
    E11SwitchModule,
    TranslateModule,
    AutomationDelayInfoComponent,
    AutomationResetComponent,
    AutomationUpgradePromptComponent,
    E11DynamicFormModule,
    E11ShowHideWrapperComponent,
    E11UtilModule,
    NgIf,
    E11ButtonModule,
    AutomatedQuestionModalComponent,
    E11DataTableModule,
    SharedLibModule,
    LetDirective,
    NgClass,
    AutoQuestionMessageIndicatorComponent,
    E11TooltipModule,
    AutomatedQuestionPopupHelperModalComponent,
    E11ConfirmationModule,
    AutomationFormHeaderComponent,
  ],
  template: `
    <ng-container *ngrxLet="autoQuestionAutomation$ as automation">
      <automation-form-header
        [sectionTitle]="'Automated Questions'"
        [showEnableSwitch]="true"
        [isCollapsed]="isCollapsed"
        [isEnabled]="automation.enabled"
        (enabledChanged)="toggleAutomationEnabled($event)"
        (isCollapsedChanged)="isCollapsed = !isCollapsed"
      ></automation-form-header>
      <automated-question-modal
        (onSavedChanges)="saveQuestionsChanges.emit(automation.autoQuestions)"
        #automatedQuestionModalComponent
      ></automated-question-modal>
      <automated-question-popup-helper-modal #automatedQuestionPopupHelperModalComponent />

      <div class="e11-mb-4">
        <span>
          {{
            'Automatically ask your candidates important and relevant questions about the job they are applying for. These questions will be sent to the candidate after the conditions you configure are met.'
          }}</span
        >
        <span
          (click)="automatedQuestionPopupHelperModalComponent.open()"
          class="e11-text-skin-primary-accent e11-mx-2 e11-cursor-pointer e11-select-none"
          >How this works</span
        >
      </div>
      <e11-show-hide-wrapper [isCollapsed]="isCollapsed">
        <div class="e11-flex e11-justify-between e11-mb-4">
          <e11-button
            [skeletonLoading]="loading"
            (click)="automatedQuestionModalComponent.open()"
            value="Add Questions"
            size="sm"
            buttonStyle="pill"
            color="primary-accent"
          ></e11-button>
          <span class="e11-text-sm">{{ automation.autoQuestions.length }} {{ 'Automated questions' | translate }}</span>
        </div>

        <div class="e11-overflow-scroll">
          <e11-datatable [initialGridOptions]="tableConfig" [records]="automation.autoQuestions" [loading]="loading">
            <ng-template e11DatatableTemplate="swimlane" let-row>
              {{ 'Swimlane - ' | translate }}{{ row.swimlane | applicationState | translate }}
            </ng-template>
            <ng-template e11DatatableTemplate="questions" let-row>
              {{ row.questions?.length }}
            </ng-template>
            <ng-template e11DatatableTemplate="messaging" let-row>
              <div *ngIf="assertRowItem(row) as row" class="e11-flex e11-gap-2">
                <auto-question-message-indicator
                  [type]="'initialMessage'"
                  [enabled]="row.initialMessage.enabled"
                  [message]="row.initialMessage.message"
                >
                </auto-question-message-indicator>
                <auto-question-message-indicator [type]="'followUp'" [enabled]="row.followUp.enabled" [message]="row.followUp.message">
                </auto-question-message-indicator>
                <auto-question-message-indicator [type]="'warning'" [enabled]="row.warning.enabled" [message]="row.warning.message">
                </auto-question-message-indicator>
              </div>
            </ng-template>

            <ng-template e11DatatableTemplate="action" let-row>
              <div class="e11-flex e11-items-center e11-gap-2">
                <e11-tooltip
                  [delay]="500"
                  [color]="'dark'"
                  [tooltipText]="
                    row.enabled
                      ? 'These questions are active and being sent to candidates.'
                      : ('These questions are not active and not being sent to any candidates.' | translate)
                  "
                >
                  <e11-switch (changes)="toggleQuestionEnabled(row, $event)" [color]="'success'" [checked]="row.enabled" [size]="'xs'"></e11-switch>
                </e11-tooltip>
                <span
                  (click)="editAutomation(row); automatedQuestionModalComponent.open(false)"
                  class="e11-cursor-pointer material-icons-outlined e11-text-skin-primary-accent"
                  >edit</span
                >
                <span
                  (click)="selectedForDeletion = row; confirmAutomationDeletion.open()"
                  class="e11-cursor-pointer material-icons-outlined e11-text-skin-danger"
                  >delete</span
                >
              </div>
            </ng-template>
          </e11-datatable>
        </div>
      </e11-show-hide-wrapper>

      <e11-confirmation
        #confirmAutomationDeletion
        (answer)="deleteAutomation($event)"
        [backdropStyle]="'dark'"
        cancelText="{{ 'Cancel' | translate }}"
        confirmText="{{ 'Delete' | translate }}"
        confirmTitle="{{ 'Delete Automated Question' | translate }}"
      >
        {{ 'Are you sure you want to delete "' + selectedForDeletion?.name + '" automated question?' | translate }}
      </e11-confirmation>
    </ng-container>
  `,
  styles: ``,
})
export class AutomationAutoQuestionFormComponent implements OnDestroy {
  @ViewChild('automatedQuestionPopupHelperModalComponent') automatedQuestionPopupHelperModalComponent!: AutomatedQuestionPopupHelperModalComponent
  @ViewChild('automatedQuestionModalComponent') automatedQuestionModalComponent!: AutomatedQuestionModalComponent
  @Input() loading = false

  @Input() set formData(automations: IAutoQuestionAutomationFormData) {
    this.#logger.log('AutomationAutoQuestionFormComponent.formData', automations)
    this.autoQuestionStore.setAutoQuestions(automations)
  }

  @Input() formConfig: IAutomationFormConfig | undefined

  @Output() valueChanges = new EventEmitter<IAutoQuestionAutomationFormData>()
  @Output() saveQuestionsChanges = new EventEmitter<IAutoQuestionFormData[]>()

  isEnabled = false
  isCollapsed = false
  tableConfig = autoQuestionsTableOptions

  autoQuestionAutomation$: Observable<IAutoQuestionAutomationFormData> = this.autoQuestionStore.autoQuestionsEnabled$.pipe(
    combineLatestWith(this.autoQuestionStore.allAutoQuestionFormData$),
    map(([enabled, autoQuestions]) => ({ enabled, autoQuestions })),
    tap(automations => {
      this.valueChanges.emit(automations)
      this.saveOnDeletion(automations)
    })
  )
  #logger = inject(E11Logger)
  selectedForDeletion: IAutoQuestionFormData | undefined

  constructor(private autoQuestionStore: AutoQuestionStore) {}

  toggleQuestionEnabled(formData: IAutoQuestionFormData, value: boolean) {
    this.autoQuestionStore.updateAutoQuestion({ ...formData, enabled: value })
  }

  toggleAutomationEnabled(value: boolean) {
    this.autoQuestionStore.toggleAutoQuestionsEnabled(value)
  }

  editAutomation(formData: IAutoQuestionFormData) {
    this.autoQuestionStore.setCurrentAutoQuestionFormId(formData.id)
  }

  assertRowItem(row: any): IAutoQuestionFormData {
    return row as IAutoQuestionFormData
  }

  ngOnDestroy() {
    this.autoQuestionStore.resetAutoQuestions()
  }

  deleteAutomation(answer: boolean) {
    if (!answer || !this.selectedForDeletion) {
      return
    }
    this.autoQuestionStore.onDeleteAutoQuestion(this.selectedForDeletion.id)
  }

  private saveOnDeletion(automations: IAutoQuestionAutomationFormData) {
    if (!this.selectedForDeletion) {
      return
    }
    this.saveQuestionsChanges.emit(automations.autoQuestions)
    this.selectedForDeletion = undefined
  }
}

import { MessagingSystemUserIds } from 'shared-lib'
import { ISendMessageFormData } from './job-automation.model'

export type IApplicationAutoResponseFormData = ISendMessageFormData

export const autoResponseDefaults: IApplicationAutoResponseFormData = {
  enabled: false,
  message:
    'Hello, and thanks for taking the time to submit your application! We look forward to reviewing it and learning more about you. Please keep an eye out as the process moves forward, as we may be sending out some requests or interview questions for you to respond to.',
  senderId: MessagingSystemUserIds.hiring_team,
  delayDuration: 10,
  delayUnits: 'minutes',
}

import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { INotificationHandler, INotificationView, UserAppNotificationService } from '@engineering11/notifications-web'
import { IApplicationReviewCreatedNotification } from 'projects/shared-lib/src/lib/model/app-notification.model'
import { CandidateDetailTabs, EmployerNavigationService } from '../navigation.service'

@Injectable({ providedIn: 'root' })
export class ApplicationReviewCreatedNotificationHandler implements INotificationHandler<IApplicationReviewCreatedNotification, INotificationView> {
  notificationType = 'employer_new_review_available'
  constructor(
    private router: Router,
    private userAppNotificationService: UserAppNotificationService,
    private navigationService: EmployerNavigationService
  ) {}

  onClick(notification: IApplicationReviewCreatedNotification) {
    this.markAsRead(notification)
    return this.navigationService.toCandidateDetailPage(notification.jobPostId, notification.candidateApplicationId, CandidateDetailTabs.Feedback)
  }

  private markAsRead(notification: IApplicationReviewCreatedNotification) {
    if (notification.viewed) {
      return
    }
    this.userAppNotificationService.markNotificationAsRead(notification.id)
  }
}

<e11-panel [hasBorder]="true" [hasShadow]="true">
  <div class="e11-w-full e11-mb-16">
    <sdk-customer-manage-company-info> </sdk-customer-manage-company-info>
    <div class="e11-my-4"></div>

    <div class="e11-w-full">
      <h3>{{ 'Deactivate Account' | translate }}</h3>
      <p>
        {{
          "Deactivate your cnect company account. This will permanently deactivate your account, and all other accounts associated with your company's account."
            | translate
        }}
      </p>
      <sdk-registration-deregister
        [customDeactivationReasons]="customDeactivationReasons"
        (accountDeactivated)="accountDeactivated($event)"
      ></sdk-registration-deregister>
    </div>
  </div>
</e11-panel>

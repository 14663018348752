import { NgClass } from '@angular/common'
import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'

@Component({
  selector: 'auto-question-modal-tab',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, NgClass],
  template: `
    <div class="e11-flex e11-gap-2 e11-mb-2 e11-items-center">
      <span class="material-icons-outlined" [ngClass]="{ 'e11-text-skin-success': completed, 'e11-text-skin-grey/70': !completed }">task_alt</span>
      <span class="e11-text-skin-primary-accent e11-font-primary-semibold" [ngClass]="{ 'e11-font-primary-bold': isActive }">
        {{ title | translate }}</span
      >
    </div>
    <p class="e11-text-skin-grey e11-mb-2 e11-text-sm" [ngClass]="{ 'e11-font-primary-medium': isActive }">
      <ng-content></ng-content>
    </p>
  `,
  styles: ``,
})
export class AutoQuestionModalTabComponent {
  @Input({ required: true }) completed: boolean = false
  @Input({ required: true }) isActive!: boolean
  @Input({ required: true }) title!: string
}

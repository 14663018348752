import { Injectable } from '@angular/core'
import { CollectionRepository, FirestoreQueryBuilder, incrementBy } from '@engineering11/web-api-firebase'
import { BehaviorSubject, Observable } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { AutocompleteType, IAutocomplete } from './autocomplete.model'

export const COLLECTION_AUTOCOMPLETE = 'autocomplete/'

@Injectable({ providedIn: 'root' })
export class AutoCompleteRepository extends CollectionRepository<IAutocomplete> {
  protected COLLECTION = COLLECTION_AUTOCOMPLETE

  getAllByCustomer(customerKey: string): Observable<IAutocomplete[]> {
    const path = this.buildPath()
    const queryFn = new FirestoreQueryBuilder().where('customerKey', '==', customerKey).build()
    return super._query(path, queryFn)
  }

  getAllValueChangesByCustomer(customerKey: string): Observable<IAutocomplete[]> {
    const path = this.buildPath()
    const queryFn = new FirestoreQueryBuilder().where('customerKey', '==', customerKey).build()
    return super._queryValueChanges(path, queryFn)
  }

  queryAutocompleteByCustomer(customerKey: string, type: AutocompleteType, searchText: string) {
    const startText = searchText
    const endText = startText + '\uf8ff'
    const queryFn = (ref: any) =>
      ref.where('customerKey', '==', customerKey).where('type', '==', type).orderBy('search').startAt(startText).endAt(endText).limit(10)
    return this.queryValueChanges(queryFn)
  }

  queryAutoComplete(customerKey: string, type: AutocompleteType, start: BehaviorSubject<string>): Observable<IAutocomplete[]> {
    return start.pipe(
      switchMap((startText: string) => {
        startText = startText.toLocaleLowerCase()
        const endText = startText + '\uf8ff'
        //TODO: Fix FirestoreQueryBuilder startAt and endAt and switch to that
        const queryFn = (ref: any) =>
          ref.where('customerKey', '==', customerKey).where('type', '==', type).orderBy('search').startAt(startText).endAt(endText).limit(10)
        return this.queryValueChanges(queryFn)
      })
    )
  }

  /**
   * Increments the counter on the autocomplete document by 1 so we know its relative usage
   * @param id
   */
  increment(id: string) {
    this.update({ id, useCount: incrementBy(1) as unknown as number })
  }
}

import { ContentItemService, IContentRevision, IContentPublishLog, IContentPublishRequest } from '@engineering11/content-web'
import { Injectable } from '@angular/core'
import { JobProfileStatus } from 'shared-lib'
import { IEmployerUser } from '@cnect/user-shared'
import { IJobProfileContentItem } from '@employer/app/models/job-profile.model'
import { map } from 'rxjs/operators'
import { Optional } from '@engineering11/types'
import { firstValueFrom } from 'rxjs'
@Injectable({
  providedIn: 'root',
})
export class JobProfileContentService extends ContentItemService<IJobProfileContentItem> {
  async saveProfile(user: IEmployerUser, jobProfileContentItem: Optional<IJobProfileContentItem, 'contentId'>): Promise<IJobProfileContentItem> {
    const revision: IContentRevision = {
      userId: user.id!,
      firstName: user.firstName,
      lastName: user.lastName,
      customerKey: user.customerKey,
      description: 'Job template modified',
      contentId: jobProfileContentItem.document.contentId,
    }
    jobProfileContentItem = await super.persist(structuredClone(jobProfileContentItem), revision)
    return jobProfileContentItem as IJobProfileContentItem
  }

  async publishProfile(user: IEmployerUser, jobProfileContentItem: IJobProfileContentItem): Promise<IJobProfileContentItem> {
    jobProfileContentItem.document.status = JobProfileStatus.Published
    jobProfileContentItem = await this.saveProfile(user, jobProfileContentItem)
    const publishLogRequest: IContentPublishRequest = {
      document: jobProfileContentItem.document,
      firstName: user.firstName,
      lastName: user.lastName,
      userId: user.id,
    }
    await this.publish.add(publishLogRequest) // super.publish does not work, must use this.publish
    return jobProfileContentItem
  }

  async rePublishProfile(user: IEmployerUser, jobProfileContentItem: IJobProfileContentItem) {
    const summary = await this.summary.get(jobProfileContentItem.contentId).toPromise()
    if (summary) {
      await this.summary.unArchive(summary)
    }
    jobProfileContentItem.document.status = JobProfileStatus.Published
    return this.publishProfile(user, jobProfileContentItem)
  }

  async reactivateTemplate(user: IEmployerUser, jobProfileContentItem: IJobProfileContentItem) {
    const summary = await this.summary.get(jobProfileContentItem.contentId).toPromise()
    if (summary) {
      await this.summary.unArchive(summary)
    }
    jobProfileContentItem.document.status = JobProfileStatus.Draft
    return this.saveProfile(user, jobProfileContentItem)
  }

  async deactivateProfile(user: IEmployerUser, jobProfileContentItem: IJobProfileContentItem) {
    await this.summary.archiveWithId(jobProfileContentItem.contentId)
    jobProfileContentItem.document.status = JobProfileStatus.Closed
    return this.saveProfile(user, jobProfileContentItem)
  }

  async deleteProfile(user: IEmployerUser, jobProfileContentItem: IJobProfileContentItem) {
    jobProfileContentItem.document.status = JobProfileStatus.Deleted

    const [summary, _] = await Promise.all([firstValueFrom(this.summary.get(jobProfileContentItem.contentId)), this.update(jobProfileContentItem)])
    if (summary) this.summary.delete(summary)
    const content = await firstValueFrom(this.get(jobProfileContentItem.contentId))
    return content!
  }

  getLatestPublishedDate(contentId: string, customerKey: string) {
    return this.publish.getLatestPublishedDate(contentId, customerKey)
  }

  isPublishing(contentId: string, customerKey: string) {
    return this.publish.isPublishing(contentId, customerKey)
  }

  getPublishingItems(contentId: string, customerKey: string) {
    return this.publish.getAllPublishingLogsValueChanges(contentId, customerKey)
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core'
import { E11ShowHideControllerComponent } from '@engineering11/ui-lib/e11-show-hide'
import { E11SwitchComponent } from '@engineering11/ui-lib/e11-switch'
import { NgIf } from '@angular/common'
import { TranslateModule } from '@ngx-translate/core'

@Component({
  selector: 'automation-form-header',
  standalone: true,
  template: `
    <div class="e11-flex e11-flex-col e11-gap-2 e11-mb-2">
      <div class="e11-flex e11-items-center">
        <h3 class="e11-text-skin-primary-accent e11-mr-2">{{ sectionTitle | translate }}</h3>
        <div class="e11-mr-4">
          <e11-switch
            *ngIf="showEnableSwitch"
            [checked]="isEnabled"
            (onChange)="enabledChanged.emit($event)"
            [size]="'xs'"
            [color]="'success'"
          ></e11-switch>
        </div>
        <e11-show-hide-controller
          [containerClass]="'e11-text-skin-primary-accent'"
          [showText]="showText"
          [hideText]="hideText"
          [isCollapsed]="isCollapsed"
          (toggle)="isCollapsedChanged.emit($event)"
        >
        </e11-show-hide-controller>
      </div>
    </div>
  `,
  imports: [E11ShowHideControllerComponent, E11SwitchComponent, NgIf, TranslateModule],
})
export class AutomationFormHeaderComponent {
  @Input() sectionTitle: string = ''
  @Input() isEnabled: boolean = false
  @Input() showEnableSwitch: boolean = true
  @Input() isCollapsed: boolean = true
  @Input() showText: string = 'Show Settings'
  @Input() hideText: string = 'Hide Settings'

  @Output() enabledChanged = new EventEmitter<boolean>()
  @Output() isCollapsedChanged = new EventEmitter<boolean>()
}

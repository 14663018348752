import { Component, OnDestroy } from '@angular/core'
import { IAuthService } from '@engineering11/auth-web'
import { AppConfigService } from '@engineering11/tenant-bootstrap-web'
import { Subject } from 'rxjs'
import { TokenStorage } from 'shared-lib'

@Component({
  selector: 'app-header-landing',
  templateUrl: './header-landing.component.html',
  styleUrls: ['./header-landing.component.scss'],
})
export class AppHeaderLandingComponent implements OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>()
  logoUrl = this.appConfig.config.brand.logoImageDarkUrl
  loggedIn = false

  constructor(private tokenStorage: TokenStorage, private authService: IAuthService, private appConfig: AppConfigService) {
    this.authService.user$.subscribe(user => {
      this.loggedIn = user ? true : false
    })
  }
  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.unsubscribe()
  }

  signOut(): void {
    this.authService.signOut()
    this.tokenStorage.clear()
  }
}

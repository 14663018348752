import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { IJobApplicationReview } from '../models/job-application-review.model'
import { JobApplicationReviewRepository } from '../repositories/job-application-review.repository'

@Injectable({ providedIn: 'root' })
export class JobApplicationReviewService {
  constructor(private repository: JobApplicationReviewRepository) {}

  listenOrderedForApplication(jobPostId: string, jobApplicationId: string): Observable<IJobApplicationReview[]> {
    return this.repository.getAllValueChangesOrderedByDate(jobPostId, jobApplicationId)
  }

  /**
   * TODO: Consider making userId optional and grabbing it inside this service
   * @param model
   * @returns
   */
  addReviewForUser(model: IJobApplicationReview) {
    return this.repository.set(model)
  }
}

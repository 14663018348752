import { Injectable } from '@angular/core'
import { E11ErrorHandlerService } from '@engineering11/web-api-error'
import { ComponentStore, tapResponse } from '@ngrx/component-store'
import { from, Observable, switchMap } from 'rxjs'
import { map } from 'rxjs/operators'
import { IJobPostUserConfig } from '@employer/app/models/job-post-user-config.model'
import { E11NotificationMessage, E11NotificationsService } from '@engineering11/ui-lib/e11-notifications'
import { EmployerUserService } from '@employer/app/services/employer-user.service'
import { of } from 'rxjs/internal/observable/of'

type UserHiringTeamSettingConfig = Omit<IJobPostUserConfig, 'id' | 'customerKey'>

export interface UserHiringTeamSettingState {
  config?: UserHiringTeamSettingConfig
  loaded: boolean
}

const defaultState: UserHiringTeamSettingState = {
  loaded: false,
  config: undefined,
}

@Injectable({
  providedIn: 'root',
})
export class UserHiringTeamConfigStore extends ComponentStore<UserHiringTeamSettingState> {
  constructor(
    private errorHandler: E11ErrorHandlerService,
    private notificationService: E11NotificationsService,
    private employerUserService: EmployerUserService
  ) {
    super(defaultState)
  }

  // Selectors
  readonly getState = this.select(s => s)
  readonly swimlaneConfig$ = this.select(s => s.config?.swimlanes)
  readonly configLoaded$ = this.select(s => s.loaded)

  // Effects
  readonly onFetchOrLoadHiringTeamConfig = this.effect((_$: Observable<void>) =>
    _$.pipe(
      switchMap(() => this.swimlaneConfig$),
      map(config => {
        if (config) {
          return of({ swimlanes: config })
        }
        return this.onLoadHiringTeamConfig()
      })
    )
  )

  readonly onLoadHiringTeamConfig = this.effect((_$: Observable<void>) =>
    _$.pipe(
      switchMap(() =>
        this.employerUserService.getJobPostConfigForCurrentCustomerUser().pipe(
          tapResponse(
            config => {
              this.setConfig({ swimlanes: config?.swimlanes ?? [] })
            },
            (err: Error) => this.errorHandler.handleError(err)
          )
        )
      )
    )
  )

  readonly onUpdateHiringTeamSwimlaneConfig = this.effect((payload$: Observable<UserHiringTeamSettingConfig>) =>
    payload$.pipe(
      switchMap(({ swimlanes }) =>
        from(this.employerUserService.updateJobPostConfig({ swimlanes })).pipe(
          tapResponse(
            _ => {
              this.setConfig({ swimlanes })
              this.notificationService.popNotificationMessage(successfulUpdateNotification)
            },
            (err: Error) => this.errorHandler.handleError(err)
          )
        )
      )
    )
  )

  // Reducers
  readonly setConfig = this.updater((state, config: UserHiringTeamSettingConfig) => ({
    ...state,
    config,
    loaded: true,
  }))
}

const successfulUpdateNotification: E11NotificationMessage = {
  title: 'Success',
  message: 'Your Hiring Team settings have been saved.',
  type: 'success',
  dismissOnRouteChange: true,
  autoClose: true,
}

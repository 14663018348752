import { Component, Input } from '@angular/core'
import { NgIf } from '@angular/common'

@Component({
  selector: 'automation-helper-icon',
  standalone: true,
  imports: [NgIf],
  template: `
    <div class="e11-flex e11-items-center e11-justify-center">
      <div
        *ngIf="showLeftIndicator"
        class="e11-hidden xl:e11-block -e11-mr-10 e11-w-20 e11-h-20 xl:e11-w-24 xl:e11-h-24 e11-border-8 e11-border-white e11-bg-skin-{{
          color
        }}/20 e11-rounded-full e11-flex e11-items-center e11-justify-center e11-text-skin-white"
      ></div>

      <div
        class="e11-w-36 e11-h-36 xl:e11-w-48 xl:e11-h-48 e11-border-8 e11-border-white e11-bg-skin-{{
          color
        }} e11-z-10 e11-rounded-full e11-flex e11-items-center e11-justify-center e11-text-skin-white"
      >
        <span class="material-icons-outlined e11-text-4xl xl:e11-text-7xl">{{ icon }}</span>
      </div>

      <div
        *ngIf="showRightIndicator"
        class="e11-hidden xl:e11-block -e11-ml-10 e11-w-20 e11-h-20 xl:e11-w-24 xl:e11-h-24 e11-border-8 e11-border-white e11-bg-skin-{{
          color
        }}/20 e11-rounded-full e11-flex e11-items-center e11-justify-center e11-text-skin-white"
      ></div>
    </div>
  `,
  styles: ``,
})
export class AutomationHelperIconComponent {
  @Input() color: 'primary-accent' | 'secondary' | 'success' = 'primary-accent'
  @Input() icon: string = 'edit'
  @Input() showLeftIndicator: boolean = false
  @Input() showRightIndicator: boolean = false
}

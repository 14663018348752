import { Pipe, PipeTransform } from '@angular/core'
import { JobPostLocationService } from '@employer/app/modules/jobs/services/job-post-location.service'

@Pipe({
  name: 'checkStateRequiresSalaryTransparency',
})
export class CheckStateRequiresSalaryTransparencyPipe implements PipeTransform {
  constructor(private jobPostLocationService: JobPostLocationService) {}

  transform(field: string | undefined) {
    if (!field) {
      return false
    }
    return this.jobPostLocationService.stateRequiresSalaryTransparency(field)
  }
}

import { Component, Input, OnDestroy } from '@angular/core'
import { E11ButtonComponent } from '@engineering11/ui-lib/e11-button'
import { TranslateModule } from '@ngx-translate/core'
import { IJobApplicationShareData } from '@employer/app/modules/jobs/components/candidate-share-profile/job-post-application-share.model'
import { SharedApplicationDetailsComponent } from '@employer/app/modules/application-share/components/shared-application-details.component'
import { JobPostApplicationShareStore } from '@employer/app/modules/jobs/components/candidate-share-profile/job-post-application-share.store'

@Component({
  selector: 'candidate-share-profile-preview',
  standalone: true,
  imports: [SharedApplicationDetailsComponent, E11ButtonComponent, TranslateModule],
  template: `
    <div class="e11-mb-4">
      <e11-button
        [type]="'button'"
        [ghost]="true"
        value="{{ 'Close Preview' | translate }}"
        [color]="'primary-accent'"
        (click)="resetPreview()"
      ></e11-button>
    </div>
    <shared-application-details [applicationShare]="applicationShare"></shared-application-details>
  `,
})
export class CandidateShareProfilePreviewComponent implements OnDestroy {
  @Input() applicationShare: IJobApplicationShareData | undefined

  constructor(private jobPostApplicationShareStore: JobPostApplicationShareStore) {}

  resetPreview() {
    this.jobPostApplicationShareStore.setPreview(undefined)
  }

  ngOnDestroy() {
    this.resetPreview()
  }
}

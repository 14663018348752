import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { NavItemNames } from '@employer/app/services/navigation.service'
import { FileNotFoundComponent } from '../_404/404.component'
import { AboutComponent } from './about/about.component'
import { HomeComponent } from './home.component'

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: AboutComponent,
      },
      {
        path: 'about',
        component: AboutComponent,
      },
      {
        path: '**',
        component: FileNotFoundComponent,
        data: { title: NavItemNames.FileNotFound },
      },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}

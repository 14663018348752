import { Component, Input } from '@angular/core'
import { NgIf } from '@angular/common'
import { TranslateModule } from '@ngx-translate/core'
import { IJobApplicationShareData } from '@employer/app/modules/jobs/components/candidate-share-profile/job-post-application-share.model'
import { SharedApplicationDetailHeaderComponent } from '@employer/app/modules/application-share/components/shared-application-detail-header.component'
import { SharedApplicationLeftSectionsComponent } from '@employer/app/modules/application-share/components/shared-application-left-sections.component'
import { SharedApplicationRightSectionsComponent } from '@employer/app/modules/application-share/components/shared-application-right-sections.component'

@Component({
  selector: 'shared-application-details',
  standalone: true,
  imports: [
    NgIf,
    TranslateModule,
    SharedApplicationDetailHeaderComponent,
    SharedApplicationLeftSectionsComponent,
    SharedApplicationRightSectionsComponent,
  ],
  template: `
    <div
      *ngIf="applicationShare"
      class="e11-border e11-border-skin-grey/50 e11-rounded-lg e11-flex e11-justify-center e11-items-start e11-w-[85%] e11-p-6 e11-my-0 e11-mx-auto e11-shadow-2xl"
    >
      <div class="!e11-w-full">
        <div *ngIf="applicationShare">
          <shared-application-detail-header
            [maskDetails]="!applicationShare.jobApplication.firstName"
            [candidateApplication]="applicationShare.jobApplication"
          ></shared-application-detail-header>

          <!-- Summary -->
          <div *ngIf="applicationShare.jobApplication.summary" class="section-wrapper e11-relative e11-mb-8 e11-text-sm">
            <div class="e11-flex e11-w-full e11-items-center e11-border-b e11-border-skin-grey/50 e11-pb-2 e11-mb-4">
              <h4 class="e11-font-skin-secondary e11-font-bold">
                {{ 'Summary' | translate }}
              </h4>
            </div>
            <p innerHtml="{{ applicationShare?.jobApplication?.summary }}" class=""></p>
          </div>
          <div class="e11-w-full">
            <div class="e11-block lg:e11-flex">
              <!-- Left -->
              <div class="e11-w-full lg:e11-w-5/12">
                <shared-application-left-sections
                  [pdfMode]="pdfMode"
                  [candidateApplication]="applicationShare.jobApplication"
                  [cleanURL]="applicationShare.link"
                ></shared-application-left-sections>
              </div>
              <!-- Right -->
              <div class="e11-w-full e11-ml-0 lg:e11-ml-12 lg:e11-w-7/12">
                <shared-application-right-sections
                  [pdfMode]="pdfMode"
                  [candidateApplication]="applicationShare.jobApplication"
                  [cleanURL]="applicationShare.link"
                ></shared-application-right-sections>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: ``,
})
export class SharedApplicationDetailsComponent {
  @Input() pdfMode: boolean = false
  @Input() applicationShare: IJobApplicationShareData | undefined
}
